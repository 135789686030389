import React from "react";
import { Link } from "react-router-dom";

export default function Tabs() {
  let urlString = window.location.href;
  console.log("url string is " + urlString);
  let length = urlString.length - 2;
  let lastChar = urlString.substr(-1);
  console.log("lastChar:" + " " + lastChar);
  let partialStringIndex;
  if (lastChar !== "/") {
    partialStringIndex = urlString.indexOf("/client-dashboard");
  } else {
    partialStringIndex = urlString.indexOf("/client-dashboard/");
  }
  console.log("partialStringIndex is " + partialStringIndex);
  let partialString = urlString.slice(partialStringIndex);
  console.log("partial string is " + partialString);
  return (
    <div className="tabs">
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <Link
            to="/client-dashboard/gallery"
            className={
              partialString == "/client-dashboard/gallery" ||
              partialString == "/client-dashboard/gallery/" ||
              partialString.includes("/client-dashboard/gallery/") ||
              partialString == "/client-dashboard" ||
              partialString == "/client-dashboard/"
                ? "tabLink nav-link active"
                : "tabLink nav-link"
            }
          >
            Gallery
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/client-dashboard/download"
            className={
              partialString == "/client-dashboard/download" ||
              partialString == "/client-dashboard/download/" ||
              partialString.includes("/client-dashboard/download/")
                ? "tabLink nav-link active"
                : "tabLink nav-link"
            }
          >
            Download
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/client-dashboard/messages"
            className={
              partialString == "/client-dashboard/messages" ||
              partialString == "/client-dashboard/messages/" ||
              partialString == "/client-dashboard/messages/home" ||
              partialString == "/client-dashboard/messages/home/" ||
              partialString == "/client-dashboard/messages/pricing" ||
              partialString == "/client-dashboard/messages/pricing/" ||
              partialString == "/client-dashboard/messages/portfolio" ||
              partialString == "/client-dashboard/messages/portfolio/" ||
              partialString == "/client-dashboard/messages/about" ||
              partialString == "/client-dashboard/messages/about/" ||
              partialString == "/client-dashboard/messages/contact" ||
              partialString == "/client-dashboard/messages/contact/" ||
              partialString == "/client-dashboard/messages/images" ||
              partialString == "/client-dashboard/messages/images/"
                ? "tabLink nav-link active"
                : "tabLink nav-link"
            }
          >
            Messages
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/client-dashboard/payment"
            className={
              partialString == "/client-dashboard/payment" ||
              partialString == "/client-dashboard/payment/"
                ? "tabLink nav-link active"
                : "tabLink nav-link"
            }
          >
            Payment
          </Link>
        </li>
      </ul>
    </div>
  );
}
