import React, { Component } from "react";

import { withFirebase } from "../../firebase/index";
import { compose } from "redux";

import ImageGallery from "react-image-gallery";

import Tabs from "./Tabs";
import "./gallery.css";

class Gallery extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      message: undefined,
      client: undefined,
      images: [],
      selectedImages: [],
      zips: [],
      galleryImages: []
    };
  }

  getClientInfo = () => {
    this.props.firebase.db
      .collection("users")
      .doc(this.props.firebase.auth.currentUser.uid)
      .get()
      .then(
        function(res) {
          if (res.exists) {
            console.log("have client");
            this.setState({ client: res.data(), loading: false }, () => {
              let images = [];
              this.props.firebase.db
                .collection("users")
                .doc(this.state.client.uid)
                .collection("images")
                .get()
                .then(
                  function(res) {
                    res.forEach((image, i) => {
                      images.push(image.data());
                    });
                    this.setState({ images: images, loading: false }, () => {
                      this.setGalleryObjects();
                    });
                  }.bind(this)
                )
                .catch(
                  function(err) {
                    console.log("error getting images: " + err.message);
                    this.setState({
                      message:
                        "There Was An Error Getting Your Images From The Database, Check Connection And Try Again",
                      loading: false
                    });
                  }.bind(this)
                );
            });
          } else {
            console.log("error getting client information");
            this.setState({
              message:
                "There Was An Error Getting Information From The Database, Check Connection And Try Again."
            });
          }
        }.bind(this)
      );
  };
  setGalleryObjects = () => {
    console.log("inside setGalleryObjects");
    let gallery = [];
    this.state.images.forEach((image, i) => {
      let galleryImage = { original: image.url, thumbnail: image.thumbFive };
      gallery.push(galleryImage);
    });
    this.setState({ galleryImages: gallery });
  };
  componentDidMount() {
    this.getClientInfo();
  }
  render() {
    return (
      <div className="uploadFormContainer">
        {" "}
        {this.state.loading ? (
          <i className="spinner fas fa-circle-notch fa-spin" />
        ) : (
          <div className="container">
            <Tabs />
            {this.state.message !== undefined ? (
              <p className="message">{this.state.message}</p>
            ) : (
              ""
            )}
            <div className="headlineBox">
              <h4 className="display-5 text-center headline">
                Welcome, {this.state.client.fullName},
              </h4>
              <p className="display-5 text-center headline">
                {" "}
                this is your image gallery. You can browse or view a slideshow.
                When you're ready to download images you love, head to the
                Download Tab.
              </p>
            </div>

            <ImageGallery
              thumbnailPosition="left"
              items={this.state.galleryImages}
            />
          </div>
        )}
      </div>
    );
  }
}

export default compose(withFirebase)(Gallery);
