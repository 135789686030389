import React from "react";
import "./footer.css";
import logo from "./../assets/logo.png";
export default function Footer() {
  return (
    <div className="container-fluid footerContainer">
      <div className="footer row">
        <div className="col col-md-6 col-xs-12" style={{ padding: "2em" }}>
          <img
            src={logo}
            style={{
              width: 250,
              marginLeft: "auto",
              marginRight: "auto",
              display: "block"
            }}
          />
        </div>
        <div style={{ padding: "2em" }} className="col col-md-6 col-xs-12 ">
          <p className="lead text-center footerText">
            <span className="margin">
              <a href="mailto:katie@katiekozma.com">
                <i className="fas fa-envelope"></i> katie@katiekozma.com
              </a>
            </span>
            <span className="margin">
              <i className="fas fa-phone"></i> 231-225-5498
            </span>
            <br />
            newaygo county, michigan
            <br />
            <span className="margin attribution">
              this site was developed by:{" "}
              <a href="http://www.bkbeesites.com/" target="_blank">
                www.bkbeesites.com
              </a>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
