import React, { Component } from "react";

import { withFirebase } from "./../firebase/index";
import { compose } from "redux";
import NavBar from "./nav/NavBar";
import LoadingScreen from "./common/LoadingScreen";
import StaticNav from "./nav/StaticNav";
import RelativeNav from "./nav/RelativeNav";
import Footer from "./Footer";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hiring: false,
      loading: true,

      navHeight: 150
    };
  }
  componentDidMount() {
    this.getAboutStuff();
    this.setNavHeight();
  }
  getAboutStuff = () => {
    console.log("getting about stuff");
    this.props.firebase.db
      .collection("pages")
      .doc("about")
      .get()
      .then(
        function(res) {
          if (res.exists) {
            console.log("got about stuff");
            let defaultImage = new Image();
            defaultImage.src = res.data().defaultImage.url;
            if (res.data().defaultImage.url == "") {
              console.log("no url");
              this.setState({
                defaultImage: "res.data().defaultImage",
                overlay: res.data().overlay,
                loading: false,
                pageHeading: res.data().pageHeading,
                headingColor: res.data().headingColor,
                headingShadow: res.data().headingShadow,
                aboutImage: res.data().aboutImage,
                paragraphs: res.data().paragraphs,
                navStyle: res.data().navStyle
              });
            } else {
              defaultImage.onload = () => {
                this.setState({
                  defaultImage: res.data().defaultImage,
                  overlay: res.data().overlay,
                  loading: false,
                  pageHeading: res.data().pageHeading,
                  headingColor: res.data().headingColor,
                  headingShadow: res.data().headingShadow,
                  aboutImage: res.data().aboutImage,
                  paragraphs: res.data().paragraphs,
                  navStyle: res.data().navStyle
                });
              };
            }
          } else {
            console.log("no about stuff");
            this.setState({
              defaultImage: "",
              overlay: undefined,
              loading: false,
              pageHeading: "Edit About Page In Dashboard",
              headingColor: "white",
              headingShadow: true,
              aboutImage: "",
              paragraphs: [],
              navStyle: "relative"
            });
          }
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting about stuff: " + err.message);
      });
  };
  closeHiring = () => {
    this.setState({ hiring: false });
  };
  setHiring = () => {
    this.setState({ hiring: true });
  };
  setNavHeight = height => {
    console.log("inside setNavHeight:" + height);
    this.setState({ navHeight: height });
  };
  openHiring = job => {
    switch (job) {
      case "family":
        this.setState({ hiring: true, job: "family" });
        break;
      case "engagement":
        this.setState({ hiring: true, job: "engagement" });
        break;
      case "wedding":
        break;
      case "other":
        break;
      default:
        break;
    }
  };
  render() {
    let overlayColor;
    console.log("inside render of contact");
    let height = window.innerHeight;
    console.log("HEIGHT is : " + height);
    let overlayHeight;

    let nav;
    switch (this.state.navStyle) {
      case "sticky":
        overlayHeight = "100%";
        nav = (
          <NavBar
            active="about"
            signedIn={this.props.signedIn}
            admin={this.props.admin}
          />
        );
        break;
      case "animated":
        overlayHeight = height - this.state.navHeight;

        console.log(
          "setting overlayHeight to : " +
            overlayHeight +
            " navHeight is: " +
            this.state.navHeight +
            " height is " +
            height
        );
        nav = (
          <RelativeNav
            active="about"
            signedIn={this.props.signedIn}
            admin={this.props.admin}
            setHeight={this.setNavHeight}
          />
        );
        break;
      case "relative":
        overlayHeight = height - this.state.navHeight;
        console.log(
          "setting overlayHeight to : " +
            overlayHeight +
            " navHeight is: " +
            this.state.navHeight +
            " height is: " +
            height
        );
        nav = (
          <StaticNav
            active="about"
            signedIn={this.props.signedIn}
            admin={this.props.admin}
            setHeight={this.setNavHeight}
          />
        );
        break;
      default:
        break;
    }
    switch (this.state.overlay) {
      case "light":
        overlayColor = "rgba(197, 195, 195, 0.262)";
        break;
      case "medium":
        overlayColor = "rgba(197, 195, 195, 0.462)";
        break;
      case "dark":
        overlayColor = "rgba(66, 66, 66, 0.568)";
        break;
      case "none":
        overlayColor = "rgba(66, 66, 66, 0)";
        break;
      default:
        overlayColor = "rgba(66, 66, 66, 0)";
        break;
    }
    let headingColor;
    switch (this.state.headingColor) {
      case "white":
        headingColor = "#fff";
        break;
      case "grey":
        headingColor = "#878787";
        break;
      case "black":
        headingColor = "#000";
        break;
      default:
        headingColor = "#878787";
        break;
    }
    return (
      <div>
        {" "}
        {this.state.loading ? (
          <LoadingScreen />
        ) : (
          <div>
            <div
              className=" pricingContainer landingImage"
              style={
                this.state.defaultImage !== undefined
                  ? {
                      backgroundImage:
                        "url(" + this.state.defaultImage.url + ")"
                    }
                  : {}
              }
            >
              {nav}
              <div
                className="overlay"
                style={
                  this.state.overlay
                    ? {
                        backgroundColor: overlayColor,
                        height: overlayHeight || 150
                      }
                    : {}
                }
              >
                {" "}
                <p
                  style={
                    this.state.headingShadow == "yes"
                      ? {
                          color: headingColor,
                          textShadow: "2px 4px 3px rgba(0,0,0,0.4)"
                        }
                      : { color: headingColor }
                  }
                  className="lead pageHeading"
                >
                  {this.state.pageHeading}
                </p>
              </div>
              <div className="pricing">
                <div className="container">
                  {" "}
                  <div className="row row-space">
                    <div className="col-xs-12 col-md-6">
                      {this.state.paragraphs.map((paragraph, i) => (
                        <p className="lead" key={i}>
                          {" "}
                          {this.state.paragraphs[i]}
                        </p>
                      ))}
                    </div>
                    <div className="col-xs-12 col-md-6">
                      <img
                        className="aboutImage"
                        src={this.state.aboutImage.thumbFive}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        )}{" "}
      </div>
    );
  }
}

export default compose(withFirebase)(About);
