import React, { Component } from "react";

export default class AddPortfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      portfolioName: ""
    };
  }
  onChange = event => {
    let nameTaken = false;
    let convertedName = this.urlConvert(event.target.value);
    for (let i = 0; i < this.props.portfolios.length; i++) {
      if (convertedName == this.props.portfolios[i].url) {
        nameTaken = true;
      }
    }
    if (nameTaken) {
      this.setState({
        portfolioName: event.target.value,
        nameError: true,
        message: "Name Already Taken",
        valid: false
      });
    } else {
      this.setState({
        portfolioName: event.target.value,
        nameError: false,
        message: undefined
      });
    }
  };
  urlConvert = name => {
    let nameToConvert = name;
    let converted = nameToConvert.replace(/[^a-z0-9 _-]/gi, "-").toLowerCase();
    let noSpaceConverted = converted.replace(/\s+/g, "-");
    return noSpaceConverted;
  };
  submitPortfolio = event => {
    event.preventDefault();
    let url = this.urlConvert(this.state.portfolioName);
    this.props.submit(this.state.portfolioName, url);
  };
  render() {
    let submit;
    if (this.state.portfolioName.length > 0 && !this.state.nameError) {
      submit = (
        <input
          type="submit"
          className="controlButton btn btn-secondary addJobButton"
          style={{ marginTop: "1em" }}
        />
      );
    } else {
      submit = (
        <input
          type="submit"
          className="controlButton btn btn-secondary addJobButton"
          disabled
          style={{ marginTop: "1em" }}
        />
      );
    }
    return (
      <div className="card addClient">
        <p className="lead">Add Portfolio</p>
        <form onSubmit={this.submitPortfolio}>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label" htmlFor="firstName">
              Portfolio Name
            </label>

            <input
              className={
                this.state.nameError
                  ? "form-control col-sm-9 is-invalid"
                  : "form-control col-sm-9"
              }
              type="text"
              id="portfolioName"
              onChange={this.onChange}
              placeholder="Portfolio Name"
              value={this.state.portfolioName}
              name="portfolioName"
            />
          </div>
          <div className="form-group row submitContainer">{submit}</div>
        </form>
        {this.state.message !== undefined ? (
          <p className="alert lead">{this.state.message}</p>
        ) : (
          ""
        )}
      </div>
    );
  }
}
