import React, { Component } from "react";
import AddPortfolio from "./AddPortfolio";
import PortfolioTable from "./PortfolioTable";
import EditPortfolio from "./EditPortfolio";
import Backdrop from "../../../common/Backdrop";
import LoadingScreen from "../../../common/LoadingScreen";
import Confirmation from "./../../../common/Confirmation";
import { withFirebase } from "../../../../firebase/index";
import { compose } from "redux";
import "./portfolio.css";

class PortfolioManagement extends Component {
  constructor() {
    super();
    this.state = {
      addPortfolio: false,
      loading: true,
      portfolios: [],
      selectedPortfolio: undefined,
      editPortfolio: false,
      deleteConfirmation: false
    };
  }
  deletePortfolio = () => {
    this.setState({ deleteConfirmation: true });
  };
  answerDelete = answer => {
    if (answer) {
      this.setState({ loading: true }, () => {
        this.deletePortObject();
      });
    } else {
      this.setState({ deleteConfirmation: false, loading: false });
    }
  };
  deletePortObject = () => {
    console.log(
      "inside deletePortObject, object name is" +
        this.state.selectedPortfolio.name
    );
    this.setState({ loading: true, deleteConfirmation: false });
    this.props.firebase.db
      .collection("portfolios")
      .doc(this.state.selectedPortfolio.name)
      .delete()
      .then(
        function(res) {
          this.setState(
            { loading: false, message: "Successfully Deleted Portfolio" },
            () => {
              this.getPortfolios();
            }
          );
        }.bind(this)
      )
      .catch(
        function(err) {
          console.log("error deleting portfolio: " + err.message);
          this.setState({
            message:
              "Error Deleting Portfolio. Check Network Connection, Refresh, And Try Again"
          });
        }.bind(this)
      );
  };
  closeEditPortfolio = () => {
    this.setState({ editPortfolio: false });
  };
  toggleAddPortfolio = () => {
    this.setState(prevState => {
      return { addPortfolio: !prevState.addPortfolio };
    });
  };
  selectPortfolio = event => {
    console.log(
      "inside selectPortfolio from Portfoliomanagement: " +
        JSON.stringify(event)
    );
    if (event == null) {
      console.log("setting selectedPortfolio to undefined");
      this.setState({ selectedPortfolio: undefined });
    } else {
      for (let i = 0; i < this.state.portfolios.length; i++) {
        if (this.state.portfolios[i].name == event.name) {
          this.setState({ selectedPortfolio: event });
        }
      }
    }
  };
  editPortfolio = () => {
    this.openPortfolio(this.state.selectedPortfolio);
  };
  openPortfolio = port => {
    console.log("inside openPortfolio from portfoliomanagement");
    if (port == undefined || port == null) {
      //open selected port
    } else {
      console.log("setting state to editPortfolio:true");
      this.setState({ editPortfolio: true, selectedPortfolio: port });
    }
  };
  getPortfolios = () => {
    let ports = [];
    this.props.firebase.db
      .collection("portfolios")
      .get()
      .then(
        function(portSnapshot) {
          portSnapshot.forEach(function(port) {
            if (port.exists) {
              console.log("got portfolio");
              ports.push(port.data());
            }
          });
          this.setState({ loading: false, portfolios: ports });
        }.bind(this)
      );
  };
  componentDidMount() {
    this.getPortfolios();
  }
  submitPortfolio = (portName, url) => {
    console.log("inside submitportfolio from portfoliomanagement");
    this.setState({ loading: true, addPortfolio: false });
    let portObject = {
      name: portName,
      url: url,
      date: new Date().getTime(),
      defaultImage: "",
      portStyle: "block"
    };
    this.props.firebase.db
      .collection("portfolios")
      .doc(portName)
      .set(portObject)
      .then(
        function(res) {
          this.setState(
            {
              loading: false,
              message: "Successfully Added Portfolio: " + portName
            },
            () => {
              this.getPortfolios();
            }
          );
        }.bind(this)
      )
      .catch(
        function(err) {
          console.log("error adding portfolio: " + err.message);
          this.setState({
            message: "Error Adding Portfolio, Refresh And Try Again",
            loading: false
          });
        }.bind(this)
      );
  };
  render() {
    return (
      <div>
        {this.state.deleteConfirmation ? (
          <Confirmation
            answer={this.answerDelete}
            question="Are You Sure You Want To Delete This Portfolio? Also, Be Aware This Will Not Delete The Images Inside The Portfolio. To Do That, You Must Go To Images In Site Management."
          />
        ) : (
          ""
        )}
        {this.state.loading ? (
          <i className="spinner fas fa-circle-notch fa-spin" />
        ) : (
          <div>
            <p className="lead">Portfolio Management</p>
            {this.state.message !== undefined ? (
              <p className="alert lead">{this.state.message}</p>
            ) : (
              ""
            )}
            <button
              className="controlButton btn btn-sm btn-secondary"
              onClick={this.toggleAddPortfolio}
            >
              Add Portfolio
            </button>
            {this.state.selectedPortfolio !== undefined &&
            this.state.selectedPortfolio !== null ? (
              <div>
                <button
                  className="controlButton btn btn-sm btn-secondary"
                  onClick={this.deletePortfolio}
                >
                  Delete Portfolio
                </button>
                <button
                  className="controlButton btn btn-sm btn-secondary"
                  onClick={this.editPortfolio}
                >
                  Edit Portfolio
                </button>
              </div>
            ) : (
              ""
            )}
            <PortfolioTable
              selectPortfolio={this.selectPortfolio}
              openPortfolio={this.openPortfolio}
              ports={this.state.portfolios}
            />
            {this.state.addPortfolio ? (
              <div>
                <AddPortfolio
                  portfolios={this.state.portfolios}
                  submit={this.submitPortfolio}
                />
                <Backdrop click={this.toggleAddPortfolio} />
              </div>
            ) : (
              ""
            )}
            {this.state.editPortfolio ? (
              <EditPortfolio
                close={this.closeEditPortfolio}
                port={this.state.selectedPortfolio}
                siteImages={this.props.siteImages}
                getPortfolios={this.getPortfolios}
              />
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    );
  }
}

export default compose(withFirebase)(PortfolioManagement);
