import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "./../../assets/logo.png";
import whiteLogo from "./../../assets/whiteLogo.png";
import "./navbar.css";
import Plx from "react-plx";
import MobileMenu from "./MobileMenu";
import { withFirebase } from "./../../firebase/index";
import { compose } from "redux";
import LoadingScreen from "../common/LoadingScreen";

class StaticNav extends Component {
  constructor(props) {
    super(props);
    this.portRef = this.state = {
      portfolios: [],
      width: window.innerWidth,
      height: window.innerHeight,
      sticky: this.props.sticky,
      loading: true,
      linkStyles: {
        color: "#fff",
        fontSize: "20px"
      },
      navBarStyles: {
        display: "flex",
        width: "100%",
        position: "fixed",
        zIndex: 300,
        top: 0,
        left: 0,
        alignItems: "center",
        verticalAlign: "middle"
      },
      navBarRowStyles: {
        width: "100%",
        position: "fixed",
        top: 0,
        backgroundColor: "#fff",
        display: "flex",
        zIndex: 201
      },
      logoStyles: {
        verticalAlign: "middle"
      },
      whiteLogoParallaxData: [
        {
          start: 0,
          end: 100,
          properties: [{ startValue: 1, endValue: 0, property: "scale" }]
        }
      ],
      blackLogoParallaxData: [
        {
          start: 0,
          end: 100,
          properties: [{ startValue: 0, endValue: 1, property: "scale" }]
        }
      ],
      linkParallaxData: [
        {
          start: 0,
          end: 100,
          properties: [
            { startValue: "#fff", endValue: "#000", property: "color" }
          ]
        }
      ],
      navBarStuckData: [
        {
          start: 60,
          end: 60,
          properties: [
            { startValue: -30, endValue: -30, property: "translateY" }
          ]
        }
      ],
      navBarParallaxData: [
        {
          start: 60,
          end: 100,
          properties: [{ startValue: 1, endValue: -30, property: "translateY" }]
        }
      ],
      navBarRowStuckData: [
        {
          start: 0,
          end: 1,
          easing: "easeInOut",
          properties: [
            {
              startValue: 1,
              endValue: 2,
              property: "scaleY"
            },
            { startValue: 0.8, endValue: 0.8, property: "opacity" }
          ]
        },
        {
          start: 60,
          end: 100,
          easing: "easeInOut",
          properties: [{ startValue: 1, endValue: 1, property: "scaleY" }]
        }
      ],
      navBarRowParallaxData: [
        {
          start: 0,
          end: 50,
          easing: "easeInOut",
          properties: [
            {
              startValue: 1,
              endValue: 2,
              property: "scaleY"
            },
            { startValue: 0, endValue: 0.8, property: "opacity" }
          ]
        },
        {
          start: 60,
          end: 100,
          easing: "easeInOut",
          properties: [{ startValue: 2, endValue: 1, property: "scaleY" }]
        }
      ]
    };
    this.imageContainerRef = React.createRef();
    this.divElement = React.createRef();
  }
  toggleMobileMenu = () => {
    console.log("toggling mobile menu");
    this.setState(prevState => {
      return { mobileMenu: !prevState.mobileMenu };
    });
  };
  updateDimensions = () => {
    console.log("inside updateDimensions");
    console.log(
      "inside updateDimensions, ref height is: " +
        this.divElement.current.clientHeight
    );
    let windowWidth = window.innerWidth;
    let width = windowWidth / 5.5;
    if (width < 200) {
      width = 200;
    } else if (width > 400) {
      width = 400;
    }

    this.props.setHeight(this.divElement.current.clientHeight);

    this.setState({ logoWidth: width, width: windowWidth, loading: false });
  };
  logOut = () => {
    this.props.firebase.auth
      .signOut()
      .then(
        function(res) {
          this.props.history.push("/login");
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error logging out: " + err.message);
      });
  };
  throttle = (fn, wait) => {
    var time = Date.now();
    return function() {
      if (time + wait - Date.now() < 0) {
        fn();
        time = Date.now();
      } else if (window.pageYOffset == 0) {
        fn();
        time = Date.now();
      }
    };
  };
  handleScroll = () => {
    console.log("scrolled in bigNav position is : " + window.pageYOffset);
    if (window.pageYOffset > 0) {
      this.setState({ navSticky: true, scrolled: true });
    } else if (window.pageYOffset === 0) {
      this.setState({ navSticky: false, scrolled: true });
    }
  };
  getPortfolios = () => {
    let ports = [];
    this.props.firebase.db
      .collection("portfolios")
      .get()
      .then(
        function(portSnapshot) {
          portSnapshot.forEach(
            function(port) {
              let portObj = {
                ...port.data(),
                path: "/portfolio/" + port.data().url
              };
              ports.push(portObj);
            }.bind(this)
          );
          this.setState({ portfolios: ports }, () => {
            this.updateDimensions();
          });
        }.bind(this)
      );
  };
  componentDidMount() {
    this.getPortfolios();
    console.log("from navbar, sticky is : " + this.props.sticky);
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
    // window.addEventListener("scroll", this.throttle(this.handleScroll, 1000));
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
    // window.removeEventListener("scroll", this.throttle);
  }
  togglePortMenu = () => {
    console.log("toggling Portfolio menu");
    this.setState(prevState => {
      return { portMenu: !prevState.portMenu };
    });
  };
  render() {
    let menu;
    let third = window.innerWidth / 3;
    let remainder = third - this.state.logoWidth;
    let margin = remainder / 2;
    let loading;
    let login, admin;

    if (this.props.signedIn) {
      //login link should have logout
      login = (
        <button className="btn btn-link" onClick={this.logOut}>
          Log Out
        </button>
      );
      if (this.props.admin) {
        //dashboard should link dashboard
        login = (
          <Link
            to="/dashboard"
            className={this.props.active == "dashboard" ? "active" : ""}
          >
            Dashboard
          </Link>
        );
      } else {
        //dashboard should link client-dashboard
        login = (
          <Link
            to="/client-dashboard"
            className={this.props.active == "client-dashboard" ? "active" : ""}
          >
            Dashboard
          </Link>
        );
      }
    } else {
      //login link should have login
      login = (
        <Link
          className={this.props.active == "login" ? "active" : ""}
          to="/login"
        >
          Log In
        </Link>
      );
    }

    if (this.state.width < 800) {
      console.log("displaying mobile menu");
      if (this.state.mobileMenu) {
        //display with mobile menu
        console.log("state says mobile menu");
        menu = (
          <MobileMenu
            admin={this.props.admin}
            signedIn={this.props.signedIn}
            close={this.toggleMobileMenu}
            logOut={this.logOut}
            ports={this.state.portfolios}
          />
        );
      } else {
        menu = (
          <div className="mobile">
            <div className="staticMobileNavBar row">
              {" "}
              <div
                ref={this.imageContainerRef}
                className=" imageContainer blue col col-xs-6"
              >
                <img
                  src={logo}
                  alt="Katie Kozma Photography"
                  width="200px"
                  style={{}}
                  className="logo"
                />
              </div>
              <div
                style={{ paddingRight: 30, textAlign: "right" }}
                className="col col-xs-6"
              >
                <a
                  className="mobileLink staticMobileLink"
                  onClick={this.toggleMobileMenu}
                >
                  {" "}
                  <div style={{ fontSize: 20 }}>MENU</div>
                </a>
              </div>
            </div>
          </div>
        );
      }
    } else {
      menu = (
        <div>
          <div className="staticNavBar">
            <div className="col col-xs-4 text-right green">
              <ul className=" leftList staticList navbar-nav">
                <li className="nav-item">
                  <Link
                    className={this.props.active == "home" ? "active" : ""}
                    to="/"
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item btn-group">
                  <a
                    style={{ cursor: "pointer" }}
                    className={this.props.active == "portfolio" ? "active" : ""}
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Portfolio
                  </a>
                  <ul className="dropdown-menu portMenu animated fadeIn">
                    {this.state.portfolios.map((port, i) => (
                      <li key={i}>
                        <Link to={port.path}>{port.name}</Link>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="nav-item">
                  <Link
                    className={this.props.active == "pricing" ? "active" : ""}
                    to="/pricing"
                  >
                    Pricing
                  </Link>
                </li>
              </ul>
            </div>
            <div
              ref={this.imageContainerRef}
              className="col col-xs-4 imageContainer blue"
            >
              <img
                src={logo}
                alt="Katie Kozma Photography"
                width={this.state.logoWidth}
                style={{ marginLeft: margin, marginRight: margin }}
                className="logo"
              />
            </div>
            <div className="col col-xs-4 green">
              <ul className="rightList staticList navbar-nav">
                <li>
                  <Link
                    className={this.props.active == "about" ? "active" : ""}
                    to="/about"
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    className={this.props.active == "contact" ? "active" : ""}
                    to="/contact"
                  >
                    Contact
                  </Link>
                </li>
                <li>{login}</li>
              </ul>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div ref={this.divElement}>
        <div>{this.state.loading ? <LoadingScreen /> : menu}</div>
      </div>
    );
  }
}

export default compose(withFirebase)(StaticNav);
