import React, { Component } from "react";
import formatDate from "./../../common/formatDate";
import { Link } from "react-router-dom";
class InvoicesTable extends Component {
  constructor() {
    super();
    this.state = {
      selectedInvoice: null
    };
  }
  selectInvoice = event => {
    if (this.state.selectedInvoice == event) {
      this.props.selectInvoice(null);
      this.setState({ selectedInvoice: null });
    } else {
      this.props.selectInvoice(event);
      this.setState({ selectedInvoice: event });
    }
  };
  render() {
    console.log(
      "invoices from invoicesTable:" + JSON.stringify(this.props.invoices)
    );
    return (
      <div className="clientsTableContainer">
        <table className="table table-hover">
          <thead className="thead-dark">
            <tr>
              <th></th>
              <th
                className="sort"
                scope="col"
                onClick={() => {
                  this.props.sort("name");
                }}
              >
                Name
              </th>
              <th
                className="sort"
                scope="col"
                onClick={() => {
                  this.props.sort("date");
                }}
              >
                Date Added
              </th>
              <th
                className="sort"
                scope="col"
                onClick={() => {
                  this.props.sort("amount");
                }}
              >
                Amount
              </th>
              <th
                className="sort"
                scope="col"
                onClick={() => {
                  this.props.sort("status");
                }}
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.invoices.map((invoice, i) => (
              <tr
                key={i}
                style={{
                  backgroundColor:
                    this.state.selectedInvoice == invoice ? "#ccc" : undefined
                }}
              >
                <td>
                  <input
                    type="checkbox"
                    checked={
                      this.state.selectedInvoice == invoice ? true : false
                    }
                    id="selectInvoice"
                    onClick={() => {
                      this.selectInvoice(invoice);
                    }}
                  ></input>
                </td>
                <th scope="row">{invoice.recipient.name}</th>
                <th scope="row">{formatDate(invoice.time)}</th>
                <th scope="row">{invoice.amount}</th>
                <th scope="row">{invoice.paid ? "Paid" : "Not Paid"}</th>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default InvoicesTable;
