import React from "react";

export default function ContactForm(props) {
  return (
    <div className="container container-fluid">
      <div className="form-group row">
        <div className="col-sm-6">
          <label htmlFor="name">Name</label>

          <input
            type="text"
            className="form-control"
            onChange={props.change}
            value={props.name}
            name="name"
            id="name"
          />
        </div>

        <div className="col-sm-6">
          <label htmlFor="name">Email</label>

          <input
            type="email"
            className="form-control"
            value={props.email}
            onChange={props.change}
            name="email"
            id="email"
          />
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-6">
          <label htmlFor="name">Phone</label>

          <input
            type="tel"
            className="form-control"
            value={props.phone}
            onChange={props.change}
            name="phone"
            id="phone"
          />
        </div>
        <div className="col col-sm-6">
          <label htmlFor="name">Type Of Session</label>

          <input
            type="text"
            className="form-control"
            value={props.type}
            name="type"
            onChange={props.change}
            id="name"
          />
        </div>
      </div>
      <div className="form-group row">
        <div className="col col-sm-12">
          {" "}
          <label htmlFor="content">Your Message:</label>
          <textarea
            className="form-control"
            name="content"
            id="content"
            value={props.content}
            onChange={props.change}
          />
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-9"></div>
        <div className="col-sm-3 ">
          <button onClick={props.submit} className="btn btn-secondary">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
