import React, { Component } from "react";

export default class EditPackage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      portfolioName: ""
    };
  }
  changeButton = event => {
    console.log("changing button to: " + event.target.name);
    if (event.target.name == "yes" || event.target.name == "true") {
      this.setState({ button: true });
    } else {
      this.setState({ button: false });
    }
  };
  onChange = event => {
    if (event.target.name == "packageName") {
      let taken = false;
      this.props.packages.forEach(function(pa, i) {
        if (event.target.value == pa.name) {
          taken = true;
        }
      });
      if (taken) {
        this.setState({ message: "Package Name Already Taken." });
      } else {
        this.setState({
          [event.target.name]: event.target.value,
          message: undefined
        });
      }
    } else {
      this.setState({
        [event.target.name]: event.target.value,
        message: undefined
      });
    }
  };
  componentDidMount() {
    console.log(
      "inside editPackage, data is : " + JSON.stringify(this.props.packageData)
    );
    this.setFields();
  }
  setFields = () => {
    let button;
    if (
      this.props.packageData.button == undefined ||
      this.props.packageData.button == null
    ) {
      button = false;
    } else {
      button = this.props.packageData.button;
    }
    this.setState({
      packageName: this.props.packageData.name,
      packageDescription: this.props.packageData.description,
      packagePrice: this.props.packageData.price,
      button: button
    });
  };

  submitPackage = event => {
    event.preventDefault();
    let packageObject = {
      name: this.state.packageName,
      description: this.state.packageDescription,
      price: this.state.packagePrice,
      button: this.state.button
    };
    this.props.submit(packageObject);
  };
  render() {
    return (
      <div className="card addClient">
        <p className="lead">Edit Package</p>
        {this.state.message !== undefined ? this.state.message : ""}
        <form onSubmit={this.submitPackage}>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label" htmlFor="firstName">
              Package {this.state.packageName}
            </label>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label" htmlFor="firstName">
              Package Description
            </label>

            <textarea
              className={
                this.state.nameError
                  ? "form-control col-sm-9 is-invalid"
                  : "form-control col-sm-9"
              }
              type="text"
              id="packageDescription"
              onChange={this.onChange}
              placeholder="Package Description"
              value={this.state.packageDescription}
              name="packageDescription"
            />
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label" htmlFor="firstName">
              Package Price
            </label>

            <input
              className={
                this.state.nameError
                  ? "form-control col-sm-9 is-invalid"
                  : "form-control col-sm-9"
              }
              type="text"
              id="packagePrice"
              onChange={this.onChange}
              placeholder="Package Price"
              value={this.state.packagePrice}
              name="packagePrice"
            />
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">
              Include Contact Button?
            </label>
            <div className="col col-sm-4">
              <label htmlFor="yes"> Yes</label>
              <input
                className="form-control col-sm-9"
                type="checkbox"
                id="true"
                onChange={this.changeButton}
                checked={this.state.button ? true : false}
                placeholder="Package Price"
                name="true"
              />
            </div>

            <div className="col col-sm-4">
              <label htmlFor="no">No</label>

              <input
                className="form-control col-sm-9"
                type="checkbox"
                id="false"
                onChange={this.changeButton}
                checked={this.state.button == false ? true : false}
                placeholder="Package Price"
                name="false"
              />
            </div>
          </div>
          <div className="form-group row submitContainer">
            <input
              type="submit"
              className="controlButton btn btn-secondary addJobButton"
              disabled={
                this.state.packageName !== undefined &&
                this.state.packageName !== ""
                  ? false
                  : true
              }
              style={{ marginTop: "1em" }}
            />
          </div>
        </form>
        {this.state.message !== undefined ? (
          <p className="alert lead">{this.state.message}</p>
        ) : (
          ""
        )}
      </div>
    );
  }
}
