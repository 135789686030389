import React, { Component } from "react";
import FileUploadUtility from "./FileUploadUtility";

export default class SiteUpload extends Component {
  render() {
    return (
      <div>
        <FileUploadUtility destination="site" />
      </div>
    );
  }
}
