import React, { Component } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import Plx from "react-plx";
import { withFirebase } from "./firebase/index";
import { compose } from "redux";
//components
import Dashboard from "./components/dashboard/Dashboard";
import PrivateRoute from "./components/common/PrivateRoute";
import PrivateAdminRoute from "./components/common/PrivateAdminRoute";
import ClientDashboard from "./components/client-dashboard/ClientDashboard";
import Landing from "./components/Landing";
import "./components/styles/animate.css";
import "./App.css";
import Contact from "./components/Contact";
import Login from "./components/Login";
import LoadingScreen from "./components/common/LoadingScreen";
import "./assets/fontAwesome.css";
import Home from "./components/portfolio/Home";
import Pricing from "./components/pricing/Pricing";
import About from "./components/About";
import { admin } from "./versionInfo";
import "./components/dashboard/dashboard.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navSticky: false,
      scrolled: false,
      signedIn: false,
      loading: true,
      admin: false,
      dashboard: false,
      currentPath: window.location.pathname
    };
  }
  setupAuthListener = () => {
    this.props.firebase.auth.onAuthStateChanged(
      function(user) {
        if (user) {
          console.log("user signed in, should forward");
          if (user.email == admin[0]) {
            this.setState({ signedIn: true, admin: true, loading: false });
          } else {
            this.setState({ signedIn: true, admin: false, loading: false });
          }
        } else {
          console.log("user signed out");
          this.setState({ signedIn: false, loading: false });
        }
      }.bind(this)
    );
  };
  checkRoute = () => {
    console.log("current path is : " + this.state.currentPath);
    if (this.state.currentPath.substr(0, 3) == "/da") {
      this.setState({ dashboard: true });
    } else {
      this.setState({ dashboard: false });
    }
  };
  componentDidMount() {
    console.log("from appJS, pathname is : " + window.location.pathname);
    this.setupAuthListener();
    this.checkRoute();
  }

  render() {
    let content;

    if (this.state.loading) {
      content = <LoadingScreen />;
    } else {
      content = (
        <Router>
          <div className="appContainer d-flex flex-column">
            <Route
              exact
              path="/"
              render={props => (
                <Landing
                  {...props}
                  signedIn={this.state.signedIn}
                  admin={this.state.admin}
                />
              )}
            />
            <Route
              exact
              path="/login"
              render={props => (
                <Login
                  {...props}
                  signedIn={this.state.signedIn}
                  admin={this.state.admin}
                />
              )}
            />
            <Route
              exact
              path="/contact"
              render={props => (
                <Contact
                  {...props}
                  signedIn={this.state.signedIn}
                  admin={this.state.admin}
                />
              )}
            />
            <Route
              path="/portfolio"
              render={props => (
                <Home
                  {...props}
                  signedIn={this.state.signedIn}
                  admin={this.state.admin}
                />
              )}
            />
            <Route
              path="/pricing"
              render={props => (
                <Pricing
                  {...props}
                  signedIn={this.state.signedIn}
                  admin={this.state.admin}
                />
              )}
            />
            <Route
              path="/about"
              render={props => (
                <About
                  {...props}
                  signedIn={this.state.signedIn}
                  admin={this.state.admin}
                />
              )}
            />
            <Switch>
              <PrivateAdminRoute
                signedIn={this.state.signedIn}
                admin={this.state.admin}
                loading={this.state.loading}
                path="/dashboard"
                component={Dashboard}
              />
            </Switch>
            <Switch>
              <PrivateRoute
                path="/client-dashboard"
                firebase={this.props.firebase}
                component={ClientDashboard}
                signedIn={this.state.signedIn}
                admin={this.state.admin}
                loading={this.state.loading}
              />
            </Switch>
          </div>
        </Router>
      );
    }

    return content;
  }
}

export default compose(withFirebase)(App);
