import React, { Component } from "react";
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";
import Confirmation from "./../../common/Confirmation";

class GlobalManagement extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      confirmation: false
    };
  }
  generateDefaults = () => {
    this.setState({
      confirmation: true,
      question: "Are You Sure You Want To Generate Page Defaults?"
    });
  };
  answerConfirm = answer => {
    this.setState({ confirmation: false, loading: true });
    if (answer) {
      let about = {
        aboutImage: { url: "", thumbFive: "" },
        defaultImage: { url: "", thumbFive: "" },
        headingColor: "white",
        headingShadow: "yes",
        navStyle: "sticky",
        overlay: "none",
        pageHeading: "Edit Page Heading",
        paragraphs: [""]
      };
      let pricing = {
        defaultImage: { url: "", thumbFive: "" },
        headingColor: "white",
        headingShadow: "yes",
        navStyle: "sticky",
        overlay: "none",
        pageHeading: "Edit Page Heading",
        packages: []
      };
      let contact = {
        defaultImage: { url: "", thumbFive: "" },
        email: "test@test.com",
        formRecipient: "test@test.com",
        name: "NAME",
        paragraph: "",
        phone: "222",
        headingColor: "white",
        headingShadow: "yes",
        navStyle: "sticky",
        overlay: "none",
        pageHeading: "Edit Page Heading"
      };
      let home = {
        homeHeading: "Edit Home Heading",
        homeParagraph: "Edit Home Paragraph"
      };
      this.props.firebase.db
        .collection("pages")
        .doc("about")
        .set(about)
        .then()
        .catch(function(err) {
          console.log("error writing about doc");
        });
      this.props.firebase.db
        .collection("pages")
        .doc("pricing")
        .set(pricing)
        .then()
        .catch(function(err) {
          console.log("error writing pricing doc");
        });
      this.props.firebase.db
        .collection("pages")
        .doc("contact")
        .set(contact)
        .then()
        .catch(function(err) {
          console.log("error writing contact doc");
        });
      this.props.firebase.db
        .collection("pages")
        .doc("home")
        .set(home)
        .then()
        .catch(function(err) {
          console.log("error writing home doc");
        });
      this.setState({ loading: false, message: "Defaults Have Been Set." });
    }
  };
  render() {
    return (
      <div>
        {this.state.confirmation ? (
          <Confirmation
            question={this.state.question}
            answer={this.answerConfirm}
          />
        ) : (
          ""
        )}
        {this.state.loading ? (
          <i className="spinner fas fa-circle-notch fa-spin" />
        ) : (
          <div>
            <p className="lead">Global Settings</p>
            {this.state.message !== undefined ? (
              <p className="lead alert">{this.state.message}</p>
            ) : (
              ""
            )}
            <div className="row row-space">
              <button
                onClick={this.generateDefaults}
                className="btn btn-sm btn-secondary"
              >
                Generate Page Defaults (will overwrite old settings)
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default compose(withFirebase)(GlobalManagement);
