import React, { Component } from "react";
import formatDate from "./../../common/formatDate";
import { Link } from "react-router-dom";
class ClientsTable extends Component {
  constructor() {
    super();
    this.state = {
      selectedClient: null
    };
    this.selectClient = this.selectClient.bind(this);
  }
  selectClient(event) {
    if (this.state.selectedClient == event) {
      this.props.selectClient(null);
      this.setState({ selectedClient: null });
    } else {
      this.props.selectClient(event);
      this.setState({ selectedClient: event });
    }
  }
  render() {
    return (
      <div className="clientsTableContainer">
        <table className="table table-hover">
          <thead className="thead-dark">
            <tr>
              <th></th>
              <th
                className="sort"
                scope="col"
                onClick={() => {
                  this.props.sort("name");
                }}
              >
                Name
              </th>
              <th
                className="sort"
                scope="col"
                onClick={() => {
                  this.props.sort("date");
                }}
              >
                Date Added
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.clients.map((client, i) => (
              <tr
                key={i}
                style={{
                  backgroundColor:
                    this.state.selectedClient == client ? "#ccc" : undefined
                }}
              >
                <td>
                  <input
                    type="checkbox"
                    checked={this.state.selectedClient == client ? true : false}
                    id="selectClient"
                    onClick={() => {
                      this.selectClient(client);
                    }}
                  ></input>
                </td>
                <th scope="row">
                  <Link to={"/dashboard/clients/" + client.uid}>
                    {client.fullName}
                  </Link>
                </th>
                <th scope="row">{formatDate(client.date)}</th>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ClientsTable;
