import React, { Component } from "react";
import SiteImageContainer from "../portfolioManagement/SiteImageContainer";
import { withFirebase } from "../../../../firebase/index";
import { compose } from "redux";
import "./pages.css";

class PagesManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      loading: true,
      activeImage: undefined,
      editActive: false,
      confirmation: false,
      homeSelecting: undefined,
      homeMessage: ""
    };
  }
  componentDidMount() {
    this.setImages();
    this.setHomeStuff();
  }
  componentWillReceiveProps(nextProps) {
    console.log("will recieve props");
    console.log("props are: " + nextProps.siteImages);
    this.setImages(nextProps);
  }
  setHomeStuff = () => {
    this.props.firebase.db
      .collection("pages")
      .doc("home")
      .get()
      .then(
        function(res) {
          if (res.exists) {
            let landing1 = res.data().landing1;
            let landing2 = res.data().landing2;
            let landing3 = res.data().landing3;
            let homeHeading = res.data().homeHeading;
            let homeParagraph = res.data().homeParagraph;
            this.setState({
              landing1: landing1,
              landing2: landing2,
              landing3: landing3,
              homeHeading: homeHeading,
              homeParagraph: homeParagraph
            });
          }
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting home stuff: " + err.message);
      });
  };
  setImages = nextProps => {
    let images = [];
    if (nextProps == undefined) {
      images = [...this.props.siteImages];
      console.log("inside setImages: " + JSON.stringify(images[0]));
    } else {
      images = [...nextProps.siteImages];
    }
    images.forEach(function(img) {
      img.selected = false;
    });
    if (this.state.activeIndex !== undefined && images !== undefined) {
      if (images[this.state.activeIndex] !== undefined) {
        images[this.state.activeIndex].selected = true;
      }
    }

    this.setState({
      images: images,
      loading: false,
      activeImage: images[this.state.activeIndex]
    });
  };
  setHomeSelecting = index => {
    if (index == this.state.homeSelecting) {
      this.setState({ homeSelecting: undefined });
    } else {
      this.setState({ homeSelecting: index });
    }
  };
  saveHomeImages = () => {
    this.setState({ loading: true });
    let home = {
      landing1: this.state.landing1,
      landing2: this.state.landing2,
      landing3: this.state.landing3
    };
    this.props.firebase.db
      .collection("pages")
      .doc("home")
      .set(home, { merge: true })
      .then(
        function(res) {
          this.setState({
            homeMessage: "Successfully Wrote Home Images",
            loading: false
          });
        }.bind(this)
      )
      .catch(
        function(err) {
          console.log("error writing home images: " + err.message);
          this.setState({
            homeMessage:
              "Error Writing Home Images. Check Connection And Try Again",
            loading: false
          });
        }.bind(this)
      );
  };
  changeHomeHeading = event => {
    this.setState({ homeHeading: event.target.value });
  };
  changeHomeParagraph = event => {
    this.setState({ homeParagraph: event.target.value });
  };
  saveHomeText = () => {
    console.log("saving home text");
    this.setState({ loading: true });
    let home = {
      homeHeading: this.state.homeHeading,
      homeParagraph: this.state.homeParagraph
    };
    this.props.firebase.db
      .collection("pages")
      .doc("home")
      .set(home, { merge: true })
      .then(
        function(res) {
          console.log("success");
          this.setState({
            loading: false,
            homeMessage: "Successfully Wrote Home Text"
          });
        }.bind(this)
      )
      .catch(
        function(err) {
          this.setState({
            loading: false,
            homeMessage:
              "Error Writing Home Text, Check Connection And Try Again"
          });
          console.log("error writing home text: " + err.message);
        }.bind(this)
      );
  };
  selectImage = image => {
    let images = this.state.images;
    this.state.images.forEach(
      function(siteImage, i) {
        if (image == siteImage) {
          console.log("found image that was clicked");
          console.log("image is :" + JSON.stringify(siteImage));

          if (image.selected == true) {
            console.log("setting active image to undefined");
            images[i].selected = false;
            if (this.state.homeSelecting == 1) {
              this.setState({ landing1: undefined, activeIndex: undefined });
            } else if (this.state.homeSelecting == 2) {
              this.setState({ landing2: undefined, activeIndex: undefined });
            } else if (this.state.homeSelecting == 3) {
              this.setState({ landing3: undefined, activeIndex: undefined });
            }
          } else {
            console.log("setting active image to image");
            images[i].selected = true;
            if (this.state.homeSelecting == 1) {
              this.setState({ landing1: siteImage, activeIndex: i });
            } else if (this.state.homeSelecting == 2) {
              this.setState({ landing2: siteImage, activeIndex: i });
            } else if (this.state.homeSelecting == 3) {
              this.setState({ landing3: siteImage, activeIndex: i });
            }
          }
        } else {
          images[i].selected = false;
        }
        this.setState({ images: images });
      }.bind(this)
    );
  };
  render() {
    return (
      <div>
        {this.state.loading ? (
          <i className="spinner fas fa-circle-notch fa-spin" />
        ) : (
          <div>
            <p className="lead">Home Page</p>
            {this.state.homeMessage !== "" ? (
              <p className="alert">{this.state.homeMessage}</p>
            ) : (
              ""
            )}
            <button
              className="btn btn-sm btn-secondary"
              onClick={this.saveHomeImages}
            >
              Save Homepage Images
            </button>
            <div className="homeImageRow row row-space">
              <div className="col col-xs-4">
                <p className="small">Landing Image 1</p>
                <input
                  onClick={() => {
                    this.setHomeSelecting(1);
                  }}
                  checked={this.state.homeSelecting == 1 ? true : false}
                  type="radio"
                ></input>
                <br />
                {this.state.landing1 !== undefined ? (
                  <img className="pagesImage" src={this.state.landing1.url} />
                ) : (
                  ""
                )}
              </div>
              <div className="col col-xs-4">
                <p className="small">Landing Image 2</p>
                <input
                  onClick={() => {
                    this.setHomeSelecting(2);
                  }}
                  checked={this.state.homeSelecting == 2 ? true : false}
                  type="radio"
                ></input>
                <br />
                {this.state.landing2 !== undefined ? (
                  <img className="pagesImage" src={this.state.landing2.url} />
                ) : (
                  ""
                )}
              </div>
              <div className="col col-xs-4">
                <p className="small">Landing Image 3</p>
                <input
                  onClick={() => {
                    this.setHomeSelecting(3);
                  }}
                  checked={this.state.homeSelecting == 3 ? true : false}
                  type="radio"
                ></input>
                <br />
                {this.state.landing3 !== undefined ? (
                  <img className="pagesImage" src={this.state.landing3.url} />
                ) : (
                  ""
                )}
              </div>
            </div>
            Selecting Photo For {this.state.homeSelecting}
            <SiteImageContainer
              siteImages={this.state.images}
              select={this.selectImage}
            />
            <div className="form-group row">
              <div className="col col-xs-12 col-md-6">
                <p className="lead">Set Home Heading</p>
                <input
                  style={{ width: "100%" }}
                  type="text"
                  onChange={this.changeHomeHeading}
                  value={this.state.homeHeading}
                />
              </div>
              <div className="col col-xs-12 col-md-6">
                <p className="lead">Set Home Paragraph</p>
                <textarea
                  style={{ width: "100%" }}
                  type="textarea"
                  onChange={this.changeHomeParagraph}
                  value={this.state.homeParagraph}
                />
              </div>
            </div>
            <button
              className="btn btn-sm btn-secondary"
              onClick={this.saveHomeText}
            >
              Save Home Text
            </button>
          </div>
        )}
      </div>
    );
  }
}
export default compose(withFirebase)(PagesManagement);
