import React, { Component } from "react";
import SiteImageContainer from "../portfolioManagement/SiteImageContainer";
import { withFirebase } from "../../../../firebase/index";
import OverlayModule from "./OverlayModule";
import Backdrop from "./../../../common/Backdrop";
import SetAboutImage from "./SetAboutImage";
import { compose } from "redux";
import "./pages.css";

class AboutManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      images: [],
      pageHeading: "About",
      headingColor: "white",
      headingShadow: false,
      setAboutImage: false,
      paragraphs: [""],
      navStyle: "sticky"
    };
  }
  componentDidMount() {
    console.log("about management mounted");
    this.setImages();
  }
  saveAboutSettings = () => {
    this.setState({ loading: true });

    this.props.firebase.db
      .collection("pages")
      .doc("about")
      .set(
        {
          overlay: this.state.overlay,
          defaultImage: this.state.activeImage,
          pageHeading: this.state.pageHeading,
          headingColor: this.state.headingColor,
          headingShadow: this.state.headingShadow,
          navStyle: this.state.navStyle
        },
        { merge: true }
      )
      .then(
        function(res) {
          console.log("successfully wrote about settings");
          this.setState({
            message: "Successfully Wrote About Page Settings",
            loading: false
          });
        }.bind(this)
      )
      .catch(
        function(err) {
          console.log("error setting About stuff");
          this.setState({
            message:
              "Error Writing About Settings. Check Connection And Try Again",
            loading: false
          });
        }.bind(this)
      );
  };
  setImages = nextProps => {
    console.log("inside setImages in PricingManagement");
    let images = [];
    if (nextProps == undefined) {
      images = [...this.props.siteImages];
      console.log("inside setImages: " + JSON.stringify(images[0]));
    } else {
      images = [...nextProps.siteImages];
    }
    images.forEach(function(img) {
      console.log("going through images selected false");
      img.selected = false;
    });
    if (this.state.activeIndex !== undefined && images !== undefined) {
      if (images[this.state.activeIndex] !== undefined) {
        images[this.state.activeIndex].selected = true;
      }
    }

    this.setState(
      {
        images: images,
        activeImage: images[this.state.activeIndex]
      },
      () => {
        this.getAboutStuff();
      }
    );
  };
  componentWillReceiveProps(nextProps) {
    this.setImages(nextProps);
  }
  getAboutStuff = () => {
    console.log("getting about stuff");
    this.props.firebase.db
      .collection("pages")
      .doc("about")
      .get()
      .then(
        function(res) {
          if (res.exists) {
            let about = res.data();
            let navStyle;
            if (about.navStyle == undefined) {
              navStyle = "sticky";
            } else {
              navStyle = about.navStyle;
            }
            let aboutContent;
            if (about.content == undefined) {
              aboutContent = "";
            } else {
              aboutContent = about.content;
            }
            let aboutImage;
            if (about.aboutImage == undefined) {
              aboutImage = "";
            } else {
              aboutImage = about.aboutImage;
            }
            let defaultImage;
            if (about.defaultImage == undefined) {
              defaultImage = "";
            } else {
              defaultImage = about.defaultImage;
            }
            let overlay;
            if (about.overlay == undefined) {
              overlay = "none";
            } else {
              overlay = about.overlay;
            }
            let headingColor;
            if (about.headingColor == undefined) {
              headingColor = "white";
            } else {
              headingColor = about.headingColor;
            }
            let pageHeading;
            if (about.pageHeading == undefined) {
              pageHeading = "Edit Page Heading";
            } else {
              pageHeading = about.pageHeading;
            }
            let headingShadow;
            if (about.headingShadow == undefined) {
              headingShadow = "none";
            } else {
              headingShadow = about.headingShadow;
            }
            let paragraphs = [];
            if (about.paragraphs == undefined) {
              paragraphs = [""];
            } else {
              paragraphs = about.paragraphs;
            }
            this.setState({
              aboutContent: aboutContent,
              aboutImage: aboutImage,
              activeImage: defaultImage,
              overlay: overlay,
              loading: false,
              headingColor: headingColor,
              pageHeading: pageHeading,
              headingShadow: headingShadow,
              paragraphs: paragraphs,
              navStyle: navStyle
            });
          } else {
            console.log("no about doc, setting state");
            this.setState({
              aboutContent: {},
              aboutImage: undefined,
              activeImage: undefined,
              overlay: false,
              loading: false,
              headingColor: "white",
              pageHeading: "About",
              headingShadow: false,
              paragraphs: [""],
              navStyle: "sticky"
            });
          }
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting about stuff");
      });
  };
  selectImage = image => {
    console.log("inside select image: " + JSON.stringify(image));
    let images = this.state.images;
    let activeImage = undefined;
    let activeIndex = undefined;
    images.forEach(
      function(siteImage, i) {
        if (image == siteImage) {
          console.log("found image in siteImages");
          if (siteImage.selected == true) {
            siteImage.selected = false;
          } else {
            activeImage = siteImage;
            activeIndex = i;
            siteImage.selected = true;
          }
        } else {
          siteImage.selected = false;
        }
      }.bind(this)
    );
    this.setState({
      images: images,
      activeImage: activeImage,
      activeIndex: activeIndex
    });
  };
  setAboutImage = () => {
    this.setState({ setAboutImage: true });
  };
  closeSetAboutImage = () => {
    this.setState({ setAboutImage: false });
  };
  answerSetAboutImage = image => {
    console.log("inside answerSetAboutImage: " + JSON.stringify(image));
    this.setState({ setAboutImage: false }, () => {
      let about = { aboutImage: image };
      this.props.firebase.db
        .collection("pages")
        .doc("about")
        .set(about, { merge: true })
        .then(
          function(res) {
            console.log("success writing about image");
            this.setState({ message: "Successfully Wrote About Image" }, () => {
              this.getAboutStuff();
            });
          }.bind(this)
        )
        .catch(
          function(err) {
            console.log("error writing about image: " + err.message);
            this.setState({
              message:
                "Error Writing About Image. Check Connection And Try Again."
            });
          }.bind(this)
        );
    });
  };
  answerNavStyle = style => {
    switch (style) {
      case "sticky":
        this.setState({ navStyle: "sticky" });
        break;
      case "animated":
        this.setState({ navStyle: "animated" });
        break;
      case "relative":
        this.setState({ navStyle: "relative" });
        break;
      default:
        break;
    }
  };
  overlayAnswer = answer => {
    console.log("inside overlay answer");
    this.setState({ overlay: answer });
  };
  setPageHeading = heading => {
    console.log("inside set page heading, setting heading to: " + heading);
    this.setState({ pageHeading: heading });
  };
  setPageHeadingColor = color => {
    console.log("inside setpageheadingcolor setting color to " + color);
    this.setState({ headingColor: color });
  };
  setPageHeadingShadow = answer => {
    console.log("inside setpageheadingshadow: " + answer);
    this.setState({ headingShadow: answer });
  };
  addAboutParagraph = () => {
    let paragraphs = this.state.paragraphs;
    paragraphs.push("");
    this.setState({ paragraphs: paragraphs });
  };
  onParagraphChange = (event, i) => {
    console.log(
      "inside onParagraph change, changeing : " +
        i +
        " to " +
        event.target.value
    );
    let paragraphs = this.state.paragraphs;
    paragraphs[i] = event.target.value;
    this.setState({ paragraphs: paragraphs });
  };
  removeParagraph = i => {
    let paragraphs = this.state.paragraphs;
    paragraphs.splice(i, 1);
    this.setState({ paragraphs: paragraphs });
  };
  saveAboutParagraphs = () => {
    this.setState({ loading: true });
    this.props.firebase.db
      .collection("pages")
      .doc("about")
      .set({ paragraphs: this.state.paragraphs }, { merge: true })
      .then(
        function(res) {
          this.setState({
            message: "Successfully Saved About Paragraphs",
            loading: false
          });
        }.bind(this)
      )
      .catch(
        function(err) {
          console.log("error saving about paragraphs");
          this.setState({
            message: "Successfully Saved About Paragraphs",
            loading: false
          });
        }.bind(this)
      );
  };
  render() {
    return (
      <div>
        {this.state.loading ? (
          <i className="spinner fas fa-circle-notch fa-spin" />
        ) : (
          <div>
            {this.state.setAboutImage ? (
              <div>
                <SetAboutImage
                  images={this.state.images}
                  setAboutImage={this.answerSetAboutImage}
                  close={this.closeSetAboutImage}
                />
                <Backdrop click={this.closeSetAboutImage} />
              </div>
            ) : (
              ""
            )}
            <p className="lead">About Page</p>
            {this.state.message !== "" ? (
              <p className="alert">{this.state.message}</p>
            ) : (
              ""
            )}
            <div className="row row-space">
              <div className="col col-xs-12 col-md-6">
                {this.state.aboutImage !== undefined ? (
                  <div className="managementRow">
                    <p className="small">About Image:</p>
                    <img src={this.state.aboutImage.thumbFive} />
                  </div>
                ) : (
                  ""
                )}
                <button
                  onClick={this.setAboutImage}
                  className="btn btn-sm btn-secondary"
                >
                  Set About Image
                </button>
              </div>
              <div className="col col-xs-12 col-md-6">
                <button
                  className="btn btn-sm btn-secondary pageManagementControl"
                  onClick={this.addAboutParagraph}
                >
                  Add About Paragraph
                </button>
                {this.state.paragraphs.map((paragraph, i) => (
                  <div className="form-group  pageManagementControl">
                    <textarea
                      value={this.state.paragraphs[i]}
                      onChange={event => {
                        this.onParagraphChange(event, i);
                      }}
                    ></textarea>
                    <br />
                    <button
                      className="btn btn-sm btn-secondary  pageManagementControl"
                      onClick={() => {
                        this.removeParagraph(i);
                      }}
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <button
                  className="btn btn-sm btn-secondary  pageManagementControl"
                  onClick={this.saveAboutParagraphs}
                >
                  Save About Paragraphs
                </button>
              </div>
            </div>

            <div className="row row-space managementRow">
              {this.state.activeImage !== undefined ? (
                <div className="activeBackgroundImage">
                  <img src={this.state.activeImage.thumbFive} />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="row row-space managementRow">
              {" "}
              Selecting Photo For About Page Background
              <SiteImageContainer
                siteImages={this.state.images}
                select={this.selectImage}
              />
            </div>

            <div className="row row-space managementRow">
              <OverlayModule
                answer={this.overlayAnswer}
                overlay={this.state.overlay}
                setPageHeading={this.setPageHeading}
                answerHeadingColor={this.setPageHeadingColor}
                headingColor={this.state.headingColor}
                pageHeading={this.state.pageHeading}
                answerHeadingShadow={this.setPageHeadingShadow}
                headingShadow={this.state.headingShadow}
                answerNavStyle={this.answerNavStyle}
                navStyle={this.state.navStyle}
              />{" "}
              <div className="col col-md-4">
                <button
                  className="btn btn-sm btn-secondary"
                  onClick={this.saveAboutSettings}
                >
                  Save About Page Settings
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default compose(withFirebase)(AboutManagement);
