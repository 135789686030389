import React, { Component } from "react";
import Tabs from "../Tabs";
import { compose } from "redux";
import { withFirebase } from "./../../../firebase/index";

import Backdrop from "./../../common/Backdrop";

import LoadingScreen from "./../../common/LoadingScreen";
import Confirmation from "./../../common/Confirmation";
import InvoicesTable from "./InvoicesTable";

import axios from "axios";

class Invoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addClient: false,
      editClient: false,
      invoices: [],
      confirmation: false
    };
  }

  getInvoices = () => {
    let invoices = [];
    this.props.firebase.db
      .collection("invoices")
      .get()
      .then(
        function(userSnapshot) {
          console.log("got response from getInvoices");
          userSnapshot.forEach(
            function(invoice) {
              if (invoice.exists) {
                console.log("got invoice");
                invoices.push(invoice.data());
              }
            }.bind(this)
          );
          this.setState({ invoices: invoices });
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting invoices: " + err.message);
      });
  };
  deleteInvoice = () => {
    this.props.firebase.db
      .collection("users")
      .doc(this.state.selectedInvoice.recipient.uid)
      .collection("invoices")
      .doc(this.state.selectedInvoice.id)
      .delete()
      .then(
        function(res) {
          this.props.firebase.db
            .collection("invoices")
            .doc(this.state.selectedInvoice.id)
            .delete()
            .then(
              function(res) {
                this.setState(
                  {
                    loading: false,
                    message: "Successfully Deleted Invoice",
                    selectedInvoice: undefined
                  },
                  () => {
                    this.getInvoices();
                  }
                );
              }.bind(this)
            )
            .catch(function(err) {
              console.log(
                "error deleting invoice from invoice node: " + err.message
              );
            });
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error deleting invoice from user node: " + err.message);
      });
  };
  selectInvoice = invoice => {
    console.log("from selectinvoice, invoice is : " + JSON.stringify(invoice));
    if (invoice === this.state.selectedInvoice || invoice === null) {
      console.log("unselecting");
      this.setState({ selectedInvoice: undefined });
    } else {
      this.setState({ selectedInvoice: invoice });
    }
  };
  componentDidMount() {
    this.getInvoices();
  }
  answerConfirmDelete = answer => {
    this.setState({ confirmation: false, loading: true });
    if (answer) {
      this.deleteInvoice();
    }
  };
  confirmDelete = () => {
    this.setState({ confirmation: true });
  };

  render() {
    return (
      <div>
        <Tabs />
        {this.state.loading ? (
          <LoadingScreen />
        ) : (
          <div className="uploadFormContainer">
            <div className="uploadForm">
              <p className="display-4 dashboardHeading">Invoices</p>
              {this.state.message !== undefined ? (
                <p className="lead">{this.state.message}</p>
              ) : (
                ""
              )}
              <div className="buttonContainer">
                {" "}
                {this.state.selectedInvoice !== undefined ? (
                  <div>
                    {" "}
                    <button
                      className="controlButton btn btn-sm btn-secondary"
                      onClick={this.confirmDelete}
                    >
                      Delete Invoice
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <br />
              <InvoicesTable
                selectInvoice={this.selectInvoice}
                invoices={this.state.invoices}
              />
            </div>
          </div>
        )}
        {this.state.confirmation ? (
          <Confirmation
            question="Are You Sure You Want To Delete The Selected Invoice?"
            answer={this.answerConfirmDelete}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default compose(withFirebase)(Invoices);
