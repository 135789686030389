import React, { Component } from "react";
import Confirmation from "./../../common/Confirmation";
export default class SendInvoice extends Component {
  constructor() {
    super();
    this.state = {
      amount: [0],
      description: "",
      valid: false,
      loading: false,
      confirmation: false
    };
  }
  answerConfirmSend = answer => {
    this.setState({ confirmation: false });
    if (answer) {
      this.sendInvoice();
    }
  };
  sendInvoice = () => {
    let message = {
      amount: this.state.amountFloat,
      description: this.state.description
    };
    this.props.sendInvoice(message);
  };
  submit = () => {
    this.setState({ confirmation: true });
  };
  onValueChange = event => {
    let amount = event.target.value;
    console.log("inside onvaluechange, amount to add to array is : " + amount);

    this.setState(
      {
        amount: amount
      },
      () => {
        let amountString = this.state.amount.toString();
        var currenctyFormatted =
          amountString.substring(0, amountString.length - 2) +
          "." +
          amountString.substring(amountString.length - 2);
        console.log("amountString is: " + JSON.stringify(currenctyFormatted));

        let amountFloat = parseFloat(currenctyFormatted).toFixed(2);
        let question =
          "Are You Sure You Want To Send An Invoice To " +
          this.props.client.fullName +
          " For: " +
          amountFloat +
          "?";
        if (amountFloat >= 1) {
          this.setState({
            amountFloat: amountFloat,
            question: question,
            valid: true
          });
        } else {
          this.setState({
            amountFloat: amountFloat,
            question: question,
            valid: false
          });
        }
      }
    );
  };
  onChange = event => {
    console.log("inside onchange");
    switch (event.target.name) {
      case "description":
        if (event.target.value !== "") {
          if (this.state.amountFloat >= 1) {
            this.setState({ description: event.target.value, valid: true });
          } else {
            this.setState({ description: event.target.value, valid: false });
          }
        } else {
          this.setState({ description: event.target.value, valid: false });
        }
        break;
      default:
        break;
    }
  };
  render() {
    return (
      <div className="sendEmail card addClient">
        {this.state.loading ? (
          <i className="spinner fas fa-circle-notch fa-spin" />
        ) : (
          <div className="container container-fluid">
            <p className="lead">Send {this.props.client.fullName} an invoice</p>
            <div className="form-group row">
              <div className="col col-sm-6">
                <label htmlFor="name">Amount: ${this.state.amountFloat}</label>

                <input
                  type="number"
                  value="0"
                  className="form-control"
                  value={this.state.amount}
                  name="inputAmount"
                  onChange={this.onValueChange}
                  id="inputAmount"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col col-sm-12">
                {" "}
                <label htmlFor="content">Description:</label>
                <textarea
                  className="form-control"
                  name="description"
                  id="content"
                  value={this.state.description}
                  onChange={this.onChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-9"></div>
              <div className="col-sm-3 ">
                <button
                  disabled={this.state.valid ? false : true}
                  onClick={this.submit}
                  className="btn btn-secondary"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
        {this.state.confirmation ? (
          <Confirmation
            question={this.state.question}
            answer={this.answerConfirmSend}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}
