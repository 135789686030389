import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { withFirebase } from "./../../firebase/index";
import { compose } from "redux";
import NavBar from "./../nav/NavBar";
import StaticNav from "./../nav/StaticNav";
import "./portfolio.css";
import Portfolio from "./Portfolio";
import LoadingScreen from "./../common/LoadingScreen";
import RelativeNav from "./../nav/RelativeNav";
import Footer from "./../Footer";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      portfolios: [],
      loading: true,
      activePort: undefined,
      overlay: undefined,
      headingColor: undefined,
      headingShadow: undefined,
      pageHeading: undefined
    };
  }
  getPortStuff = () => {
    this.props.firebase.db
      .collection("portfolios")
      .get()
      .then(
        function(res) {
          console.log("got res from ports");
          let portfolios = [];
          let portName = window.location.href;
          let strIndex = portName.lastIndexOf("/");
          let portUrl = portName.substr(strIndex + 1);
          let activePort;
          console.log("looking for: " + portUrl);
          res.forEach((port, i) => {
            portfolios.push(port.data());
            if (port.data().url == portUrl) {
              console.log("found active port: " + JSON.stringify(port.data()));
              activePort = port.data();
            }
          });
          let navStyle;
          if (activePort.navStyle == undefined) {
            navStyle = "sticky";
          } else {
            navStyle = activePort.navStyle;
          }
          this.setState({
            portfolios: portfolios,
            activePort: activePort,
            defaultImage: activePort.defaultimage,
            overlay: activePort.overlay,
            headingColor: activePort.headingColor,
            headingShadow: activePort.headingShadow,
            pageHeading: activePort.pageHeading,
            navStyle: activePort.navStyle,
            loading: false
          });
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting port stuff: " + err.message);
      });
  };
  componentWillReceiveProps() {
    this.getPortStuff();
  }
  componentDidMount() {
    console.log("inside home portfolio component");
    this.getPortStuff();
  }
  setNavHeight = () => {
    let height;
    if (this.divElement == undefined) {
      height = 150;
    } else {
      height = this.divElement.clientHeight;
    }
    console.log("height is: " + height);
    this.setState({ navHeight: height });
  };
  render() {
    let overlayColor;
    let nav;
    let height = window.innerHeight;
    console.log("HEIGHT is : " + height);
    let overlayHeight;
    switch (this.state.navStyle) {
      case "sticky":
        overlayHeight = "100%";
        nav = (
          <NavBar
            active="portfolio"
            signedIn={this.props.signedIn}
            admin={this.props.admin}
          />
        );
        break;
      case "animated":
        overlayHeight = height - this.state.navHeight;
        console.log("setting overlayHeight to : " + overlayHeight);
        nav = (
          <RelativeNav
            active="portfolio"
            signedIn={this.props.signedIn}
            admin={this.props.admin}
            setHeight={this.setNavHeight}
          />
        );
        break;
      case "relative":
        overlayHeight = height - this.state.navHeight;
        nav = (
          <StaticNav
            active="portfolio"
            signedIn={this.props.signedIn}
            admin={this.props.admin}
            setHeight={this.setNavHeight}
          />
        );
        break;
      default:
        break;
    }
    let defaultImage;
    if (this.state.activePort !== undefined) {
      console.log(
        "from render, default image is: " + this.state.activePort.defaultImage
      );
      console.log("not undefined, setting defaultImage");
      defaultImage = this.state.activePort.defaultImage;
    } else {
      defaultImage = "";
    }
    switch (this.state.overlay) {
      case "light":
        overlayColor = "rgba(197, 195, 195, 0.262)";
        break;
      case "medium":
        overlayColor = "rgba(197, 195, 195, 0.462)";
        break;
      case "dark":
        overlayColor = "rgba(66, 66, 66, 0.568)";
        break;
      case "none":
        overlayColor = "rgba(66, 66, 66, 0)";
        break;
      default:
        overlayColor = "rgba(66, 66, 66, 0)";
        break;
    }

    return (
      <div>
        {this.state.loading ? (
          <LoadingScreen />
        ) : (
          <div
            className=" portfolioContainer landingImage"
            style={
              defaultImage !== undefined
                ? {
                    backgroundImage: "url(" + defaultImage + ")"
                  }
                : {}
            }
          >
            {nav}

            <div
              className="overlay"
              style={
                this.state.overlay ? { backgroundColor: overlayColor } : {}
              }
            >
              <div
                className={
                  this.state.activePort.navStyle == "sticky"
                    ? "portfolio"
                    : "relativePortfolio"
                }
              >
                <div className="container">
                  {" "}
                  <Route
                    exact
                    path="/portfolio/:portName"
                    component={Portfolio}
                  />
                </div>
              </div>
            </div>
            <Footer />
          </div>
        )}
      </div>
    );
  }
}

export default compose(withFirebase)(Home);
