import React, { Component } from "react";
import Tabs from "../Tabs";
import { withRouter } from "react-router-dom";
import PortfolioManagement from "./portfolioManagement/PortfolioManagement";
import PricingManagement from "./pagesManagement/PricingManagement";
import AboutManagement from "./pagesManagement/AboutManagement";
import ContactManagement from "./pagesManagement/ContactManagement";
import HomeManagement from "./pagesManagement/HomeManagement";
import ImagesManagement from "./imagesManagement/ImagesManagement";
import GlobalManagement from "./GlobalManagement";
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";
import { Route } from "react-router-dom";

class SiteManagement extends Component {
  constructor() {
    super();
    this.state = { active: "portfolio", siteImages: [], loading: true };
  }

  getSiteImages = () => {
    console.log("getting Site Images");
    this.setState({ loading: true });
    let images = [];
    this.props.firebase.db
      .collection("site-images")
      .get()
      .then(
        function(imageSnapshot) {
          imageSnapshot.forEach(function(image) {
            console.log("got image");
            images.push(image.data());
          });
          this.setState({ siteImages: images, loading: false });
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting site images: " + err.message);
      });
  };
  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      console.log("different path: " + nextProps.location.pathname);
      this.setCheckBoxes(nextProps.location.pathname);
    }
  }
  setCheckBoxes = pathString => {
    console.log("setting checkboxes");

    switch (pathString) {
      case "/dashboard/site-management/home":
        this.setState({ active: "home" });
        break;
      case "/dashboard/site-management/home/":
        this.setState({ active: "home" });
        break;
      case "/dashboard/site-management/pricing":
        console.log("setting active to pricing");
        this.setState({ active: "pricing" });
        break;
      case "/dashboard/site-management/pricing/":
        console.log("setting active to pricing");
        this.setState({ active: "pricing" });
        break;
      case "/dashboard/site-management/portfolio":
        this.setState({ active: "portfolio" });
        break;
      case "/dashboard/site-management/portfolio/":
        this.setState({ active: "portfolio" });
        break;
      case "/dashboard/site-management/about":
        this.setState({ active: "about" });
        break;
      case "/dashboard/site-management/about/":
        this.setState({ active: "about" });
        break;
      case "/dashboard/site-management/contact":
        this.setState({ active: "contact" });
        break;
      case "/dashboard/site-management/contact/":
        this.setState({ active: "contact" });
        break;
      case "/dashboard/site-management/images":
        this.setState({ active: "images" });
        break;
      case "/dashboard/site-management/images/":
        this.setState({ active: "images" });
        break;
      case "/dashboard/site-management/global":
        this.setState({ active: "global" });
        break;
      case "/dashboard/site-management/global/":
        this.setState({ active: "global" });
      default:
        console.log("setting to default, home");
        this.setState({ active: "home" });
    }
  };
  componentDidMount() {
    this.getSiteImages();
    console.log("location is : " + this.props.location.pathname);
    this.setCheckBoxes(this.props.location.pathname);
  }
  render() {
    return (
      <div>
        <Tabs />
        <div className="uploadFormContainer">
          {" "}
          <div className="uploadForm">
            <p className="display-4 dashboardHeading">Site Management</p>
            <br />
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="pagesRadio"
                id="pagesRadio"
                value="pages"
                checked={this.state.active == "home" ? true : false}
                onChange={() => {
                  this.props.history.push("/dashboard/site-management/home");
                }}
              />

              <label className="form-check-label" htmlFor="clientRadio">
                Home
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="portfolioRadio"
                id="portfolioRadio"
                value="portfolio"
                checked={this.state.active == "portfolio" ? true : false}
                onChange={() => {
                  this.props.history.push(
                    "/dashboard/site-management/portfolio"
                  );
                }}
              />

              <label className="form-check-label" htmlFor="siteRadio">
                Portfolio
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="pagesRadio"
                id="pagesRadio"
                value="pages"
                checked={this.state.active == "pricing" ? true : false}
                onChange={() => {
                  this.props.history.push("/dashboard/site-management/pricing");
                }}
              />

              <label className="form-check-label" htmlFor="clientRadio">
                Pricing
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="pagesRadio"
                id="pagesRadio"
                value="pages"
                checked={this.state.active == "about" ? true : false}
                onChange={() => {
                  this.props.history.push("/dashboard/site-management/about");
                }}
              />

              <label className="form-check-label" htmlFor="clientRadio">
                About
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="pagesRadio"
                id="pagesRadio"
                value="pages"
                checked={this.state.active == "contact" ? true : false}
                onChange={() => {
                  this.props.history.push("/dashboard/site-management/contact");
                }}
              />

              <label className="form-check-label" htmlFor="clientRadio">
                Contact
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="imagesRadio"
                id="imagesRadio"
                value="images"
                checked={this.state.active == "images" ? true : false}
                onChange={() => {
                  this.props.history.push("/dashboard/site-management/images");
                }}
              />

              <label className="form-check-label" htmlFor="siteRadio">
                Images
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="globalRadio"
                id="globalRadio"
                value="global"
                checked={this.state.active == "global" ? true : false}
                onChange={() => {
                  this.props.history.push("/dashboard/site-management/global");
                }}
              />

              <label className="form-check-label" htmlFor="siteRadio">
                Global
              </label>
            </div>
            <Route
              exact
              path="/dashboard/site-management/"
              render={props => (
                <HomeManagement
                  getImages={this.getSiteImages}
                  siteImages={this.state.siteImages}
                />
              )}
            />
            <Route
              exact
              path="/dashboard/site-management/home"
              render={props => (
                <HomeManagement
                  getImages={this.getSiteImages}
                  siteImages={this.state.siteImages}
                />
              )}
            />
            <Route
              exact
              path="/dashboard/site-management/pricing"
              render={props => (
                <PricingManagement
                  getImages={this.getSiteImages}
                  siteImages={this.state.siteImages}
                />
              )}
            />
            <Route
              exact
              path="/dashboard/site-management/portfolio"
              render={props => (
                <PortfolioManagement
                  getImages={this.getSiteImages}
                  siteImages={this.state.siteImages}
                />
              )}
            />
            <Route
              exact
              path="/dashboard/site-management/about"
              render={props => (
                <AboutManagement
                  getImages={this.getSiteImages}
                  siteImages={this.state.siteImages}
                />
              )}
            />
            <Route
              exact
              path="/dashboard/site-management/contact"
              render={props => (
                <ContactManagement
                  getImages={this.getSiteImages}
                  siteImages={this.state.siteImages}
                />
              )}
            />
            <Route
              exact
              path="/dashboard/site-management/images"
              render={props => (
                <ImagesManagement
                  getImages={this.getSiteImages}
                  siteImages={this.state.siteImages}
                />
              )}
            />
            <Route
              exact
              path="/dashboard/site-management/global"
              render={props => (
                <GlobalManagement
                  getImages={this.getSiteImages}
                  siteImages={this.state.siteImages}
                />
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default compose(withFirebase)(SiteManagement);
