import React, { Component } from "react";
import Gallery from "react-grid-gallery";

export default class ClientImageContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { images: [], loading: true, selectedImages: [] };
  }
  setImagesToState = nextProps => {
    console.log("setting images to state");
    let images = nextProps.images;

    this.setState({ images: images, loading: false }, () => {
      console.log("state is: " + JSON.stringify(this.state.images));
    });
  };
  //   componentWillReceiveProps() {
  //     this.setImagesToState();
  //   }
  componentWillReceiveProps(nextProps) {
    this.setImagesToState(nextProps);
  }
  //   componentDidMount() {
  //     this.setImagesToState();
  //   }
  selectImage = event => {
    console.log("selected image: " + JSON.stringify(event));
    let images = [...this.props.images];
    let newImages = [];
    let selectedImages = [];
    images.forEach((image, i) => {
      if (i === event) {
        if (image.isSelected) {
          image.isSelected = false;
        } else {
          console.log("setting image to selected");
          image.isSelected = true;
          selectedImages.push(image);
        }
      } else {
        if (image.isSelected) {
          selectedImages.push(image);
        }
      }
      newImages.push(image);
    });

    this.setState({ images: newImages, selectedImages: selectedImages }, () => {
      this.props.download(selectedImages);
    });
  };

  render() {
    let images = [];
    console.log("state is: " + JSON.stringify(this.state.images));
    this.state.images.forEach(function(image) {
      console.log("setting image");
      let newImg = {
        src: image.url,
        thumbnail: image.thumbFive,
        thumbnailWidth: 500,
        thumbnailHeight: 500,
        caption: image.caption,
        isSelected: image.isSelected
      };
      images.push(newImg);
    });
    return (
      <div>
        {this.state.loading ? (
          <i className="spinner fas fa-circle-notch fa-spin" />
        ) : (
          <Gallery
            onSelectImage={this.selectImage}
            onClickThumbnail={this.selectImage}
            enableLightbox={false}
            backdropClosesModal={true}
            images={images}
          />
        )}
      </div>
    );
  }
}
