import React from "react";

export default function OverlayModule(props) {
  return (
    <div className="col col-md-8">
      <p className="lead">Add Grey Overlay To Background?</p>
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="checkbox"
          id="inlineCheckbox1"
          onClick={() => {
            props.answer("light");
          }}
          checked={props.overlay == "light" ? true : false}
          value="light"
        />
        <label className="form-check-label" htmlFor="inlineCheckbox1">
          Light
        </label>
      </div>
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="checkbox"
          id="inlineCheckbox2"
          onClick={() => {
            props.answer("medium");
          }}
          checked={props.overlay == "medium" ? true : false}
          value="medium"
        />
        <label className="form-check-label" htmlFor="inlineCheckbox2">
          Medium
        </label>
      </div>
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="checkbox"
          id="inlineCheckbox2"
          onClick={() => {
            props.answer("dark");
          }}
          checked={props.overlay == "dark" ? true : false}
          value="dark"
        />
        <label className="form-check-label" for="inlineCheckbox2">
          Dark
        </label>
      </div>
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="checkbox"
          id="inlineCheckbox2"
          onClick={() => {
            props.answer("none");
          }}
          checked={props.overlay == "none" ? true : false}
          value="none"
        />
        <label className="form-check-label" for="inlineCheckbox2">
          None
        </label>
      </div>
      <hr />
      <div className="form-check form-check-inline">
        <input
          className="form-control"
          type="text"
          id="pageHeading"
          onChange={event => {
            props.setPageHeading(event.target.value);
          }}
          value={props.pageHeading}
        />
        <label className="form-check-label" for="inlineCheckbox2">
          Page Heading
        </label>
      </div>
      <hr />
      <p className="lead">Heading Text Color</p>
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="checkbox"
          id="inlineCheckbox1"
          onClick={() => {
            props.answerHeadingColor("white");
          }}
          checked={props.headingColor == "white" ? true : false}
          value="white"
        />
        <label className="form-check-label" for="inlineCheckbox1">
          White
        </label>
      </div>
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="checkbox"
          id="inlineCheckbox2"
          onClick={() => {
            props.answerHeadingColor("grey");
          }}
          checked={props.headingColor == "grey" ? true : false}
          value="grey"
        />
        <label className="form-check-label" for="inlineCheckbox2">
          Grey
        </label>
      </div>
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="checkbox"
          id="inlineCheckbox2"
          onClick={() => {
            props.answerHeadingColor("black");
          }}
          checked={props.headingColor == "black" ? true : false}
          value="black"
        />
        <label className="form-check-label" for="inlineCheckbox2">
          Black
        </label>
      </div>

      <hr />
      <p className="lead">Add Text Shadow Effect To Heading?</p>
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="checkbox"
          id="inlineCheckbox1"
          onClick={() => {
            props.answerHeadingShadow("yes");
          }}
          checked={props.headingShadow == "yes" ? true : false}
          value="yes"
        />
        <label className="form-check-label" for="inlineCheckbox1">
          Yes
        </label>
      </div>
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="checkbox"
          id="inlineCheckbox2"
          onClick={() => {
            props.answerHeadingShadow("no");
          }}
          checked={props.headingShadow == "no" ? true : false}
          value="no"
        />
        <label className="form-check-label" for="inlineCheckbox2">
          No
        </label>
      </div>

      <hr />
      <p className="lead">Navbar Style</p>
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="checkbox"
          id="inlineCheckbox1"
          onClick={() => {
            props.answerNavStyle("sticky");
          }}
          checked={props.navStyle == "sticky" ? true : false}
          value="sticky"
        />
        <label className="form-check-label" for="inlineCheckbox1">
          Sticky
        </label>
      </div>
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="checkbox"
          id="inlineCheckbox2"
          onClick={() => {
            props.answerNavStyle("animated");
          }}
          checked={props.navStyle == "animated" ? true : false}
          value="animated"
        />
        <label className="form-check-label" for="inlineCheckbox2">
          Animated (Scroll Away Style)
        </label>
      </div>
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="checkbox"
          id="inlineCheckbox2"
          onClick={() => {
            props.answerNavStyle("relative");
          }}
          checked={props.navStyle == "relative" ? true : false}
          value="relative"
        />
        <label className="form-check-label" for="inlineCheckbox2">
          Relative (No Parallax Or Animations)
        </label>
      </div>
    </div>
  );
}
