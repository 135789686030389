import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { withFirebase } from "./../../firebase/index";
import { compose } from "redux";
import NavBar from "./../nav/NavBar";
import "./pricing.css";
import LoadingScreen from "../common/LoadingScreen";
import StaticNav from "./../nav/StaticNav";
import RelativeNav from "./../nav/RelativeNav";
import Footer from "../Footer";
class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hiring: false,
      loading: true,
      navHeight: 150
    };
  }
  componentDidMount() {
    this.getPricingStuff();
    this.setNavHeight();
  }
  getPricingStuff = () => {
    console.log("getting pricing stuff");
    this.props.firebase.db
      .collection("pages")
      .doc("pricing")
      .get()
      .then(
        function(res) {
          if (res.exists) {
            let defaultImage = new Image();
            defaultImage.src = res.data().defaultImage.url;
            let navStyle;
            if (res.data().navStyle == undefined) {
              navStyle = "sticky";
            } else {
              navStyle = res.data().navStyle;
            }
            defaultImage.onload = () => {
              this.setState({
                packages: res.data().packages,
                defaultImage: res.data().defaultImage,
                overlay: res.data().overlay,
                loading: false,
                pageHeading: res.data().pageHeading,
                headingColor: res.data().headingColor,
                headingShadow: res.data().headingShadow,
                navStyle: navStyle
              });
            };
          } else {
            this.setState({
              packages: [],
              defaultImage: "",
              overlay: undefined,
              loading: false,
              pageHeading: "Edit Pricing Page In Dashboard",
              headingColor: "white",
              headingShadow: true,
              navStyle: "relative"
            });
          }
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting pricing stuff: " + err.message);
      });
  };
  closeHiring = () => {
    this.setState({ hiring: false });
  };
  setHiring = () => {
    this.setState({ hiring: true });
  };
  setNavHeight = height => {
    console.log("inside setNavHeight:" + height);
    this.setState({ navHeight: height });
  };
  openHiring = () => {
    this.props.history.push("/contact");
  };
  render() {
    let height = window.innerHeight;
    console.log("HEIGHT is : " + height);
    let overlayHeight;

    let nav;
    switch (this.state.navStyle) {
      case "sticky":
        overlayHeight = "100%";
        nav = (
          <NavBar
            active="pricing"
            signedIn={this.props.signedIn}
            admin={this.props.admin}
          />
        );
        break;
      case "animated":
        overlayHeight = height - this.state.navHeight;
        console.log("setting overlayHeight to : " + overlayHeight);
        nav = (
          <RelativeNav
            active="pricing"
            signedIn={this.props.signedIn}
            admin={this.props.admin}
            setHeight={this.setNavHeight}
          />
        );
        break;
      case "relative":
        overlayHeight = height - this.state.navHeight;
        nav = (
          <StaticNav
            active="pricing"
            signedIn={this.props.signedIn}
            admin={this.props.admin}
            setHeight={this.setNavHeight}
          />
        );
        break;
      default:
        break;
    }
    let overlayColor;
    switch (this.state.overlay) {
      case "light":
        overlayColor = "rgba(197, 195, 195, 0.262)";
        break;
      case "medium":
        overlayColor = "rgba(197, 195, 195, 0.462)";
        break;
      case "dark":
        overlayColor = "rgba(66, 66, 66, 0.568)";
        break;
      case "none":
        overlayColor = "rgba(66, 66, 66, 0)";
        break;
      default:
        overlayColor = "rgba(66, 66, 66, 0)";
        break;
    }
    let headingColor;
    switch (this.state.headingColor) {
      case "white":
        headingColor = "#fff";
        break;
      case "grey":
        headingColor = "#878787";
        break;
      case "black":
        headingColor = "#000";
        break;
      default:
        headingColor = "#878787";
        break;
    }
    return (
      <div>
        {" "}
        {this.state.loading ? (
          <LoadingScreen />
        ) : (
          <div>
            <div
              className=" pricingContainer landingImage"
              style={
                this.state.defaultImage !== undefined
                  ? {
                      backgroundImage:
                        "url(" + this.state.defaultImage.url + ")"
                    }
                  : {}
              }
            >
              {nav}
              <div
                className="overlay"
                style={
                  this.state.overlay
                    ? {
                        backgroundColor: overlayColor,
                        height: overlayHeight || 150
                      }
                    : {}
                }
              >
                {" "}
                <p
                  style={
                    this.state.headingShadow == "yes"
                      ? {
                          color: headingColor,
                          textShadow: "2px 4px 3px rgba(0,0,0,0.4)"
                        }
                      : { color: headingColor, textShadow: "none" }
                  }
                  className="lead pageHeading"
                >
                  {this.state.pageHeading}
                </p>
              </div>
              <div className="pricing">
                <div className="container">
                  {" "}
                  <p className="display-4">{this.state.pricingHeading}</p>
                  <div className="list-group">
                    {this.state.packages.map((pa, i) => (
                      <div
                        key={i}
                        className="list-group-item list-group-item-action flex-column align-items-start"
                      >
                        <div className="d-flex w-100 justify-content-between">
                          <h5 className="mb-1">{pa.name}</h5>
                        </div>
                        <p className="mb-1">{pa.description}</p>
                        <p>{pa.price}</p>
                        {pa.button ? (
                          <button
                            onClick={() => {
                              this.openHiring(pa.name);
                            }}
                            className="btn btn-sm btn-secondary"
                          >
                            Hire Katie For {pa.name}
                          </button>
                        ) : (
                          ""
                        )}
                        <small></small>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        )}{" "}
      </div>
    );
  }
}

export default compose(withFirebase)(Pricing);
