import React from "react";
import { withRouter, Link } from "react-router-dom";
import logo from "./../../assets/logo.png";

export default function MobileMenu(props) {
  let login, admin;
  if (props.signedIn) {
    if (props.admin) {
      login = (
        <Link
          onClick={() => {
            props.close();
          }}
          className=""
          to="/dashboard"
        >
          Dashboard
        </Link>
      );
    } else {
      login = (
        <Link
          onClick={() => {
            props.close();
          }}
          className=""
          to="/client-dashboard"
        >
          Dashboard
        </Link>
      );
    }
  } else {
    login = (
      <Link
        onClick={() => {
          props.close();
        }}
        to="/login"
      >
        Log In
      </Link>
    );
  }
  return (
    <div className="mobile">
      <div className="mobileNavBarRow animated fadeIn">
        <div className="mobileNavBar">
          {" "}
          <div className="mobileImageContainer col col-xs-6">
            {" "}
            <div className="image">
              <div style={{ verticalAlign: "middle" }}>
                <img
                  src={logo}
                  width="200px"
                  alt="Katie Kozma Photography"
                  style={{}}
                  className="logo"
                />
              </div>
            </div>
            <div style={{ width: 200 }} />
          </div>
          <div
            style={{ paddingRight: 30, textAlign: "right" }}
            className="col col-xs-6"
          >
            <a
              className="mobileLink exit"
              onClick={() => {
                props.close();
              }}
            >
              {" "}
              CLOSE
            </a>
          </div>
        </div>{" "}
        <div className="menuContainer animated fadeIn">
          <ul className="centeredMenu">
            <li className="mobileMenuLink">
              <Link
                onClick={() => {
                  props.close();
                }}
                className=""
                to="/"
              >
                Home
              </Link>
            </li>

            <li className="mobileMenuLink">
              <div className="btn-group dropdown">
                {" "}
                <button
                  className="dropdown-toggle portfolioDropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  to="/portfolio"
                >
                  Portfolio
                </button>
                <ul className="dropdown-menu mobilePortMenu">
                  {props.ports.map((port, i) => (
                    <li>
                      <Link
                        onClick={() => {
                          props.close();
                        }}
                        key={i}
                        to={port.path}
                      >
                        {port.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
            <li className="mobileMenuLink">
              <Link
                onClick={() => {
                  props.close();
                }}
                to="/pricing"
              >
                Pricing
              </Link>
            </li>
            <li className="mobileMenuLink">
              <Link
                onClick={() => {
                  props.close();
                }}
                to="/about"
              >
                About
              </Link>
            </li>
            <li className="mobileMenuLink">
              <Link
                onClick={() => {
                  props.close();
                }}
                to="/contact"
              >
                Contact
              </Link>
            </li>
            <li className="mobileMenuLink">{login}</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
