import React, { Component } from "react";
import { Link } from "react-router-dom";
import Tabs from "./../Tabs";
import ClientUploadUtility from "./../upload/ClientUploadUtility";
import SiteImageContainer from "./../site-management/portfolioManagement/SiteImageContainer";
import LoadingScreen from "./../../common/LoadingScreen";
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";
import Confirmation from "./../../common/Confirmation";
import SendEmail from "./SendEmail";
import SendInvoice from "./SendInvoice";
import Backdrop from "./../../common/Backdrop";
import axios from "axios";
import { adminSendMail } from "./../../../versionInfo";

class ClientManagement extends Component {
  constructor() {
    super();
    this.state = {
      client: undefined,
      loading: true,
      images: [],
      selectedImage: undefined,
      confirmDelete: false,
      sendEmail: false,
      sendInvoice: false,
      invoices: []
    };
  }
  getInvoices = () => {
    this.props.firebase.db
      .collection("users")
      .doc(this.state.client.uid)
      .collection("invoices")
      .get()
      .then(
        function(res) {
          let invoices = [];
          res.forEach((invoice, i) => {
            console.log("got invoice");
            invoices.push(invoice.data());
          });
          this.setState({ invoices: invoices });
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting invoices :" + err.message);
      });
  };
  toggleSendEmail = () => {
    this.setState(prevState => {
      return { sendEmail: !prevState.sendEmail };
    });
  };
  toggleSendInvoice = () => {
    this.setState(prevState => {
      return { sendInvoice: !prevState.sendInvoice };
    });
  };
  confirmDelete = () => {
    this.setState({ confirmDelete: true });
  };
  answerConfirmDelete = answer => {
    if (answer) {
      this.setState({ confirmDelete: false, loading: true }, () => {
        this.deleteActiveImage();
      });
    } else {
      this.setState({ confirmDelete: false });
    }
  };
  deleteActiveImage = () => {
    let strings = this.state.selectedImage.path.split("/");
    let lastPart = this.state.selectedImage.path.split("/").pop();

    let fiveLastPart = "thumb@500_" + lastPart;
    let fivePath =
      strings[0] +
      "/" +
      strings[1] +
      "/" +
      this.state.selectedImage.id +
      "/" +
      fiveLastPart;
    this.props.firebase.db
      .collection("users")
      .doc(this.state.client.uid)
      .collection("images")
      .doc(this.state.selectedImage.id)
      .delete()
      .then(
        function(res) {
          this.setState(
            { message: "Successfully Deleted Image object in db" },
            () => {
              this.deleteImage(this.state.selectedImage.path)
                .then(res => {
                  console.log("calling deleteImage for thumb image");
                  if (res == "success") {
                    this.deleteImage(fivePath)
                      .then(res => {
                        if (res == "success") {
                          console.log("successfully deleted both images");
                          this.getClientData();
                        } else {
                          console.log(
                            "error deleting thumb, deleting db entry anyway"
                          );
                          this.getClientData();
                        }
                      })
                      .catch(() => {
                        this.setState({
                          message:
                            "There Was A Problem Deleting thumb Image, Check Connection And Try Again."
                        });
                      });
                  } else {
                    this.getClientData();
                  }
                })
                .catch(() => {
                  this.setState({
                    message:
                      "There Was A Problem Deleting full Image, CHeck Connection And Try Again"
                  });
                });
            }
          );
        }.bind(this)
      );
  };
  deleteImage = path => {
    console.log("inside delete image: " + path);
    return new Promise(
      function(res, rej) {
        this.props.firebase.storage
          .ref(path)
          .delete()
          .then(function(obj) {
            console.log("successfully deleted image file");
            res("success");
          })
          .catch(function(err) {
            console.log("error deleting image: " + err.message);
            res(err.message);
          });
      }.bind(this)
    );
  };
  selectImage = image => {
    console.log("inside setImage: " + JSON.stringify(image));
    let images = [...this.state.images];
    let selectedImage;
    images.forEach((img, i) => {
      if (img.id == image.id) {
        console.log("found image to select");
        if (img.selected == true) {
          img.selected = false;
          selectedImage = undefined;
        } else {
          img.selected = true;
          selectedImage = img;
        }
      } else {
        img.selected = false;
      }
    });
    this.setState({ images: images, selectedImage: selectedImage });
  };
  generateFiveThumb = (fivePath, index) => {
    console.log("inside generateFiveThumb");
    return new Promise(
      function(promiseRes, rej) {
        this.props.firebase.storage
          .ref(fivePath)
          .getDownloadURL()
          .then(
            function(url) {
              console.log("url from generateFiveThumb is : " + url);

              this.props.firebase.db
                .collection("users")
                .doc(this.state.client.uid)
                .collection("images")
                .doc(this.state.images[index].id)
                .update({ thumbFive: url })
                .then(function(res) {
                  console.log("successfully wrote fiveThumb to database");
                  promiseRes(url);
                })
                .catch(function(err) {
                  console.log("error writing five thumb to database");
                  rej(err);
                });
            }.bind(this)
          )
          .catch(
            function(err) {
              console.log(
                "error getting download url for 500 thumb: " + err.message
              );
              this.setState({
                error: true,
                loading: false,
                message: "Error Generating 500 pixel wide Thumb"
              });
              rej(err);
            }.bind(this)
          );
      }.bind(this)
    );
  };
  generateThumbnails = () => {
    console.log("inside generate thumbnails");
    this.setState({ loading: true });
    for (let i = 0; i < this.state.images.length; i++) {
      console.log("inside for loop in generateThumbnails");
      let strings = this.state.images[i].path.split("/");
      let lastPart = this.state.images[i].path.split("/").pop();

      let fiveLastPart = "thumb@500_" + lastPart;

      let fivePath =
        strings[0] +
        "/" +
        strings[1] +
        "/" +
        this.state.images[i].id +
        "/" +
        fiveLastPart;

      console.log("fivePath is " + fivePath);
      if (
        this.state.images[i].thumbFive == undefined ||
        this.state.images[i] == null
      ) {
        this.generateFiveThumb(fivePath, i)
          .then(
            function(res) {
              this.setState(
                { message: "thumbs generated successfully", loading: false },
                () => {
                  // this.props.getImages();
                  console.log(
                    "success generating thumbnails, check to see if it refreshed"
                  );
                }
              );
            }.bind(this)
          )
          .catch(function(err) {
            console.log("error generating fiveThumb");
          });
      } else {
        console.log("already has fiveThumb");
      }
    }

    console.log("thumbnails generated successfully");
    this.setState({ message: "Thumbnails Generated Successfully" }, () => {
      this.getClientData();
    });
  };
  getClientData = () => {
    console.log("getting client data, uid is: " + this.props.match.params.uid);
    this.props.firebase.db
      .collection("users")
      .where("uid", "==", this.props.match.params.uid)
      .get()
      .then(
        function(res) {
          res.forEach((user, i) => {
            console.log("found user");
            this.setState({ client: user.data(), loading: false }, () => {
              this.props.firebase.db
                .collection("users")
                .doc(this.state.client.uid)
                .collection("images")
                .get()
                .then(
                  function(imageSnapshot) {
                    let images = [];
                    imageSnapshot.forEach((image, i) => {
                      let imageObject = image.data();
                      imageObject.url = imageObject.thumbFive;
                      images.push(imageObject);
                    });
                    this.setState({ images: images }, () => {
                      this.getInvoices();
                    });
                  }.bind(this)
                )
                .catch(function(err) {
                  console.log("error getting site images: " + err.message);
                });
            });
          });
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting client data: " + err.message);
      });
  };
  sendInvoice = invoice => {
    this.setState({ loading: true, sendInvoice: false });
    let date = new Date();
    let time = date.getTime();
    let docTitle = time + "-" + invoice.amount + "-" + this.state.client.uid;
    this.props.firebase.db
      .collection("users")
      .doc(this.state.client.uid)
      .collection("invoices")
      .doc(docTitle)
      .set({
        amount: invoice.amount,
        description: invoice.description,
        id: docTitle,
        paid: false,
        time: time,
        recipient: {
          name: this.state.client.fullName,
          uid: this.state.client.uid
        }
      })
      .then(
        function(res) {
          this.props.firebase.db
            .collection("invoices")
            .doc(docTitle)
            .set({
              amount: invoice.amount,
              description: invoice.description,
              paid: false,
              id: docTitle,
              time: time,
              recipient: {
                name: this.state.client.fullName,
                uid: this.state.client.uid
              }
            })
            .then(
              function(res) {
                axios
                  .post(adminSendMail, {
                    to: this.state.client.email,
                    content:
                      "You Have A New Invoice From KatieKozma.com, log in to pay",
                    subject: "New Invoice From KatieKozma.com"
                  })
                  .then(
                    function(uid) {
                      this.setState({
                        loading: false,
                        sendInvoice: false,
                        message: "Invoice Successfully Sent"
                      });
                    }.bind(this)
                  )
                  .catch(
                    function(err) {
                      console.log("Error sending invoice,: " + err.message);
                      this.setState({
                        message:
                          "Error Sending Invoice, Delete The Broken One And Try Again.",
                        loading: false
                      });
                    }.bind(this)
                  );
              }.bind(this)
            )
            .catch(function(err) {
              console.log("error writing to invoices node: " + err.message);
            });
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error writing invoice to db: " + err.message);
      });
  };
  sendMail = message => {
    this.setState({ loading: true, sendEmail: false });
    axios
      .post(adminSendMail, {
        to: this.state.client.email,
        content: message.content,
        subject: message.subject
      })
      .then(
        function(uid) {
          this.setState({
            sendEmail: false,
            loading: false,
            message: "Message Successfully Sent"
          });
        }.bind(this)
      )
      .catch(
        function(err) {
          console.log("error adding client,: " + err.message);
          this.setState({ message: "Error Adding Client", loading: false });
        }.bind(this)
      );
  };
  componentDidMount() {
    console.log("inside client management");
    this.getClientData();
  }
  render() {
    return (
      <div>
        <Tabs />
        {this.state.loading ? (
          <i className="spinner fas fa-circle-notch fa-spin" />
        ) : (
          <div className="uploadFormContainer">
            <div className="uploadForm">
              <Link to="/dashboard/clients">{"<-"}Back To Clients</Link>
              <p className="display-4 dashboardHeading">
                {this.state.client.fullName}
              </p>
              {this.state.message !== undefined ? (
                <p className="message lead">{this.state.message}</p>
              ) : (
                ""
              )}
              <div className="buttonContainer">
                {" "}
                <button
                  className="controlButton btn btn-sm btn-secondary"
                  onClick={this.toggleSendEmail}
                >
                  Send Email
                </button>
                <button
                  className="controlButton btn btn-sm btn-secondary"
                  onClick={this.toggleSendInvoice}
                >
                  Send Invoice
                </button>
              </div>
              <hr />
              <ClientUploadUtility
                client={this.state.client}
                destination="client"
                refresh={this.getClientData}
              />
              <hr />
              <p className="lead">
                Uploaded Images For {this.state.client.fullName}
              </p>
              <p className="small">
                If your images aren't showing up, click generate thumbnails to
                make sure thumbs have been created
              </p>
              {this.state.message !== undefined ? (
                <p className="message lead">{this.state.message}</p>
              ) : (
                ""
              )}
              <button
                className="controlButton btn btn-sm btn-secondary"
                onClick={this.generateThumbnails}
              >
                Generate Thumbnails
              </button>
              <br />
              {this.state.selectedImage !== undefined ? (
                <div>
                  <button
                    className="controlButton btn btn-sm btn-secondary"
                    onClick={this.confirmDelete}
                  >
                    Delete Image
                  </button>
                </div>
              ) : (
                ""
              )}
              <SiteImageContainer
                siteImages={this.state.images}
                select={this.selectImage}
                disabled={
                  this.state.editActive || this.state.loading ? true : false
                }
              />
            </div>
          </div>
        )}
        {this.state.confirmDelete ? (
          <Confirmation
            question="Are You Sure You Want To Delete This Image?"
            answer={this.answerConfirmDelete}
          />
        ) : (
          ""
        )}
        {this.state.sendEmail ? (
          <div>
            {" "}
            <SendEmail sendMail={this.sendMail} client={this.state.client} />
            <Backdrop click={this.toggleSendEmail} />
          </div>
        ) : (
          ""
        )}
        {this.state.sendInvoice ? (
          <div>
            {" "}
            <SendInvoice
              sendInvoice={this.sendInvoice}
              client={this.state.client}
            />
            <Backdrop click={this.toggleSendInvoice} />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default compose(withFirebase)(ClientManagement);
