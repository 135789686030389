import React, { Component } from "react";

export default class EditClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: false,
      email: "",
      password: "",
      firstName: "",
      lastName: ""
    };
  }
  submitClient = e => {
    e.preventDefault();
    console.log("inside submitClient");
    let client = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      fullName: this.state.firstName + " " + this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      uid: this.props.client.uid,
      date: this.props.client.date
    };
    this.props.submit(client);
  };
  onChange = event => {
    console.log("inside onchange, eventvalue is : " + event.target.value);

    this.setState({ [event.target.name]: event.target.value }, () => {
      this.checkValid();
    });
  };
  checkValid = () => {
    console.log("inside checkValid");
    this.setState(
      {
        emailError: !this.validateEmail(this.state.email),
        passwordError: !this.validatePassword(this.state.password)
      },
      () => {
        if (!this.state.emailError && !this.state.passwordError) {
          this.setState({ valid: true });
        } else {
          this.setState({ valid: false });
        }
      }
    );
  };
  validatePassword = password => {
    if (password.length >= 8) {
      return true;
    } else {
      return false;
    }
  };
  validateEmail = email => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  setFields = () => {
    this.setState({
      email: this.props.client.email,
      password: this.props.client.password,
      firstName: this.props.client.firstName,
      lastName: this.props.client.lastName,
      fullName: this.props.client.fullName
    });
  };
  componentDidMount = () => {
    this.setFields();
  };
  render() {
    let submit;
    if (this.state.valid) {
      submit = (
        <input
          type="submit"
          className="controlButton btn btn-secondary addJobButton"
          style={{ marginTop: "1em" }}
        />
      );
    } else {
      submit = (
        <input
          type="submit"
          className="controlButton btn btn-secondary addJobButton"
          disabled
          style={{ marginTop: "1em" }}
        />
      );
    }
    return (
      <div className="card addClient">
        <p className="lead">Add Client</p>
        <form onSubmit={this.submitClient}>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label" htmlFor="firstName">
              First Name
            </label>
            <input
              className="form-control col-sm-9"
              type="text"
              id="firstName"
              onChange={this.onChange}
              placeholder="First Name"
              value={this.state.firstName}
              name="firstName"
            />
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label" htmlFor="lastName">
              Last Name
            </label>
            <input
              className="form-control col-sm-9"
              type="text"
              id="lastName"
              onChange={this.onChange}
              value={this.state.lastName}
              placeholder="Last Name"
              name="lastName"
            />
          </div>

          <div>
            <div className="form-group row">
              <label className="col-sm-3 col-form-label" htmlFor="email">
                Email
              </label>
              <input
                className={
                  this.state.emailError
                    ? "form-control col-sm-9 is-invalid error"
                    : "form-control col-sm-9"
                }
                type="email"
                id="email"
                onChange={this.onChange}
                value={this.state.email}
                placeholder="Email"
                name="email"
              />
            </div>

            <div className="form-group row">
              <label className="col-sm-3 col-form-label" htmlFor="password">
                Password
              </label>
              <input
                className={
                  this.state.passwordError
                    ? "form-control col-sm-9 is-invalid error"
                    : "form-control col-sm-9"
                }
                type="text"
                id="password"
                onChange={this.onChange}
                value={this.state.password}
                placeholder="Password"
                name="password"
              />
            </div>
          </div>
          <div className="form-group row submitContainer">{submit}</div>
        </form>
      </div>
    );
  }
}
