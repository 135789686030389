import React, { Component } from "react";

import { withFirebase } from "./../firebase/index";
import { compose } from "../../../../../../AppData/Local/Microsoft/TypeScript/3.6/node_modules/redux";
import pic3 from "./../assets/image3.jpg";
import LoginForm from "./LoginForm";
import NavBar from "./nav/NavBar";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      message: undefined
    };
  }
  signedIn = () => {
    console.log("signedIn, from login");
    this.componentDidMount();
  };
  componentDidMount() {
    console.log(
      "inside login componenet, auth is : " +
        JSON.stringify(this.props.firebase.auth)
    );
    // if (this.props.firebase.auth.uid) {
    //   console.log("have uid");
    // } else if (this.props.firebase.auth.currentUser) {
    //   console.log("have uid");
    // }
    this.props.firebase.auth.onAuthStateChanged(
      function(user) {
        if (user) {
          console.log("user signed in, should forward");
          if (
            this.props.firebase.auth.currentUser.email ==
            "kreplogle88@icloud.com"
          ) {
            console.log("admin");
            this.props.history.push("/dashboard");
          } else {
            this.props.history.push("/client-dashboard");
          }
        } else {
          console.log("user signed out");
        }
      }.bind(this)
    );
  }
  render() {
    return (
      <div className="appContainer d-flex flex-column">
        {" "}
        <NavBar active="login" />
        <div className="landingContainer">
          <div
            className="landingImage"
            style={{
              backgroundImage: "url(" + pic3 + ")",
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center"
            }}
          >
            <LoginForm signedIn={this.signedIn} fire={this.props.firebase} />
          </div>
        </div>
      </div>
    );
  }
}

export default compose(withFirebase)(Login);
