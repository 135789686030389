import React, { Component } from "react";
import SiteImageContainer from "../portfolioManagement/SiteImageContainer";
import OverlayModule from "./OverlayModule";
import { withFirebase } from "../../../../firebase/index";
import { compose } from "redux";
import "./pages.css";

class ContactManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      images: [],
      pageHeading: "Contact",
      headingColor: "white",
      headingShadow: false,
      setAboutImage: false,
      paragraphs: [""],
      emailValid: false,
      navStyle: "sticky"
    };
  }
  componentDidMount() {
    console.log("about management mounted");
    this.setImages();
  }
  saveContactSettings = () => {
    this.setState({ loading: true });

    this.props.firebase.db
      .collection("pages")
      .doc("contact")
      .set(
        {
          overlay: this.state.overlay,
          defaultImage: this.state.activeImage,
          pageHeading: this.state.pageHeading,
          headingColor: this.state.headingColor,
          headingShadow: this.state.headingShadow,
          navStyle: this.state.navStyle
        },
        { merge: true }
      )
      .then(
        function(res) {
          console.log("successfully wrote contact settings");
          this.setState({
            message: "Successfully Wrote Contact Page Settings",
            loading: false
          });
        }.bind(this)
      )
      .catch(
        function(err) {
          console.log("error setting Contact stuff");
          this.setState({
            message:
              "Error Writing Contact Settings. Check Connection And Try Again",
            loading: false
          });
        }.bind(this)
      );
  };
  setImages = nextProps => {
    console.log("inside setImages in PricingManagement");
    let images = [];
    if (nextProps == undefined) {
      images = [...this.props.siteImages];
      console.log("inside setImages: " + JSON.stringify(images[0]));
    } else {
      images = [...nextProps.siteImages];
    }
    images.forEach(function(img) {
      console.log("going through images selected false");
      img.selected = false;
    });
    if (this.state.activeIndex !== undefined && images !== undefined) {
      if (images[this.state.activeIndex] !== undefined) {
        images[this.state.activeIndex].selected = true;
      }
    }

    this.setState(
      {
        images: images,
        activeImage: images[this.state.activeIndex]
      },
      () => {
        this.getContacttStuff();
      }
    );
  };
  componentWillReceiveProps(nextProps) {
    this.setImages(nextProps);
  }
  getContacttStuff = () => {
    console.log("getting Contactt stuff");

    this.props.firebase.db
      .collection("pages")
      .doc("contact")
      .get()
      .then(
        function(res) {
          if (res.exists) {
            let contact = res.data();
            let navStyle;
            if (contact.navStyle == undefined) {
              navStyle = "sticky";
            } else {
              navStyle = contact.navStyle;
            }
            this.setState({
              activeImage: contact.defaultImage,
              overlay: contact.overlay,
              loading: false,
              headingColor: contact.headingColor,
              pageHeading: contact.pageHeading,
              headingShadow: contact.headingShadow,
              paragraph: contact.paragraph,
              name: contact.name,
              phone: contact.phone,
              email: contact.email,
              formRecipient: contact.formRecipient,
              navStyle: navStyle
            });
          } else {
            console.log("no contact doc, setting state");
            this.setState({
              contactContent: {},
              contactImage: undefined,
              activeImage: undefined,
              overlay: false,
              loading: false,
              headingColor: "white",
              pageHeading: "Contact",
              headingShadow: false,
              navStyle: "sticky"
            });
          }
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting contact stuff");
      });
  };
  selectImage = image => {
    console.log("inside select image: " + JSON.stringify(image));
    let images = this.state.images;
    let activeImage = undefined;
    let activeIndex = undefined;
    images.forEach(
      function(siteImage, i) {
        if (image == siteImage) {
          console.log("found image in siteImages");
          if (siteImage.selected == true) {
            siteImage.selected = false;
          } else {
            activeImage = siteImage;
            activeIndex = i;
            siteImage.selected = true;
          }
        } else {
          siteImage.selected = false;
        }
      }.bind(this)
    );
    this.setState({
      images: images,
      activeImage: activeImage,
      activeIndex: activeIndex
    });
  };
  answerNavStyle = style => {
    switch (style) {
      case "sticky":
        this.setState({ navStyle: "sticky" });
        break;
      case "animated":
        this.setState({ navStyle: "animated" });
        break;
      case "relative":
        this.setState({ navStyle: "relative" });
        break;
      default:
        break;
    }
  };
  setAboutImage = () => {
    this.setState({ setAboutImage: true });
  };
  closeSetAboutImage = () => {
    this.setState({ setAboutImage: false });
  };

  overlayAnswer = answer => {
    console.log("inside overlay answer");
    this.setState({ overlay: answer });
  };
  setPageHeading = heading => {
    console.log("inside set page heading, setting heading to: " + heading);
    this.setState({ pageHeading: heading });
  };
  setPageHeadingColor = color => {
    console.log("inside setpageheadingcolor setting color to " + color);
    this.setState({ headingColor: color });
  };
  setPageHeadingShadow = answer => {
    console.log("inside setpageheadingshadow: " + answer);
    this.setState({ headingShadow: answer });
  };
  changeContactInfo = event => {
    console.log("indisde changeContactInfo");
    switch (event.target.name) {
      case "formRecipient":
        this.setState({ formRecipient: event.target.value }, () => {
          if (this.validateEmail(this.state.formRecipient)) {
            this.setState({ emailValid: true });
          }
        });
        break;
      case "name":
        this.setState({ name: event.target.value });
        break;
      case "phone":
        this.setState({ phone: event.target.value });
        break;
      case "email":
        this.setState({ email: event.target.value });
        break;
      case "paragraph":
        this.setState({ paragraph: event.target.value });
        break;
      default:
        break;
    }
  };
  validateEmail = email => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  saveContactInfo = () => {
    console.log("inside saveContactInfo");
    if (this.validateEmail(this.state.formRecipient)) {
      this.props.firebase.db
        .collection("pages")
        .doc("contact")
        .set(
          {
            name: this.state.name,
            phone: this.state.phone,
            email: this.state.email,
            paragraph: this.state.paragraph,
            formRecipient: this.state.formRecipient
          },
          { merge: true }
        )
        .then(
          function(res) {
            console.log("successfully wrote contact info");
            this.setState({
              message: "Successfully Wrote Contact Info"
            });
          }.bind(this)
        )
        .catch(
          function(err) {
            console.log("error saving contact info");
            this.setState({
              message:
                "Error Writing Contact Info, Check Connection And Try Again."
            });
          }.bind(this)
        );
    } else {
      this.props.firebase.db
        .collection("pages")
        .doc("contact")
        .set(
          {
            name: this.state.name,
            phone: this.state.phone,
            email: this.state.email,
            paragraph: this.state.paragraph
          },
          { merge: true }
        )
        .then(
          function(res) {
            console.log("successfully wrote contact info");
            this.setState({
              message:
                "Successfully Wrote Contact Info, Except For Form Recipient Email. Make Sure It Is A Valid Email And Try Again"
            });
          }.bind(this)
        )
        .catch(
          function(err) {
            console.log("error saving contact info");
            this.setState({
              message:
                "Error Writing Contact Info, Check Connection And Try Again."
            });
          }.bind(this)
        );
    }
  };
  render() {
    return (
      <div>
        {this.state.loading ? (
          <i className="spinner fas fa-circle-notch fa-spin" />
        ) : (
          <div>
            <p className="lead">Contact Page</p>
            {this.state.message !== "" ? (
              <p className="alert">{this.state.message}</p>
            ) : (
              ""
            )}
            <div className="row row-space">
              <div className="col col-xs-12 col-md-6">
                <p className="lead">Form Settings</p>
                <div className="form-group">
                  <label htmlFor="contactEmail">Contact Email</label>
                  <input
                    type="text"
                    className="form-control"
                    id="formRecipient"
                    name="formRecipient"
                    onChange={this.changeContactInfo}
                    value={this.state.formRecipient}
                  />
                </div>
              </div>
              <div className="col col-xs-12 col-md-6">
                <p className="lead">Contact Info</p>
                <div className="form-group">
                  <label htmlFor="contactName">Name</label>
                  <input
                    id="name"
                    name="name"
                    value={this.state.name}
                    onChange={this.changeContactInfo}
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="contactPhone">Phone</label>
                  <input
                    id="phone"
                    name="phone"
                    value={this.state.phone}
                    onChange={this.changeContactInfo}
                    type="tel"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="contactPhone">Email</label>
                  <input
                    id="email"
                    name="email"
                    value={this.state.email}
                    onChange={this.changeContactInfo}
                    type="email"
                    className="form-control"
                  />
                </div>
                <hr />
                <div className="form-group">
                  <label htmlFor="contactPhone">Contact Paragraph</label>
                  <textarea
                    id="paragraph"
                    name="paragraph"
                    value={this.state.paragraph}
                    onChange={this.changeContactInfo}
                    type="email"
                    className="form-control"
                  />
                </div>
                <button
                  className="btn btn-sm btn-secondary"
                  onClick={this.saveContactInfo}
                >
                  Save Contact Info
                </button>
              </div>
            </div>

            <div className="row row-space managementRow">
              {this.state.activeImage !== undefined ? (
                <div className="activeBackgroundImage">
                  <img src={this.state.activeImage.thumbFive} />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="row row-space managementRow">
              {" "}
              Selecting Photo For Contact Page Background
              <SiteImageContainer
                siteImages={this.state.images}
                select={this.selectImage}
              />
            </div>

            <div className="row row-space managementRow">
              <OverlayModule
                answer={this.overlayAnswer}
                overlay={this.state.overlay}
                setPageHeading={this.setPageHeading}
                answerHeadingColor={this.setPageHeadingColor}
                headingColor={this.state.headingColor}
                pageHeading={this.state.pageHeading}
                answerHeadingShadow={this.setPageHeadingShadow}
                headingShadow={this.state.headingShadow}
                navStyle={this.state.navStyle}
                answerNavStyle={this.answerNavStyle}
              />{" "}
              <div className="col col-md-4">
                <button
                  className="btn btn-sm btn-secondary"
                  onClick={this.saveContactSettings}
                >
                  Save Contact Page Settings
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default compose(withFirebase)(ContactManagement);
