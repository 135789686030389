import React, { Component } from "react";
import SiteImageContainer from "../portfolioManagement/SiteImageContainer";
import AddPackage from "./AddPackage";
import EditPackage from "./EditPackage";
import PackageTable from "./PackagesTable";
import Backdrop from "./../../../common/Backdrop";
import { withFirebase } from "../../../../firebase/index";
import { compose } from "redux";
import "./pages.css";
import OverlayModule from "./OverlayModule";

class PricingManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      addPackage: false,
      images: [],
      editPackage: false,
      pageHeading: "Pricing",
      headingColor: "white",
      headingShadow: false,

      navStyle: "sticky"
    };
  }
  openEditPackage = () => {
    this.setState({ editPackage: true });
  };
  closeEditPackage = () => {
    this.setState({ editPackage: false });
  };
  componentDidMount() {
    console.log("pricing management mounted");

    this.setImages();
  }
  answerNavStyle = style => {
    switch (style) {
      case "sticky":
        this.setState({ navStyle: "sticky" });
        break;
      case "animated":
        this.setState({ navStyle: "animated" });
        break;
      case "relative":
        this.setState({ navStyle: "relative" });
        break;
      default:
        break;
    }
  };
  setImages = nextProps => {
    console.log("inside setImages in PricingManagement");
    let images = [];
    if (nextProps == undefined) {
      images = [...this.props.siteImages];
      console.log("inside setImages: " + JSON.stringify(images[0]));
    } else {
      images = [...nextProps.siteImages];
    }
    images.forEach(function(img) {
      console.log("going through images selected false");
      img.selected = false;
    });
    if (this.state.activeIndex !== undefined && images !== undefined) {
      if (images[this.state.activeIndex] !== undefined) {
        images[this.state.activeIndex].selected = true;
      }
    }

    this.setState(
      {
        images: images,
        activeImage: images[this.state.activeIndex]
      },
      () => {
        this.getPricingStuff();
      }
    );
  };
  componentWillReceiveProps(nextProps) {
    this.setImages(nextProps);
  }

  getPricingStuff = () => {
    console.log("getting pricing stuff");
    this.props.firebase.db
      .collection("pages")
      .doc("pricing")
      .get()
      .then(
        function(res) {
          if (res.exists) {
            let pricing = res.data();
            let navStyle;
            if (pricing.navStyle == undefined) {
              navStyle = "sticky";
            } else {
              navStyle = pricing.navStyle;
            }
            this.setState({
              packages: pricing.packages,
              activeImage: pricing.defaultImage,
              overlay: pricing.overlay,
              loading: false,
              headingColor: pricing.headingColor,
              pageHeading: pricing.pageHeading,
              headingShadow: pricing.headingShadow,
              defaultImage: pricing.defaultImage,
              navStyle: navStyle
            });
          } else {
            this.setState({
              packages: [],
              activeImage: undefined,
              overlay: undefined,
              loading: false,
              headingColor: "white",
              pageHeading: "",
              headingShadow: undefined,
              defaultImage: "",
              navStyle: "relative"
            });
          }
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting pricing stuff: " + err.message);
      });
  };

  addPackage = () => {
    this.setState({ addPackage: true });
  };
  deletePackage = () => {
    let newPackages = [];
    this.state.packages.forEach((packageObj, i) => {
      if (packageObj.name !== this.state.activePackage.name) {
        newPackages.push(packageObj);
      }
    });
    this.props.firebase.db
      .collection("pages")
      .doc("pricing")
      .set({ packages: newPackages }, { merge: true })
      .then(
        function(res) {
          this.setState(
            {
              message: "Successfully Deleted Package",
              activePackage: undefined
            },
            () => {
              this.getPricingStuff();
            }
          );
        }.bind(this)
      )
      .catch(
        function(err) {
          console.log("error deleting package: " + err.message);
          this.setState({
            message: "Error Deleting Package. Check Connection And Try Again."
          });
        }.bind(this)
      );
  };
  closeAddPackage = () => {
    this.setState({ addPackage: false });
  };
  setPageHeading = heading => {
    console.log("inside set page heading, setting heading to: " + heading);
    this.setState({ pageHeading: heading });
  };
  setPageHeadingColor = color => {
    console.log("inside setpageheadingcolor setting color to " + color);
    this.setState({ headingColor: color });
  };
  setPageHeadingShadow = answer => {
    console.log("inside setpageheadingshadow: " + answer);
    this.setState({ headingShadow: answer });
  };
  savePricingSettings = () => {
    this.setState({ loading: true });

    this.props.firebase.db
      .collection("pages")
      .doc("pricing")
      .set(
        {
          overlay: this.state.overlay,
          defaultImage: this.state.activeImage,
          pageHeading: this.state.pageHeading,
          headingColor: this.state.headingColor,
          headingShadow: this.state.headingShadow,
          navStyle: this.state.navStyle
        },
        { merge: true }
      )
      .then(
        function(res) {
          console.log("successfully wrote pricing settings");
          this.setState({
            message: "Successfully Wrote Pricing Page Settings",
            loading: false
          });
        }.bind(this)
      )
      .catch(
        function(err) {
          console.log("error setting pricing stuff");
          this.setState({
            message:
              "Error Writing Pricing Settings. Check Connection And Try Again",
            loading: false
          });
        }.bind(this)
      );
  };
  submitPackage = packageObject => {
    this.setState({ loading: true, addPackage: false });
    console.log("inside submitPackage");
    let packages = this.state.packages;
    if (packageObject.description == undefined) {
      packageObject.description = "";
    }
    if (packageObject.price == undefined) {
      packageObject.price = "";
    }
    packages.push(packageObject);
    let pricing = { packages: packages };
    this.props.firebase.db
      .collection("pages")
      .doc("pricing")
      .set(pricing, { merge: true })
      .then(
        function(res) {
          console.log("successfully saved package");
          this.setState(
            {
              message: "Successfully Wrote Pricing Package",
              loading: false
            },
            () => {
              this.getPricingStuff();
            }
          );
        }.bind(this)
      )
      .catch(
        function(err) {
          console.log("error writing pricing package");
          this.setState({
            message:
              "Error Writing Pricing Package, Check Connection And Try Again.",
            loading: false
          });
        }.bind(this)
      );
  };
  submitEditPackage = packageObject => {
    this.setState({ loading: true, editPackage: false });
    console.log("inside submitPackage");
    let packages = this.state.packages;
    if (packageObject.description == undefined) {
      packageObject.description = "";
    }
    if (packageObject.price == undefined) {
      packageObject.price = "";
    }

    let newPackages = [];
    packages.forEach((pa, i) => {
      if (pa.name == packageObject.name) {
        console.log("found package to edit");
        pa.name = packageObject.name;
        pa.description = packageObject.description;
        pa.price = packageObject.price;
        pa.button = packageObject.button;
        newPackages.push(pa);
      } else {
        newPackages.push(pa);
      }
    });

    let pricing = { packages: newPackages };
    this.props.firebase.db
      .collection("pages")
      .doc("pricing")
      .set(pricing, { merge: true })
      .then(
        function(res) {
          console.log("successfully saved package");
          this.setState(
            {
              message: "Successfully Edited Pricing Package",
              loading: false,
              activePackage: undefined
            },
            () => {
              this.getPricingStuff();
            }
          );
        }.bind(this)
      )
      .catch(
        function(err) {
          console.log("error writing pricing package");
          this.setState({
            message:
              "Error Writing Pricing Package, Check Connection And Try Again.",
            loading: false,
            activePackage: undefined
          });
        }.bind(this)
      );
  };
  selectPackage = event => {
    console.log("selecting package: " + JSON.stringify(event));
    if (event == null || event == undefined) {
      this.setState({ activePackage: undefined });
    } else {
      if (this.state.activePackage !== undefined) {
        if (this.state.activePackage.name !== event.name) {
          console.log("setting activePackage");
          this.setState({ activePackage: event });
        } else {
          this.setState({ activePackage: undefined });
        }
      } else {
        this.setState({ activePackage: event });
      }
    }
  };
  changeButton = (pack, answer) => {
    console.log("inside changeButton: package is " + JSON.stringify(pack));
    this.setState({ loading: true });
    let packages = this.state.packages;
    let newPackages = [];
    packages.forEach(function(p, i) {
      if (p.name == pack.name) {
        p.button = answer;
        newPackages.push(p);
      } else {
        newPackages.push(p);
      }
    });
    this.props.firebase.db
      .collection("pages")
      .doc("pricing")
      .set({ packages: newPackages }, { merge: true })
      .then(
        function(res) {
          console.log("success");
          this.setState({
            message: "Successfully changed Package Button Status",
            loading: false
          });
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error writing button stuff: " + err.message);
        this.setState({
          message:
            "Error Writing Button Status, Check Connection And Try Again",
          loading: false
        });
      });
  };
  selectImage = image => {
    console.log("inside select image: " + JSON.stringify(image));
    let images = this.state.images;
    let activeImage = undefined;
    let activeIndex = undefined;
    images.forEach(
      function(siteImage, i) {
        if (image == siteImage) {
          console.log("found image in siteImages");
          if (siteImage.selected == true) {
            siteImage.selected = false;
          } else {
            activeImage = siteImage;
            activeIndex = i;
            siteImage.selected = true;
          }
        } else {
          siteImage.selected = false;
        }
      }.bind(this)
    );
    this.setState({
      images: images,
      activeImage: activeImage,
      activeIndex: activeIndex
    });
  };
  overlayAnswer = answer => {
    console.log("inside overlay answer");
    this.setState({ overlay: answer });
  };
  render() {
    return (
      <div>
        {this.state.loading ? (
          <i className="spinner fas fa-circle-notch fa-spin" />
        ) : (
          <div>
            {this.state.editPackage ? (
              <div>
                <EditPackage
                  packageData={this.state.activePackage}
                  packages={this.state.packages}
                  submit={this.submitEditPackage}
                />
                <Backdrop click={this.closeEditPackage} />
              </div>
            ) : (
              ""
            )}
            {this.state.addPackage ? (
              <div>
                <AddPackage
                  packages={this.state.packages}
                  submit={this.submitPackage}
                />
                <Backdrop click={this.closeAddPackage} />
              </div>
            ) : (
              ""
            )}
            <p className="lead">Pricing Page</p>
            {this.state.message !== "" ? (
              <p className="alert">{this.state.message}</p>
            ) : (
              ""
            )}
            <button
              className="btn btn-sm btn-secondary dashboardButton"
              onClick={this.addPackage}
            >
              Add Package
            </button>
            {this.state.activePackage !== undefined ? (
              <div>
                <button
                  className="btn btn-sm btn-secondary dashboardButton"
                  onClick={this.openEditPackage}
                >
                  Edit Package
                </button>
                <button
                  className="btn btn-sm btn-secondary dashboardButton"
                  onClick={this.deletePackage}
                >
                  Delete Package
                </button>
              </div>
            ) : (
              ""
            )}
            <PackageTable
              selectPackage={this.selectPackage}
              openPackage={this.openPackage}
              packages={this.state.packages}
              changeButton={this.changeButton}
            />{" "}
            <div className="row row-space">
              <div className="col col-md-6">
                {this.state.activeImage !== undefined ? (
                  <div className="activeBackgroundImage">
                    <img src={this.state.activeImage.thumbFive} />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col col-md-6">
                {" "}
                Selecting Photo For Pricing Page Background
                <SiteImageContainer
                  siteImages={this.state.images}
                  select={this.selectImage}
                />
              </div>
            </div>
            <div className="row row-space">
              <OverlayModule
                answer={this.overlayAnswer}
                overlay={this.state.overlay}
                setPageHeading={this.setPageHeading}
                answerHeadingColor={this.setPageHeadingColor}
                headingColor={this.state.headingColor}
                pageHeading={this.state.pageHeading}
                answerHeadingShadow={this.setPageHeadingShadow}
                headingShadow={this.state.headingShadow}
                answerNavStyle={this.answerNavStyle}
                navStyle={this.state.navStyle}
              />{" "}
              <div className="col col-md-4">
                <button
                  className="btn btn-sm btn-secondary"
                  onClick={this.savePricingSettings}
                >
                  Save Pricing Page Settings
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default compose(withFirebase)(PricingManagement);
