import React, { Component } from "react";
import DashboardStaticNav from "./../nav/DashboardStaticNav";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import Tabs from "./Tabs";
import "./dashboard.css";
import ClientManagement from "./clients/ClientManagement";
import Clients from "./clients/Clients";
import SiteManagement from "./site-management/SiteManagement";
import Upload from "./upload/Upload";
import Invoices from "./Invoices/Invoices";

export default class Dashboard extends Component {
  render() {
    return (
      <div className="dashboardContainer">
        {" "}
        <DashboardStaticNav
          setHeight={() => {}}
          admin={true}
          signedIn={true}
          active="dashboard"
        />
        <Route exact path="/dashboard" component={Clients} />
        <Route exact path="/dashboard/clients" component={Clients} />
        <Route path="/dashboard/clients/:uid" component={ClientManagement} />
        <Route path="/dashboard/site-management" component={SiteManagement} />
        <Route exact path="/dashboard/upload" component={Upload} />
        <Route exact path="/dashboard/invoices" component={Invoices} />
      </div>
    );
  }
}
