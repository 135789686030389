import landing1 from "./../assets/image1.jpg";
import landing2 from "./../assets/image2.jpg";
import landing3 from "./../assets/image3.jpg";
import wedding1 from "./../assets/image4.jpg";

import wedding2 from "./../assets/image5.jpg";
import nature from "./../assets/image6.jpg";
import family from "./../assets/image7.jpg";

export const mockDB = {
  landing1,
  landing2,
  landing3,
  wedding1,
  wedding2,
  nature,
  family
};
