import React, { Component } from "react";
import Tabs from "./Tabs";
import { withFirebase } from "./../../firebase/index";
import { compose } from "redux";
import InvoicesTable from "./../dashboard/Invoices/InvoicesTable";
import Hire from "./Hire";
import Backdrop from "./../common/Backdrop";

class Payment extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      invoices: [],
      selectedInvoice: undefined,
      checkout: false
    };
  }
  getInvoices = () => {
    this.props.firebase.db
      .collection("users")
      .doc(this.props.firebase.auth.currentUser.uid)
      .collection("invoices")
      .get()
      .then(
        function(res) {
          let invoices = [];
          res.forEach((invoice, i) => {
            console.log("got invoice");
            invoices.push(invoice.data());
          });
          this.setState({ loading: false, invoices: invoices });
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting invoices: " + err.message);
      });
  };
  selectInvoice = invoice => {
    console.log("from selectinvoice, invoice is : " + JSON.stringify(invoice));
    if (invoice === this.state.selectedInvoice || invoice === null) {
      console.log("unselecting");
      this.setState({ selectedInvoice: undefined });
    } else {
      this.setState({ selectedInvoice: invoice });
    }
  };
  toggleCheckout = () => {
    this.setState(prevState => {
      return { checkout: !prevState.checkout };
    });
  };
  end = status => {
    if (status == "success") {
      this.props.firebase.db
        .collection("users")
        .doc(this.props.firebase.auth.currentUser.uid)
        .collection("invoices")
        .doc(this.state.selectedInvoice.id)
        .set({ paid: true }, { merge: true })
        .then(
          function(res) {
            this.setState({
              checkout: false,
              message: "Invoice Successfully Paid. Thank you, very much."
            });
          }.bind(this)
        )
        .catch(
          function(err) {
            console.log("error writing paid to users invoice: " + err.message);
            this.setState({
              checkout: false,
              message:
                "You have successfully paid the invoice, but there was an error writing that to the database, please contact an admin about this error"
            });
          }.bind(this)
        );
    } else {
      this.setState({
        checkout: false,
        message: "Error Paying Invoice, Check Connection And Try Again."
      });
    }
  };
  payInvoice = () => {
    this.setState({ checkout: true });
  };
  componentDidMount() {
    this.getInvoices();
  }
  render() {
    return (
      <div className="uploadFormContainer">
        {" "}
        {this.state.loading ? (
          <i className="spinner fas fa-circle-notch fa-spin" />
        ) : (
          <div className="container">
            <Tabs />
            {this.state.message !== undefined ? (
              <p className="message">{this.state.message}</p>
            ) : (
              ""
            )}
            <div className="headlineBox">
              <h4 className="display-5 text-center headline">Payment</h4>
              <p className="display-5 text-center headline">
                {" "}
                This is the payment section. Here you can pay invoices, reserve
                Katie for future work or purchase additional flash drives of
                your content.
              </p>
            </div>
            <p className="lead">Invoices</p>
            {this.state.selectedInvoice !== undefined &&
            this.state.selectedInvoice.paid == false ? (
              <button
                className="btn btn-secondary btn-sm"
                onClick={this.payInvoice}
              >
                Pay Invoice
              </button>
            ) : (
              ""
            )}
            <InvoicesTable
              invoices={this.state.invoices}
              selectInvoice={this.selectInvoice}
            />
          </div>
        )}
        {this.state.checkout ? (
          <div>
            <Hire
              client={this.props.firebase.auth.currentUser.uid}
              invoice={this.state.selectedInvoice}
              total={this.state.selectedInvoice.amount}
              end={this.end}
            />
            <Backdrop click={this.toggleCheckout} />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default compose(withFirebase)(Payment);
