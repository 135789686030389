import React, { Component } from "react";
import "./login.css";
import logo from "./../assets/logo.png";

export default class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      valid: false,
      message: undefined
    };
  }
  componentDidMount() {}
  logIn = e => {
    e.preventDefault();
    console.log("inside logIn");
    this.props.fire.auth
      .signInWithEmailAndPassword(this.state.username, this.state.password)
      .then(
        function(res) {
          console.log("login success");
          this.props.signedIn();
        }.bind(this)
      )
      .catch(err => {
        console.log("login error, err is: " + err.message);
        this.setState({ message: err.message });
      });
  };
  onChange = event => {
    console.log("inside onChange");
    switch (event.target.name) {
      case "username":
        if (this.state.password !== "" && this.state.password !== undefined) {
          if (event.target.value !== "") {
            this.setState({ username: event.target.value, valid: true });
          } else {
            this.setState({ username: event.target.value, valid: false });
          }
        } else {
          this.setState({ username: event.target.value, valid: false });
        }

        break;
      case "password":
        if (this.state.username !== "" && this.state.username !== undefined) {
          if (event.target.value !== "") {
            this.setState({ password: event.target.value, valid: true });
          } else {
            this.setState({ password: event.target.value, valid: false });
          }
        } else {
          this.setState({ password: event.target.value, valid: false });
        }
        break;
      default:
        break;
    }
  };
  render() {
    return (
      <div className="container h-100 animated fadeIn">
        <div className="d-flex justify-content-center h-100">
          <div className="user_card">
            <div className="d-flex justify-content-center">
              <div className="brand_logo_container">
                <img src={logo} className="brand_logo" alt="Logo" />
              </div>
            </div>
            <div className="d-flex justify-content-center form_container">
              <form onSubmit={this.logIn}>
                <div className="input-group mb-3">
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <i className="fas fa-envelope"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    name="username"
                    className="form-control input_user"
                    value={this.state.username}
                    onChange={this.onChange}
                    placeholder="email"
                  />
                </div>
                <div className="input-group mb-2">
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <i className="fas fa-key"></i>
                    </span>
                  </div>
                  <input
                    type="password"
                    name="password"
                    className="form-control input_pass"
                    value={this.state.password}
                    onChange={this.onChange}
                    placeholder="password"
                  />
                </div>
                <div className="d-flex justify-content-center mt-3 login_container">
                  <button
                    type="submit"
                    name="button"
                    className="btn login_btn"
                    disabled={this.state.valid ? false : true}
                  >
                    Login
                  </button>
                </div>
                <br />
                {this.state.message !== undefined ? (
                  <p className="alert">{this.state.message}</p>
                ) : (
                  ""
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
