import React, { Component } from "react";
import LoadingScreen from "./common/LoadingScreen";
import NavBar from "./nav/NavBar";
import ContactForm from "./common/ContactForm";
import { withFirebase } from "./../firebase/index";
import { compose } from "redux";
import axios from "axios";
import StaticNav from "./nav/StaticNav";
import RelativeNav from "./nav/RelativeNav";
import { sendMail } from "./../versionInfo";
import Footer from "./Footer";
class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      navHeight: 150
    };
  }
  componentDidMount() {
    this.getContactStuff();
    this.setNavHeight();
  }

  getContactStuff = () => {
    this.props.firebase.db
      .collection("pages")
      .doc("contact")
      .get()
      .then(
        function(res) {
          if (res.exists) {
            let defaultImage = new Image();
            defaultImage.src = res.data().defaultImage.url;
            defaultImage.onload = () => {
              this.setState({
                defaultImage: res.data().defaultImage,
                overlay: res.data().overlay,
                loading: false,
                pageHeading: res.data().pageHeading,
                headingColor: res.data().headingColor,
                headingShadow: res.data().headingShadow,
                phone: res.data().phone,
                email: res.data().email,
                paragraph: res.data().paragraph,
                name: res.data().name,
                formRecipient: res.data().formRecipient,
                navStyle: res.data().navStyle
              });
            };
          } else {
            this.setState({
              defaultImage: "",
              overlay: undefined,
              loading: false,
              pageHeading: "Edit Contact Page In Dashboard",
              headingColor: "black",
              headingShadow: true,
              phone: 2223333,
              email: "this@that.com",
              paragraph: "Edit In Dashboard",
              name: "Name ",
              formRecipient: undefined,
              navStyle: "relative"
            });
          }
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting contact stuff: " + err.message);
      });
  };
  submitForm = async () => {
    console.log("inside submitForm");
    this.setState({ formSending: true });
    let message = {
      name: this.state.formName,
      email: this.state.formEmail,
      type: this.state.formType,
      phone: this.state.formPhone,
      message: this.state.formContent,
      to: this.state.formRecipient
    };
    if (this.state.formRecipient == undefined) {
      return;
    } else {
      console.log("message is : " + JSON.stringify(message));
      await axios
        .post(sendMail, message)
        .then(
          function(res) {
            console.log("res from submitForm is : " + JSON.stringify(res));
            this.setState({
              message:
                "Thank you for filling out the form. We will contact you as quickly as we possibly can.",
              formName: "",
              formPhone: "",
              formEmail: "",
              formType: "",
              formContent: "",
              formSending: false
            });
          }.bind(this)
        )
        .catch(function(err) {});
    }
  };
  changeForm = event => {
    console.log("inside changeForm");
    console.log("value is: " + event.target.value);
    switch (event.target.name) {
      case "name":
        this.setState({ formName: event.target.value });
        break;
      case "phone":
        this.setState({ formPhone: event.target.value });
        break;
      case "email":
        this.setState({ formEmail: event.target.value });
        break;
      case "content":
        this.setState({ formContent: event.target.value });
        break;
      case "type":
        this.setState({ formType: event.target.value });
        break;
      default:
        break;
    }
  };
  setNavHeight = height => {
    console.log("inside setNavHeight:" + height);
    this.setState({ navHeight: height });
  };
  render() {
    console.log("inside render of contact");
    let height = window.innerHeight;
    console.log("HEIGHT is : " + height);
    let overlayHeight;

    let nav;
    switch (this.state.navStyle) {
      case "sticky":
        overlayHeight = "100%";
        nav = (
          <NavBar
            active="contact"
            signedIn={this.props.signedIn}
            admin={this.props.admin}
          />
        );
        break;
      case "animated":
        overlayHeight = height - this.state.navHeight;
        console.log("setting overlayHeight to : " + overlayHeight);
        nav = (
          <RelativeNav
            active="contact"
            signedIn={this.props.signedIn}
            admin={this.props.admin}
            setHeight={this.setNavHeight}
          />
        );
        break;
      case "relative":
        overlayHeight = height - this.state.navHeight;
        nav = (
          <StaticNav
            active="contact"
            signedIn={this.props.signedIn}
            admin={this.props.admin}
            setHeight={this.setNavHeight}
          />
        );
        break;
      default:
        break;
    }
    let overlayColor;
    switch (this.state.overlay) {
      case "light":
        overlayColor = "rgba(197, 195, 195, 0.262)";
        break;
      case "medium":
        overlayColor = "rgba(197, 195, 195, 0.462)";
        break;
      case "dark":
        overlayColor = "rgba(66, 66, 66, 0.568)";
        break;
      case "none":
        overlayColor = "rgba(66, 66, 66, 0)";
        break;
      default:
        overlayColor = "rgba(66, 66, 66, 0)";
        break;
    }
    let headingColor;
    switch (this.state.headingColor) {
      case "white":
        headingColor = "#fff";
        break;
      case "grey":
        headingColor = "#878787";
        break;
      case "black":
        headingColor = "#000";
        break;
      default:
        headingColor = "#878787";
        break;
    }
    return (
      <div>
        {" "}
        {this.state.loading ? (
          <LoadingScreen />
        ) : (
          <div>
            <div
              className=" pricingContainer landingImage"
              style={
                this.state.defaultImage !== undefined
                  ? {
                      backgroundImage:
                        "url(" + this.state.defaultImage.url + ")"
                    }
                  : {}
              }
            >
              {nav}
              <div
                className="overlay"
                style={
                  this.state.overlay
                    ? {
                        backgroundColor: overlayColor,
                        height: overlayHeight || 150
                      }
                    : {}
                }
              >
                {" "}
                <p
                  style={
                    this.state.headingShadow == "yes"
                      ? {
                          color: headingColor,
                          textShadow: "2px 4px 3px rgba(0,0,0,0.4)"
                        }
                      : { color: headingColor }
                  }
                  className="lead pageHeading"
                >
                  {this.state.pageHeading}
                </p>
              </div>
              <div className="pricing">
                <div className="container">
                  {" "}
                  <div className="row row-space">
                    <p className="lead aboutParagraph">
                      {this.state.paragraph}
                    </p>
                    <br />
                    {this.state.message !== undefined &&
                    this.state.message !== "" ? (
                      <p className="lead green">{this.state.message}</p>
                    ) : (
                      ""
                    )}

                    <div className="col-xs-12 col-md-6 d-flex justify-content-center">
                      {this.state.formSending ? (
                        <LoadingScreen />
                      ) : (
                        <ContactForm
                          name={this.state.formName}
                          email={this.state.formEmail}
                          phone={this.state.formPhone}
                          type={this.state.formType}
                          content={this.state.formContent}
                          change={this.changeForm}
                          submit={this.submitForm}
                        />
                      )}
                    </div>
                    <div className="col-xs-12 col-md-6 d-flex justify-content-center">
                      <div>
                        <ul className="list-group contactList">
                          <li className="list-group-item">
                            <p className="lead">Contact Details</p>
                          </li>
                          <li className="list-group-item">
                            <p className="lead dark">
                              <i class="fas fa-user-circle"></i>
                              {" : "}
                              {this.state.name}
                            </p>
                          </li>
                          <li className="list-group-item">
                            <p className="lead dark">
                              <i className="fas fa-phone"></i>
                              {" : "}
                              {this.state.phone}
                            </p>
                          </li>
                          <li className="list-group-item">
                            <p className="lead dark">
                              <i class="fas fa-envelope"></i>
                              {" : "} {this.state.email}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        )}{" "}
      </div>
    );
  }
}

export default compose(withFirebase)(Contact);
