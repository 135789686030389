import React, { Component } from "react";
import formatDate from "./../../../common/formatDate";
class PackageTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPackage: null
    };
    this.selectPackage = this.selectPackage.bind(this);
  }
  selectPackage(event) {
    if (this.state.selectedPackage == event) {
      this.props.selectPackage(null);
      this.setState({ selectedPackage: null });
    } else {
      this.props.selectPackage(event);
      this.setState({ selectedPackage: event });
    }
  }

  render() {
    return (
      <div className="clientsTableContainer">
        <p className="display-5">Packages</p>
        <table className="table table-hover">
          <thead className="thead-dark">
            <tr>
              <th></th>
              <th
                className="sort"
                scope="col"
                onClick={() => {
                  this.props.sort("name");
                }}
              >
                Name
              </th>
              <th className="sort" scope="col">
                Description
              </th>
              <th scope="col">Price</th>
              <th scope="col">Include Contact Button?</th>
            </tr>
          </thead>
          <tbody>
            {this.props.packages.map((Package, i) => (
              <tr
                key={i}
                style={{
                  backgroundColor:
                    this.state.selectedPackage == Package ? "#ccc" : undefined
                }}
              >
                <td>
                  <input
                    type="checkbox"
                    checked={
                      this.state.selectedPackage == Package ? true : false
                    }
                    id="selectPackage"
                    onChange={() => {
                      this.selectPackage(Package);
                    }}
                  ></input>
                </td>
                <th scope="row">
                  <button
                    className="btn btn-link"
                    onClick={() => {
                      this.selectPackage(Package);
                      this.props.openPackage(Package);
                    }}
                  >
                    {Package.name}
                  </button>
                </th>
                <th>{Package.description}</th>
                <th scope="row">{Package.price}</th>
                <th scope="row">{Package.button ? "Yes" : "No"}</th>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default PackageTable;
