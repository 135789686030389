import React, { Component } from "react";
import Tabs from "../Tabs";
import SiteUpload from "./SiteUpload";
import ClientUpload from "./ClientUpload";

export default class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: "client"
    };
  }
  changeActive = () => {
    this.setState(prevState => {
      if (this.state.active == "client") {
        return { active: "site" };
      } else {
        return { active: "client" };
      }
    });
  };
  render() {
    return (
      <div>
        <Tabs />

        <div className="uploadFormContainer">
          {" "}
          <div className="uploadForm">
            <p className="display-4 dashboardHeading">
              Upload For Site Content
            </p>
            <br />

            <SiteUpload />
          </div>
        </div>
      </div>
    );
  }
}
