import React, { Component } from "react";
import Gallery from "react-grid-gallery";
import ImageGallery from "react-image-gallery";
import { withFirebase } from "./../../firebase/index";
import { compose } from "redux";

class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      loading: true,
      imageGalleryImages: []
    };
  }
  getPortStuff = () => {
    this.props.firebase.db
      .collection("portfolios")
      .get()
      .then(
        function(portSnapshot) {
          portSnapshot.forEach(
            function(port) {
              if (port.data().url == this.props.match.params.portName) {
                let images = [];
                if (port.data().images !== undefined) {
                  port.data().images.forEach(function(image) {
                    images.push(image);
                  });
                }
                let heading, overlay, headingColor, headingShadow, portStyle;
                if (port.data().pageHeading == undefined) {
                  heading = port.data().name;
                } else {
                  heading = port.data().pageHeading;
                }
                if (port.data().overlay == undefined) {
                  overlay = "none";
                } else {
                  overlay = port.data().overlay;
                }
                if (port.data().headingColor == undefined) {
                  headingColor = "white";
                } else {
                  headingColor = port.data().headingColor;
                }
                if (port.data().headingShadow == undefined) {
                  headingShadow = "yes";
                } else {
                  headingShadow = port.data().headingShadow;
                }
                if (port.data().portStyle == undefined) {
                  portStyle = "block";
                } else {
                  portStyle = port.data().portStyle;
                }
                this.setState({
                  port: port.data(),
                  pageHeading: heading,
                  overlay: overlay,
                  headingColor: headingColor,
                  headingShadow: headingShadow,
                  loading: false,
                  images: images,
                  defaultImage: port.data().defaultImage,
                  portStyle: portStyle
                });
              }
            }.bind(this)
          );
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting portfolios: " + err.message);
      });
  };
  componentWillReceiveProps(nextProps) {
    this.getPortStuff();
  }
  componentDidMount() {
    console.log(
      "inside portfolio, match params is " + this.props.match.params.portName
    );
    this.getPortStuff();
  }
  render() {
    let images = [];

    let gallery;
    if (this.state.portStyle == "block") {
      this.state.images.forEach(function(image) {
        let newImg = {
          src: image.url,
          thumbnail: image.thumbFive,
          thumbnailWidth: 500,
          thumbnailHeight: 500,
          caption: image.caption
        };
        images.push(newImg);
      });
      gallery = <Gallery backdropClosesModal={true} images={images} />;
    } else if (this.state.portStyle == "cinema") {
      this.state.images.forEach(function(image) {
        images.push({ original: image.url, thumbnail: image.thumbFive });
      });
      gallery = <ImageGallery items={images} />;
    }

    let headingColor;
    switch (this.state.headingColor) {
      case "white":
        headingColor = "#fff";
        break;
      case "grey":
        headingColor = "#878787";
        break;
      case "black":
        headingColor = "#000";
        break;
      default:
        headingColor = "#878787";
        break;
    }

    return (
      <div className=" portfolioContainer landingImage">
        <p
          style={
            this.state.headingShadow == "yes"
              ? {
                  color: headingColor,
                  textShadow: "2px 4px 3px rgba(0,0,0,0.4)"
                }
              : { color: headingColor, textShadow: "none" }
          }
          className="lead pageHeading"
        >
          {this.state.pageHeading}
        </p>
        <div className="galleryContainer">{gallery}</div>
      </div>
    );
  }
}

export default compose(withFirebase)(Portfolio);
