import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import DashboardStaticNav from "./../nav/DashboardStaticNav";
import Gallery from "./Gallery";
import Messages from "./Messages";
import Payment from "./Payment";
import Download from "./Download";
import "./clientDashboard.css";
export default class ClientDashboard extends Component {
  render() {
    return (
      <div className="dashboardContainer">
        {" "}
        <DashboardStaticNav
          setHeight={() => {}}
          admin={false}
          signedIn={true}
          active="dashboard"
        />
        <Route exact path="/client-dashboard" component={Gallery} />
        <Route exact path="/client-dashboard/gallery" component={Gallery} />
        <Route exact path="/client-dashboard/download" component={Download} />
        <Route path="/client-dashboard/messages" component={Messages} />
        <Route path="/client-dashboard/payment" component={Payment} />
      </div>
    );
  }
}
