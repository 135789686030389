import React, { Component } from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import CheckoutForm from "../common/CheckoutForm";
import { stripeApiKey } from "./../../versionInfo";

export default class Hire extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    console.log("hire component mounted");
    // switch (this.props.job){
    //     case 'family':
    //         break;
    //         case 'eng'
    // }
  }
  end = status => {
    console.log("end");
    if (status == 200) {
      this.props.end("success");
    } else {
      this.props.end("failure");
    }
  };
  render() {
    return (
      <div className="card hireCard">
        <StripeProvider apiKey={stripeApiKey}>
          <div className="example">
            <h1>Pay Invoice For ${this.props.total}</h1>
            <Elements>
              <CheckoutForm
                invoice={this.props.invoice}
                client={this.props.client}
                total={this.props.total}
                end={this.end}
              />
            </Elements>
          </div>
        </StripeProvider>
      </div>
    );
  }
}
