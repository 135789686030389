import React, { Component } from "react";
import SiteImageContainer from "./../portfolioManagement/SiteImageContainer";

export default class SetAboutImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: []
    };
  }
  componentDidMount() {
    this.setImages();
  }
  setImages = () => {
    this.setState({ images: this.props.images });
  };
  selectImage = image => {
    console.log("inside select image: " + JSON.stringify(image));
    let images = this.state.images;
    let activeImage = undefined;
    let activeIndex = undefined;
    images.forEach(
      function(siteImage, i) {
        if (image == siteImage) {
          console.log("found image in siteImages");
          if (siteImage.selected == true) {
            siteImage.selected = false;
          } else {
            activeImage = siteImage;
            activeIndex = i;
            siteImage.selected = true;
          }
        } else {
          siteImage.selected = false;
        }
      }.bind(this)
    );
    this.setState({
      images: images,
      activeImage: activeImage,
      activeIndex: activeIndex
    });
  };
  render() {
    return (
      <div className="card setImage">
        <div onClick={this.props.close} className="exit">
          Close
        </div>{" "}
        <div className="row row-space">
          {this.state.activeImage !== undefined ? (
            <img src={this.state.activeImage.thumbFive} />
          ) : (
            ""
          )}
        </div>
        <div className="row row-space">
          <SiteImageContainer
            siteImages={this.state.images}
            select={this.selectImage}
          />
        </div>
        {this.state.activeImage !== undefined ? (
          <button
            onClick={() => {
              this.props.setAboutImage(this.state.activeImage);
            }}
          >
            Set About Image
          </button>
        ) : (
          ""
        )}
      </div>
    );
  }
}
