import React, { Component } from "react";

export default class ActiveImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeImage: undefined,
      editActive: this.props.editActive,
      hasThumb: false,
      name: "",
      caption: "",
      loading: true
    };
  }
  setFields = nextProps => {
    if (nextProps == undefined) {
      let hasThumb =
        this.props.activeImage.thumbFive !== undefined ? true : false;

      this.setState({
        hasThumb: hasThumb,
        activeImage: this.props.activeImage,
        name: this.props.activeImage.name,
        caption: this.props.activeImage.caption,
        loading: false
      });
    } else {
      console.log(
        "inside setFields: image is: " + JSON.stringify(nextProps.activeImage)
      );
      let hasThumb =
        nextProps.activeImage.thumbFive !== undefined ? true : false;

      this.setState({
        hasThumb: hasThumb,
        activeImage: nextProps.activeImage,
        name: nextProps.activeImage.name,
        caption: nextProps.activeImage.caption,
        loading: false
      });
    }
  };
  componentWillReceiveProps(nextProps) {
    console.log("will recieve props");
    console.log("props are: " + nextProps.activeImage.name);
    this.setFields(nextProps);
    if (nextProps.activeImage.name !== this.props.activeImage.name) {
      console.log("different props");
    }
  }
  componentDidUpdate() {
    // console.log("component updated, image is: " + this.props.activeImage.name);
    // if (this.props.activeImage.name !== this.state.activeImage.name) {
    //   console.log("props and state not same, calling setfields");
    //   this.setFields();
    // }
  }
  componentDidMount() {
    console.log("inside activeImage");
    this.setFields();
  }
  onChange = event => {
    console.log("changing state for : " + event.target.name);
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    console.log("name is : " + JSON.stringify(this.state.name));
    return (
      <div className="activeImageContainer col col-md-6 col-lg-6">
        <p className="lead">Active Image</p>
        {this.state.activeImage !== undefined ? (
          <div>
            {" "}
            <img
              alt="active"
              className="activeImage"
              src={
                this.state.activeImage !== undefined
                  ? this.state.activeImage.thumbFive
                  : ""
              }
            />
            <p className="small">
              <b>Name: </b>
              {this.state.name}
            </p>
            {this.props.editActive ? (
              <input
                type="text"
                name="name"
                value={this.state.name}
                onChange={this.onChange}
              />
            ) : (
              ""
            )}
            <p className="small">
              <b>Caption: </b>
              {this.state.caption}
            </p>
            {this.props.editActive ? (
              <input
                type="text"
                name="caption"
                value={this.state.caption}
                onChange={this.onChange}
              />
            ) : (
              ""
            )}
            {this.props.editActive ? (
              <div>
                <button
                  className="controlButton btn btn-sm btn-secondary"
                  onClick={() => {
                    console.log("name is: " + JSON.stringify(this.state.name));
                    this.props.submitEdit({
                      name: this.state.name,
                      caption: this.state.caption,
                      id: this.props.activeImage.id
                    });
                  }}
                >
                  Save Image Info
                </button>
                <button
                  className="controlButton btn btn-sm btn-secondary"
                  onClick={() => {
                    this.props.editMeta();
                  }}
                >
                  Cancel
                </button>
              </div>
            ) : (
              ""
            )}
            <p className="small">
              <b>Has Thumbnails?: </b>
              {this.state.hasThumb ? "Yes" : "No"}
            </p>
            <button
              className="controlButton btn btn-sm btn-secondary"
              disabled={this.state.hasThumb ? true : false}
              onClick={() => {
                this.props.generateThumbnails();
              }}
            >
              Generate Thumbnails
            </button>
            <button
              className="controlButton btn btn-sm btn-secondary"
              onClick={() => {
                this.props.deleteImage();
              }}
            >
              Delete Image
            </button>
            <button
              className="controlButton btn btn-sm btn-secondary"
              onClick={() => {
                this.props.editMeta();
              }}
            >
              Edit Name And Caption
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
