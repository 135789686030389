import React from "react";
import { Link } from "react-router-dom";

export default function Tabs() {
  let urlString = window.location.href;
  console.log("url string is " + urlString);
  let length = urlString.length - 2;
  let lastChar = urlString.substr(-1);
  console.log("lastChar:" + " " + lastChar);
  let partialStringIndex;
  if (lastChar !== "/") {
    partialStringIndex = urlString.indexOf("/dashboard");
  } else {
    partialStringIndex = urlString.indexOf("/dashboard/");
  }
  console.log("partialStringIndex is " + partialStringIndex);
  let partialString = urlString.slice(partialStringIndex);
  console.log("partial string is " + partialString);
  return (
    <div className="tabs">
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <Link
            to="/dashboard/clients"
            className={
              partialString == "/dashboard/clients" ||
              partialString == "/dashboard/clients/" ||
              partialString.includes("/dashboard/clients/") ||
              partialString == "/dashboard" ||
              partialString == "/dashboard/"
                ? "tabLink nav-link active"
                : "tabLink nav-link"
            }
          >
            Clients
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/dashboard/site-management"
            className={
              partialString == "/dashboard/site-management" ||
              partialString == "/dashboard/site-management/" ||
              partialString == "/dashboard/site-management/home" ||
              partialString == "/dashboard/site-management/home/" ||
              partialString == "/dashboard/site-management/pricing" ||
              partialString == "/dashboard/site-management/pricing/" ||
              partialString == "/dashboard/site-management/portfolio" ||
              partialString == "/dashboard/site-management/portfolio/" ||
              partialString == "/dashboard/site-management/about" ||
              partialString == "/dashboard/site-management/about/" ||
              partialString == "/dashboard/site-management/contact" ||
              partialString == "/dashboard/site-management/contact/" ||
              partialString == "/dashboard/site-management/images" ||
              partialString == "/dashboard/site-management/images/"
                ? "tabLink nav-link active"
                : "tabLink nav-link"
            }
          >
            Site Management
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/dashboard/upload"
            className={
              partialString == "/dashboard/upload" ||
              partialString == "/dashboard/upload/"
                ? "tabLink nav-link active"
                : "tabLink nav-link"
            }
          >
            Upload
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/dashboard/invoices"
            className={
              partialString == "/dashboard/invoices" ||
              partialString == "/dashboard/invoices/"
                ? "tabLink nav-link active"
                : "tabLink nav-link"
            }
          >
            Invoices
          </Link>
        </li>
      </ul>
    </div>
  );
}
