import React, { Component } from "react";
import ClientUploadUtility from "./FileUploadUtility";
import LoadingScreen from "./../../common/LoadingScreen";
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";

class ClientUpload extends Component {
  constructor(props) {
    super(props);
    this.state = { clients: [], loading: true };
  }
  getClients = () => {
    console.log("inside getClients");
    this.props.firebase.db
      .collection("users")
      .get()
      .then(
        function(res) {
          let clients = [];
          res.forEach((client, i) => {
            console.log("found client: ");
            clients.push(client.data());
          });
          this.setState({ clients: clients, loading: false });
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting clients: " + err.message);
      });
  };
  setActiveClient = event => {
    console.log("inside setActiveClient: " + event.target.value);
    let clients = this.state.clients;
    let activeClient = undefined;
    clients.forEach((client, i) => {
      if (event.target.value === client.fullName) {
        console.log("found client");
        activeClient = client;
      }
    });
    if (activeClient !== undefined) {
      this.setState({ activeClient: activeClient });
    } else {
      this.setState({ activeClient: undefined });
    }
  };
  componentDidMount() {
    this.getClients();
  }
  render() {
    return (
      <div>
        {this.state.loading ? (
          <i className="spinner fas fa-circle-notch fa-spin" />
        ) : (
          <div>
            <div className="form-group">
              <label htmlFor="exampleFormControlSelect1">Select Client</label>
              <select
                onChange={this.setActiveClient}
                className="form-control"
                id="exampleFormControlSelect1"
              >
                <option></option>
                {this.state.clients.map((client, i) => (
                  <option key={i}>{client.fullName}</option>
                ))}
              </select>
            </div>
            {this.state.activeClient !== undefined ? (
              <ClientUploadUtility
                client={this.state.activeClient}
                destination="client"
              />
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    );
  }
}

export default compose(withFirebase)(ClientUpload);
