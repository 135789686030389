import React, { Component } from "react";
import { withFirebase } from "./../../../../firebase/index";
import { compose } from "redux";
import LoadingScreen from "./../../../common/LoadingScreen";
import SiteImageContainer from "./SiteImageContainer";
import Confirmation from "./../../../common/Confirmation";
import OverlayModule from "./../pagesManagement/OverlayModule";
import PortStyle from "./PortStyle";
class EditPortfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      port: this.props.port,
      siteImages: [],
      portImages: [],
      loading: true,
      selectedImages: 0,
      selectedPortImages: 0,
      closeConfirmation: false,
      defaultChanged: false,
      portStyle: "block",
      navStyle: "sticky"
    };
  }
  answerNavStyle = style => {
    switch (style) {
      case "sticky":
        this.setState({ navStyle: "sticky" });
        break;
      case "animated":
        this.setState({ navStyle: "animated" });
        break;
      case "relative":
        this.setState({ navStyle: "relative" });
        break;
      default:
        break;
    }
  };
  changePortStyle = answer => {
    switch (answer) {
      case "block":
        this.setState({ portStyle: "block" });
        break;
      case "cinema":
        this.setState({ portStyle: "cinema" });
        break;
      default:
        break;
    }
  };
  savePortSettings = () => {
    console.log("saving port settings");
    this.setState({ loading: true });
    let defaultImage;
    if (this.state.defaultImage == undefined) {
      this.props.firebase.db
        .collection("portfolios")
        .doc(this.props.port.name)
        .update({
          overlay: this.state.overlay,
          pageHeading: this.state.pageHeading,
          headingShadow: this.state.headingShadow,
          headingColor: this.state.headingColor,
          portStyle: this.state.portStyle,
          navStyle: this.state.navStyle
        })
        .then(
          function(res) {
            console.log("just updated port node");
            this.setState({
              message: "Successfully Updated Port Settings",
              loading: false,
              defaultChanged: false
            });
          }.bind(this)
        )
        .catch(function(err) {
          console.log("error updating port node: " + err.message);
        });
    } else {
      this.props.firebase.db
        .collection("portfolios")
        .doc(this.props.port.name)
        .update({
          defaultImageThumb: this.state.defaultImage.thumbFive,
          defaultImage: this.state.defaultImage.url,
          overlay: this.state.overlay,
          pageHeading: this.state.pageHeading,
          headingShadow: this.state.headingShadow,
          headingColor: this.state.headingColor,
          portStyle: this.state.portStyle,
          navStyle: this.state.navStyle
        })
        .then(
          function(res) {
            console.log("just updated port node");
            this.setState({
              message: "Successfully Updated Port Settings",
              loading: false,
              defaultChanged: false
            });
          }.bind(this)
        )
        .catch(function(err) {
          console.log("error updating port node: " + err.message);
        });
    }
  };
  savePort = () => {
    this.setState({ loading: true });
    console.log(
      "inside savePort, props port is : " + JSON.stringify(this.props.port.name)
    );
    this.props.firebase.db
      .collection("portfolios")
      .doc(this.props.port.name)
      .get()
      .then(
        function(res) {
          if (res.exists) {
            let portObj = res.data();
            portObj.images = this.state.portImages;
            this.props.firebase.db
              .collection("portfolios")
              .doc(this.props.port.name)
              .set(portObj)
              .then(
                function(response) {
                  console.log("successfully wrote port obj");

                  this.setState(
                    {
                      message: "Successfully Updated Portfolio",
                      loading: false,
                      imagesToSave: false
                    },
                    () => {
                      this.props.getPortfolios();
                    }
                  );
                }.bind(this)
              )
              .catch(function(err) {
                console.log("error writting portObj: " + err.message);
              });
          }
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error writing port images: " + err.message);
      });
  };
  close = () => {
    if (this.state.imagesToSave) {
      this.setState({ closeConfirmation: true });
    } else {
      this.props.close();
    }
  };
  answerClose = answer => {
    if (answer) {
      this.props.close();
    } else {
      this.setState({ closeConfirmation: false });
    }
  };
  removePortImages = () => {
    console.log("inside removePortImages");
    let newPort = [];
    let newSite = [];
    let idsToRemove = [];
    let selectedCount = this.state.selectedPortImages;
    let siteSelectedCount = this.state.selectedImages;
    for (let i = 0; i < this.state.portImages.length; i++) {
      if (this.state.portImages[i].selected !== true) {
        let image = this.state.portImages[i];
        newPort.push(image);
      } else {
        selectedCount = selectedCount - 1;
        idsToRemove.push(this.state.portImages[i].id);
      }
    }
    console.log("ids to remove are: " + JSON.stringify(idsToRemove));
    for (let i = 0; i < this.state.siteImages.length; i++) {
      console.log("checking site image for selected removal");
      let newImage = this.state.siteImages[i];
      if (idsToRemove.includes(this.state.siteImages[i].id)) {
        console.log("found image to remove selected status");
        newImage.selected = false;
        siteSelectedCount = siteSelectedCount - 1;
      }
      newSite.push(newImage);
    }
    this.setState({
      siteImages: newSite,
      portImages: newPort,
      selectedPortImages: selectedCount,
      selectedImages: siteSelectedCount,
      imagesToSave: true
    });
  };
  addImagesToPort = () => {
    this.setState({ loading: true });
    console.log("adding images to port");

    let portImages = [];
    for (let i = 0; i < this.state.siteImages.length; i++) {
      if (this.state.siteImages[i].selected === true) {
        let newImage = {};
        newImage.id = this.state.siteImages[i].id;
        newImage.caption = this.state.siteImages[i].caption;
        newImage.name = this.state.siteImages[i].name;
        newImage.thumbFive = this.state.siteImages[i].thumbFive;
        newImage.url = this.state.siteImages[i].url;
        newImage.selected = false;
        portImages.push(newImage);
      }
    }

    this.setState({
      portImages: portImages,
      loading: false,
      selectedImages: 0,
      imagesToSave: true
    });
  };
  // addImage = image => {
  //   let portImages = [...this.state.portImages];
  //   portImages.push(image);
  //   this.setState({ portImages: portImages });
  // };
  selectDefaultImage = image => {
    let images = this.state.defaultImages;
    for (let i = 0; i < images.length; i++) {
      if (image == images[i]) {
        console.log("found image in defaultImages");
        if (images[i].selected == true) {
          images[i].selected = false;
          image = undefined;
        } else {
          images[i].selected = true;
        }
      } else {
        images[i].selected = false;
      }
    }
    this.setState({ defaultImage: image, defaultChanged: true });
  };
  selectImage = image => {
    let portImageIds = [];
    for (let i = 0; i < this.state.portImages.length; i++) {
      portImageIds.push(this.state.portImages[i].id);
    }

    this.state.siteImages.forEach(
      function(siteImage, i) {
        if (image == siteImage) {
          console.log("found image that was clicked");
          let images = this.state.siteImages;
          let portImages = this.state.portImages;
          let selectedImages = this.state.selectedImages;
          if (image.selected == true) {
            if (portImageIds.includes(image.id)) {
              console.log("in port images, shouldnt deselect");
              return;
            }
            selectedImages = selectedImages - 1;
            images[i].selected = false;
          } else {
            selectedImages = selectedImages + 1;
            images[i].selected = true;
          }
          this.setState({ siteImages: images, selectedImages: selectedImages });
        }
      }.bind(this)
    );
  };
  selectPortfolioImage = image => {
    this.state.portImages.forEach(
      function(siteImage, i) {
        if (image == siteImage) {
          let images = this.state.portImages;
          let selectedImages = this.state.selectedPortImages;
          if (image.selected == true) {
            selectedImages = selectedImages - 1;
            images[i].selected = false;
          } else {
            selectedImages = selectedImages + 1;
            images[i].selected = true;
          }
          this.setState({
            portImages: images,
            selectedPortImages: selectedImages
          });
        }
      }.bind(this)
    );
  };
  setDefaultImages = () => {
    let defaultImages = [];
    let defaultImage = undefined;
    for (let i = 0; i < this.state.siteImages.length; i++) {
      let img = {
        caption: this.state.siteImages[i].caption,
        name: this.state.siteImages[i].name,
        id: this.state.siteImages[i].id,
        path: this.state.siteImages[i].path,
        thumbFive: this.state.siteImages[i].thumbFive,
        time: this.state.siteImages[i].time,
        type: this.state.siteImages[i].type,
        url: this.state.siteImages[i].url,
        selected: false
      };

      if (this.props.port.defaultImage !== "") {
        if (this.state.siteImages[i].url == this.props.port.defaultImage) {
          img.selected = true;
          defaultImage = img;
        }
      }
      defaultImages.push(img);
    }
    if (defaultImage !== undefined) {
      this.setState({
        defaultImages: defaultImages,
        defaultImage: defaultImage
      });
    } else {
      this.setState({ defaultImages: defaultImages });
    }
  };
  setSiteImages = (portImages, ids) => {
    let images = [];
    let defaultImages = [];
    let originalPortLength = 0;
    if (this.props.port.images !== undefined) {
      originalPortLength = this.props.port.images.length;
    }
    this.props.siteImages.forEach(function(img) {
      if (img.thumbFive !== undefined) {
        console.log("adding image");
        defaultImages.push(img);
        if (ids.includes(img.id)) {
          img.selected = true;
        } else {
          img.selected = false;
        }
        images.push(img);
      }
    });

    this.setState(
      {
        originalPortLength: originalPortLength,
        defaultImages: defaultImages,
        portImages: portImages,
        siteImages: images,
        loading: false
      },
      () => {
        this.setDefaultImages();
      }
    );
  };
  setPortImages = () => {
    this.setState({ loading: true });
    console.log("port images from props are: " + this.props.port.images);
    let portImages = [];
    let ids = [];
    if (this.props.port.images == undefined) {
      this.setSiteImages(portImages, ids);
    } else {
      let originalPortLength = this.props.port.images.length;
      for (let i = 0; i < this.props.port.images.length; i++) {
        let image = this.props.port.images[i];
        portImages.push(image);
        ids.push(image.id);
      }
      this.setSiteImages(portImages, ids);
    }
  };
  setPortSettings = () => {
    let heading, overlay, headingColor, headingShadow, portStyle;
    if (this.props.port.pageHeading == undefined) {
      heading = this.props.port.name;
    } else {
      heading = this.props.port.pageHeading;
    }
    if (this.props.port.overlay == undefined) {
      overlay = "none";
    } else {
      overlay = this.props.port.overlay;
    }
    if (this.props.port.headingColor == undefined) {
      headingColor = "white";
    } else {
      headingColor = this.props.port.headingColor;
    }
    if (this.props.port.headingShadow == undefined) {
      headingShadow = "yes";
    } else {
      headingShadow = this.props.port.headingShadow;
    }
    if (this.props.port.portStyle == undefined) {
      console.log("setting portStlye to block because undefined");
      portStyle = "block";
    } else {
      console.log("setting to props: " + this.props.port.portStyle);
      portStyle = this.props.port.portStyle;
    }
    let navStyle;
    if (this.props.port.navStyle == undefined) {
      navStyle = "sticky";
    } else {
      navStyle = this.props.port.navStyle;
    }
    this.setState({
      overlay: overlay,
      headingColor: headingColor,
      headingShadow: headingShadow,
      pageHeading: heading,
      portStyle: portStyle,
      navStyle: navStyle
    });
  };
  componentDidMount() {
    this.setPortImages();
    this.setPortSettings();
  }
  overlayAnswer = answer => {
    console.log("inside overlay answer");
    this.setState({ overlay: answer });
  };
  setPageHeading = heading => {
    console.log("inside set page heading, setting heading to: " + heading);
    this.setState({ pageHeading: heading });
  };
  setPageHeadingColor = color => {
    console.log("inside setpageheadingcolor setting color to " + color);
    this.setState({ headingColor: color });
  };
  setPageHeadingShadow = answer => {
    console.log("inside setpageheadingshadow: " + answer);
    this.setState({ headingShadow: answer });
  };
  render() {
    console.log(
      "from editPortfolio, portImages length is " + this.state.portImages.length
    );
    return (
      <div className="editPortfolio">
        {this.state.closeConfirmation ? (
          <Confirmation
            question="You Have Unsaved Portfolio Changes. Are You Sure You Want To Close"
            answer={this.answerClose}
          />
        ) : (
          ""
        )}
        <div className="exit" onClick={this.close}>
          close
        </div>
        {this.state.loading ? (
          <LoadingScreen />
        ) : (
          <div className="editPortfolioContainer">
            <p className="display-4">
              Editing Portfolio: {this.props.port.name}
            </p>
            <p className="small">
              If you don't see uploaded images in your site image container,
              they may not have thumbnails available. Visit the Image Managment
              area to generate thumbnails for those images.
            </p>
            {this.state.message !== undefined ? (
              <p className="alert lead">{this.state.message}</p>
            ) : (
              ""
            )}
            <div className="imagesContainer">
              <div className="row headline">
                <p className="display-5 imagesHeadline">
                  Select Images For Portfolio
                </p>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-xs-12">
                  {" "}
                  <p className="display-5">Site Images</p>
                  <SiteImageContainer
                    addImage={this.addImage}
                    siteImages={this.state.siteImages}
                    select={this.selectImage}
                  />
                  {this.state.selectedImages > 0 ? (
                    <button
                      className="controlButton btn btn-sm btn-secondary"
                      onClick={this.addImagesToPort}
                    >
                      Add
                    </button>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-lg-6 col-md-6 col-xs-12">
                  {" "}
                  <p className="display-5">Portfolio Images</p>
                  <SiteImageContainer
                    addImage={this.addImage}
                    siteImages={this.state.portImages}
                    select={this.selectPortfolioImage}
                  />
                  {this.state.selectedPortImages > 0 ? (
                    <button
                      className="controlButton btn btn-sm btn-secondary"
                      onClick={this.removePortImages}
                    >
                      Remove Selected Port Images
                    </button>
                  ) : (
                    ""
                  )}
                  {this.state.portImages.length > 0 &&
                  this.state.imagesToSave ? (
                    <button
                      onClick={this.savePort}
                      className="controlButton btn btn-sm btn-secondary"
                    >
                      Save Portfolio
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div className="portSettingsContainer container">
              <div className="row headline">
                <p className="display-5 portSettingsHeadline">Page Settings</p>
              </div>

              <div className="row">
                <div className="col col-md-6 col-lg-6">
                  <p className="display-5 portSettingsHeadline">
                    Select Default Image (For Page Background)
                  </p>
                  {this.state.defaultImage !== undefined ? (
                    <img
                      alt="active"
                      className="activeImage"
                      src={this.state.defaultImage.url}
                    />
                  ) : (
                    ""
                  )}
                  <SiteImageContainer
                    addImage={this.addImage}
                    siteImages={this.state.defaultImages}
                    select={this.selectDefaultImage}
                  />
                </div>
                <div className="col col-md-6 col-lg-6">
                  <OverlayModule
                    answer={this.overlayAnswer}
                    overlay={this.state.overlay}
                    setPageHeading={this.setPageHeading}
                    answerHeadingColor={this.setPageHeadingColor}
                    headingColor={this.state.headingColor}
                    pageHeading={this.state.pageHeading}
                    answerHeadingShadow={this.setPageHeadingShadow}
                    headingShadow={this.state.headingShadow}
                    navStyle={this.state.navStyle}
                    answerNavStyle={this.answerNavStyle}
                  />
                  <hr />
                  <PortStyle
                    style={this.state.portStyle}
                    answer={this.changePortStyle}
                  />
                  <hr />
                  <button
                    onClick={this.savePortSettings}
                    className="btn btn-sm btn-secondary"
                  >
                    Save Port Settings
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default compose(withFirebase)(EditPortfolio);
