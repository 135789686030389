import React, { Component } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import axios from "axios";
import { processCharge } from "./../../versionInfo";

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = { loading: false };
  }

  async submit(ev) {
    // User clicked submit
    this.setState({ loading: true });
    let { token } = await this.props.stripe.createToken({
      name: this.props.client.fullName
    });
    let amount = parseFloat(this.props.total) * 100;
    console.log("amount is: " + amount);
    let invoice = this.props.invoice;
    invoice.amount = amount;
    axios
      .post(processCharge, {
        token: token.id,
        amount: amount,
        client: this.props.client,
        invoice: invoice
      })
      .then(
        function(res) {
          console.log(
            "response from stripe and backend: " + JSON.stringify(res)
          );
          if (res.status == 200) {
            console.log("successful payment");
            this.props.end(200);
          }
        }.bind(this)
      )
      .catch(
        function(err) {
          console.log("error posting card stuff: " + JSON.stringify(err));
          this.props.end(500);
        }.bind(this)
      );
  }

  render() {
    return (
      <div className="checkout">
        <p>Would you like to complete the purchase?</p>
        <CardElement />
        {this.state.loading ? (
          <i className="spinner fas fa-circle-notch fa-spin" />
        ) : (
          <button onClick={this.submit}>Purchase</button>
        )}
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);
