export const admin = ["kreplogle88@icloud.com"];

export const version = "1.0";

export const devAddClient =
  "https://us-central1-katiephotographydev.cloudfunctions.net/createAccount";
export const prodAddClient =
  "https://us-central1-katiephotographyproduction.cloudfunctions.net/createAccount";

export const devEditClient =
  "https://us-central1-katiephotographydev.cloudfunctions.net/editAccount";
export const prodEditClient =
  "https://us-central1-katiephotographyproduction.cloudfunctions.net/editAccount";

export const devDeleteClient =
  "https://us-central1-katiephotographydev.cloudfunctions.net/deleteAccount";
export const prodDeleteClient =
  "https://us-central1-katiephotographyproduction.cloudfunctions.net/deleteAccount";
export const devDownloadZip =
  "https://us-central1-katiephotographydev.cloudfunctions.net/downloadZip";
export const prodDownloadZip =
  "https://us-central1-katiephotographyproduction.cloudfunctions.net/downloadZip";
export const devSendMail =
  "https://us-central1-katiephotographydev.cloudfunctions.net/sendMail";
export const prodSendMail =
  "https://us-central1-katiephotographyproduction.cloudfunctions.net/sendMail";
export const devAdminSendMail =
  "https://us-central1-katiephotographydev.cloudfunctions.net/adminSendMail";
export const prodAdminSendMail =
  "https://us-central1-katiephotographyproduction.cloudfunctions.net/adminSendMail";
export const devProcessCharge =
  "https://us-central1-katiephotographydev.cloudfunctions.net/processCharge";
export const prodProcessCharge =
  "https://us-central1-katiephotographyproduction.cloudfunctions.net/processCharge";
export const devFirebaseConfig = {
  apiKey: "AIzaSyBrzvbu9kDdsd9WBar54TQWpAUWEow9DVI",
  authDomain: "katiephotographydev.firebaseapp.com",
  databaseURL: "https://katiephotographydev.firebaseio.com",
  projectId: "katiephotographydev",
  storageBucket: "katiephotographydev.appspot.com",
  messagingSenderId: "357033620058",
  appId: "1:357033620058:web:bbb074e3dbbe3b431ade63",
  measurementId: "G-0Z4FCSSFKC"
};
export const prodFirebaseConfig = {
  apiKey: "AIzaSyDh0Hbd7wodvqCnoAl4havIp_Ljy5gy-o0",
  authDomain: "katiephotographyproduction.firebaseapp.com",
  databaseURL: "https://katiephotographyproduction.firebaseio.com",
  projectId: "katiephotographyproduction",
  storageBucket: "katiephotographyproduction.appspot.com",
  messagingSenderId: "312423038147",
  appId: "1:312423038147:web:9121109f49f716352ff676",
  measurementId: "G-V9WVDH4EKV"
};
export const testStripeApiKey = "pk_test_dvnWyzxK9KMynj3iQGEmkUUv004msP7RBm";
export const liveStripeApiKey = "pk_live_9rOG85oAApQ5Ov6vMtbdm9qx00sjp0JwWr";
export var addClient = checkVersion("addClient");
export var editClient = checkVersion("editClient");
export var deleteClient = checkVersion("deleteClient");
export var sendMail = checkVersion("sendMail");
export var adminSendMail = checkVersion("adminSendMail");
export var processCharge = checkVersion("processCharge");
export var stripeApiKey = checkVersion("stripeApiKey");
export var firebaseConfig = checkVersion("firebaseConfig");
export var downloadZip = checkVersion("downloadZip");
function checkVersion(method) {
  if (version == "dev") {
    switch (method) {
      case "addClient":
        return devAddClient;

      case "editClient":
        return devEditClient;

      case "deleteClient":
        return devDeleteClient;
      case "sendMail":
        return devSendMail;
      case "adminSendMail":
        return devAdminSendMail;
      case "processCharge":
        return devProcessCharge;
      case "stripeApiKey":
        return testStripeApiKey;
      case "firebaseConfig":
        return devFirebaseConfig;
      case "downloadZip":
        return devDownloadZip;
      default:
        break;
    }
  } else {
    switch (method) {
      case "addClient":
        return prodAddClient;

      case "editClient":
        return prodEditClient;

      case "deleteClient":
        return prodDeleteClient;
      case "sendMail":
        return prodSendMail;
      case "adminSendMail":
        return prodAdminSendMail;
      case "processCharge":
        return prodProcessCharge;
      case "stripeApiKey":
        return liveStripeApiKey;
      case "firebaseConfig":
        return prodFirebaseConfig;
      case "downloadZip":
        return prodDownloadZip;
      default:
        break;
    }
  }
}
