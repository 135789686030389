import React, { Component } from "react";

export default class SendEmail extends Component {
  constructor() {
    super();
    this.state = {
      subject: "",
      content: "",
      valid: false,
      loading: false
    };
  }
  submit = () => {
    let message = {
      subject: this.state.subject,
      content: this.state.content
    };
    this.props.sendMail(message);
  };
  onChange = event => {
    switch (event.target.name) {
      case "subject":
        if (event.target.value !== "") {
          if (this.state.content !== "") {
            this.setState({ subject: event.target.value, valid: true });
          } else {
            this.setState({ subject: event.target.value, valid: false });
          }
        } else {
          this.setState({ subject: event.target.value, valid: false });
        }

        break;
      case "content":
        if (event.target.value !== "") {
          if (this.state.subject !== "") {
            this.setState({ content: event.target.value, valid: true });
          } else {
            this.setState({ content: event.target.value, valid: false });
          }
        } else {
          this.setState({ content: event.target.value, valid: false });
        }
        break;
      default:
        break;
    }
  };
  render() {
    return (
      <div className="sendEmail card addClient">
        {this.state.loading ? (
          <i className="spinner fas fa-circle-notch fa-spin" />
        ) : (
          <div className="container container-fluid">
            <p className="lead">Send {this.props.client.fullName} a Message</p>
            <div className="form-group row">
              <div className="col col-sm-6">
                <label htmlFor="name">Subject</label>

                <input
                  type="text"
                  className="form-control"
                  value={this.state.subject}
                  name="subject"
                  onChange={this.onChange}
                  id="name"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col col-sm-12">
                {" "}
                <label htmlFor="content">Your Message:</label>
                <textarea
                  className="form-control"
                  name="content"
                  id="content"
                  value={this.state.content}
                  onChange={this.onChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-9"></div>
              <div className="col-sm-3 ">
                <button
                  disabled={this.state.valid ? false : true}
                  onClick={this.submit}
                  className="btn btn-secondary"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
