import React, { Component } from "react";

import { withFirebase } from "../../firebase/index";
import { compose } from "redux";
import LoadingScreen from "../common/LoadingScreen";
import ClientImageContainer from "./ClientImageContainer";
import axios from "axios";
import formatDate from "../common/formatDate";
import Confirmation from "../common/Confirmation";
import ImageGallery from "react-image-gallery";
import { downloadZip } from "./../../versionInfo";
import Tabs from "./Tabs";
import "./gallery.css";

class Download extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      message: undefined,
      client: undefined,
      images: [],
      selectedImages: [],
      zips: [],
      galleryImages: []
    };
  }
  componentDidMount() {
    this.getClientInfo();
  }
  deleteZip = () => {
    return new Promise(
      function(res, rej) {
        this.props.firebase.storage
          .ref(
            "client/" +
              this.state.client.uid +
              "/" +
              this.state.zips[0].time +
              "photos.zip"
          )
          .delete()
          .then(function(obj) {
            console.log("successfully deleted image");
            res("success");
          })
          .catch(function(err) {
            console.log("error deleting image: " + err.message);
            res(err.message);
          });
      }.bind(this)
    );
  };
  answerConfirmOverwrite = answer => {
    this.setState({ confirmation: false });
    if (answer) {
      this.deleteZip().then(res => {
        this.props.firebase.db
          .collection("users")
          .doc(this.state.client.uid)
          .collection("zips")
          .doc(this.state.zips[0].time + "photos")
          .delete()
          .then(
            function(res) {
              this.setState({ zips: [] }, () => {
                this.handleDownload();
              });
            }.bind(this)
          )
          .catch(function(err) {
            console.log("error deleting old zip from db: " + err.message);
          });
      });
    }
  };
  handleDownload = () => {
    if (this.state.pendingZips.length > 0) {
      let now = new Date();
      let nowTime = now.getTime();
      console.log("pending zip");
      if (nowTime - this.state.pendingZips[0].time < 600000) {
        this.setState({
          message:
            "You have a pending Zip File Download. If Something Went Wrong With THe Creation Of That Download, You Will Be Able To Initiate A New One After 10 Minutes"
        });
      } else {
        this.initiateDownload();
      }
    } else {
      this.initiateDownload();
    }
  };
  initiateDownload = () => {
    if (this.state.zips.length > 0) {
      this.setState({ confirmation: true });
    } else {
      if (this.state.selectedImages.length > 0) {
        let date = new Date();
        let time = date.getTime();
        this.setState({ zipLoading: true });
        axios

          .post(downloadZip, {
            images: this.state.selectedImages,
            clientUid: this.state.client.uid,
            time: time
          })
          .then(
            function(res) {
              console.log("res from handleDownload is: " + JSON.stringify(res));
              this.setState(
                {
                  message:
                    "Zip File Generated Successfully, Refresh To See Download Link (May Take A While)",
                  zipLoading: false
                },
                () => {
                  this.props.firebase.db
                    .collection("users")
                    .doc(this.state.client.uid)
                    .collection("pendingZips")
                    .doc(time + "zip")
                    .set({ time: time })
                    .then(
                      function(res) {
                        this.getClientInfo();
                      }.bind(this)
                    )
                    .catch(function(err) {
                      console.log("error writing pendingZip: " + err.message);
                    });
                }
              );
            }.bind(this)
          )
          .catch(function(err) {
            console.log("error with get, " + err.message);
          });
      }
    }
  };
  getDownloadURL = time => {
    console.log("inside getdownloadurl");
    this.props.firebase.storage
      .ref("client/" + this.state.client.uid + "/" + time + "photos.zip")
      .getDownloadURL()
      .then(
        function(res) {
          console.log("inside getdownloadurl res");
          console.log("url is: " + res);
          this.props.firebase.db
            .collection("users")
            .doc(this.state.client.uid)
            .collection("zips")
            .doc(time + "photos")
            .set({ url: res, time: time })
            .then(
              function(res) {
                this.props.firebase.db
                  .collection("users")
                  .doc(this.state.client.uid)
                  .collection("pendingZips")
                  .doc(time + "zip")
                  .delete()
                  .then(
                    function(res) {
                      this.setState(
                        {
                          zipLoading: false,
                          message: "Zip Download Available"
                        },
                        () => {
                          this.getPendingZips();
                        }
                      );
                    }.bind(this)
                  )
                  .catch(function(err) {
                    console.log("error deleting pendingZip: " + err.message);
                  });
              }.bind(this)
            );
        }.bind(this)
      )
      .catch(
        function(err) {
          console.log("error getting dowload url: " + err.message);
          this.setState({
            message:
              "Your zip download is still being prepared by the server. Wait a bit and refresh to try again."
          });
        }.bind(this)
      );
  };
  downloadImage = () => {
    var dlink = document.createElement("a");
    dlink.download = this.state.selectedImages[0].name;
    dlink.href = this.state.selectedImages[0].url;
    dlink.target = "_blank";
    dlink.click();
    dlink.remove();
  };
  downloadZip = () => {
    var dlink = document.createElement("a");
    dlink.download = this.state.zips[0].time + "photos.zip";
    dlink.href = this.state.zips[0].url;
    dlink.click();
    dlink.remove();
  };
  download = selectedImages => {
    this.setState({ selectedImages: selectedImages });
  };
  getPendingZips = () => {
    this.props.firebase.db
      .collection("users")
      .doc(this.state.client.uid)
      .collection("pendingZips")
      .get()
      .then(
        function(res) {
          let zips = [];
          res.forEach((zip, i) => {
            console.log("got pending zip");
            this.getDownloadURL(zip.data().time);
            zips.push(zip.data());
          });
          this.setState({ pendingZips: zips });
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting zips: " + err.message);
      });
    this.props.firebase.db
      .collection("users")
      .doc(this.state.client.uid)
      .collection("zips")
      .get()
      .then(
        function(res) {
          let zips = [];
          res.forEach((zip, i) => {
            zips.push(zip.data());
          });
          this.setState({ zips: zips });
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting zips: " + err.message);
      });
  };
  getClientInfo = () => {
    this.props.firebase.db
      .collection("users")
      .doc(this.props.firebase.auth.currentUser.uid)
      .get()
      .then(
        function(res) {
          if (res.exists) {
            console.log("have client");
            this.setState({ client: res.data(), loading: false }, () => {
              let images = [];
              this.props.firebase.db
                .collection("users")
                .doc(this.state.client.uid)
                .collection("images")
                .get()
                .then(
                  function(res) {
                    res.forEach((image, i) => {
                      images.push(image.data());
                    });
                    this.setState({ images: images, loading: false }, () => {
                      this.getPendingZips();
                    });
                  }.bind(this)
                )
                .catch(
                  function(err) {
                    console.log("error getting images: " + err.message);
                    this.setState({
                      message:
                        "There Was An Error Getting Your Images From The Database, Check Connection And Try Again",
                      loading: false
                    });
                  }.bind(this)
                );
            });
          } else {
            console.log("error getting client information");
            this.setState({
              message:
                "There Was An Error Getting Information From The Database, Check Connection And Try Again."
            });
          }
        }.bind(this)
      );
  };
  render() {
    let downloadButton;
    if (this.state.selectedImages.length > 0) {
      if (this.state.selectedImages.length == 1) {
        //download single image
        downloadButton = (
          <div>
            <button
              onClick={this.downloadImage}
              className="btn btn-sm btn-secondary dashboardButton"
              disabled={this.state.zipLoading ? true : false}
            >
              Download Single Image
            </button>
          </div>
        );
      } else {
        //download bulk
        downloadButton = (
          <div>
            <p className="small">
              {this.state.zipLoading ? (
                <p className="small">Generating Zip File...</p>
              ) : (
                <p className="lead">
                  Select Photos And Click Generate Zip To Generate A Zip File To
                  Download -{" "}
                  <span className="alert">
                    Note: Creating A New Zip Will Overwrite Old One.
                  </span>
                </p>
              )}
            </p>

            <br />
            <button
              onClick={this.handleDownload}
              className="btn btn-sm btn-secondary dashboardButton"
              disabled={this.state.zipLoading ? true : false}
            >
              Generate Zip Of Selected Images
            </button>
          </div>
        );
      }
    } else {
      downloadButton = "";
    }

    return (
      <div>
        <Tabs />
        <div className="headlineBox">
          <p className="display-5 text-center headline">
            {" "}
            This is the download section. If you select one single image you
            will be prompted with a Download button. If you select more than one
            image You will be prompted with a Generate Zip button, clicking
            Generate Zip will initiate the generation of a zipped file
            containing the images you selected. This process takes some time,
            the more images in the zip file the longer the process will take.
            Don't worry, you can navigate away while this process ensues, it is
            all done on the server! When the zip file is ready for download you
            will see a Download Zip button appear here in the Zip Download
            section.
          </p>
        </div>
        {this.state.message !== undefined ? (
          <p className="alert message">{this.state.message}</p>
        ) : (
          ""
        )}
        <div className="downloadZipContainer">
          <p className="lead text-center">Zip Download Area</p>{" "}
          {this.state.zips.length > 0 ? (
            <button
              className="btn btn-sm btn-secondary text-center"
              onClick={this.downloadZip}
            >
              Download Zip
            </button>
          ) : (
            "There Are No Zip Downloads Available At This Time"
          )}
        </div>
        <div className="imageSelectionContainer">
          <p className="lead text-center">Image Selection Area</p>
          {downloadButton}
          {this.state.zipLoading ? (
            <i className="spinner fas fa-circle-notch fa-spin" />
          ) : (
            <div>
              <ClientImageContainer
                download={this.download}
                images={this.state.images}
              />
            </div>
          )}
          {this.state.confirmation ? (
            <Confirmation
              question="Creating a New Zip File Will Delete The Old One. Do You Want To Continue Creating A New Zip?"
              answer={this.answerConfirmOverwrite}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default compose(withFirebase)(Download);
