import React, { Component } from "react";
import Tabs from "../Tabs";
import { compose } from "redux";
import { withFirebase } from "./../../../firebase/index";
import AddClient from "./AddClient";
import Backdrop from "./../../common/Backdrop";
import ClientsTable from "./ClientsTable";
import LoadingScreen from "./../../common/LoadingScreen";
import Confirmation from "./../../common/Confirmation";
import EditClient from "./EditClient";
import axios from "axios";
import { addClient, editClient, deleteClient } from "./../../../versionInfo";
import "./clients.css";

class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addClient: false,
      editClient: false,
      clients: [],
      confirmation: false
    };
  }
  toggleAddClient = () => {
    this.setState(prevState => {
      return { addClient: !prevState.addClient };
    });
  };
  toggleEditClient = () => {
    this.setState(prevState => {
      return { editClient: !prevState.editClient };
    });
  };
  getClients = () => {
    let clients = [];
    this.props.firebase.db
      .collection("users")
      .get()
      .then(
        function(userSnapshot) {
          console.log("got response from getClients");
          userSnapshot.forEach(
            function(user) {
              if (user.exists) {
                console.log("got client");
                clients.push(user.data());
              }
            }.bind(this)
          );
          this.setState({ clients: clients });
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting clients: " + err.message);
      });
  };
  selectClient = client => {
    console.log("from selectclient, client is : " + JSON.stringify(client));
    if (client === this.state.selectedClient || client === null) {
      console.log("unselecting");
      this.setState({ selectedClient: undefined });
    } else {
      this.setState({ selectedClient: client });
    }
  };
  componentDidMount() {
    this.getClients();
  }
  answerConfirmDelete = answer => {
    this.setState({ confirmation: false, selectedClient: undefined });
    if (answer) {
      this.deleteClient();
    }
  };
  confirmDelete = () => {
    this.setState({ confirmation: true });
  };
  deleteClient = () => {
    this.setState({ loading: true });
    let client = this.state.selectedClient;
    this.props.firebase.db
      .collection("users")
      .doc(client.uid)
      .delete()
      .then(
        async function(res) {
          await axios
            .post(deleteClient, client)
            .then(
              function(res) {
                console.log(
                  "got response from deleteaccount, res is : " +
                    JSON.stringify(res)
                );
                this.setState(
                  { message: "Successfully Deleted Client", loading: false },
                  () => {
                    this.getClients();
                  }
                );
              }.bind(this)
            )
            .catch(function(err) {
              console.log("error deleting client, error: " + err.message);
            });
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error deleting client from database: " + err.message);
      });
  };
  editClient = () => {
    this.setState({ editClient: true });
  };
  submitEditClient = client => {
    this.setState({ loading: true, editClient: false });

    //admin sdk
    console.log("editing auth object");
    axios
      .post(editClient, {
        name: client.fullName,
        email: client.email,
        password: client.password,
        type: "client",
        uid: client.uid
      })
      .then(
        function(uid) {
          this.props.firebase.db
            .collection("users")
            .doc(client.uid)
            .set(client)
            .then(
              function(res) {
                this.setState({
                  loading: false,
                  message: "Successfully Edited Client"
                });
                this.getClients();
              }.bind(this)
            )
            .catch(
              function(err) {
                console.log("error editing client in db");
                this.setState({
                  loading: false,
                  message:
                    "Error Editing Client In Database, Refresh And Try Again"
                });
              }.bind(this)
            );
        }.bind(this)
      )
      .catch(
        function(err) {
          this.setState({
            loading: false,
            message: "Error Editing Client In Auth, Refresh And Try Again"
          });
          console.log("error editing client:" + err.message);
        }.bind(this)
      );
  };
  submitAddClient = client => {
    console.log(
      "inside submitAddClient in Clients Component, object is : " +
        JSON.stringify(client)
    );
    this.setState({ addClient: false, loading: true });
    axios
      .post(addClient, {
        name: client.fullName,
        email: client.email,
        password: client.password,
        type: "client"
      })
      .then(
        function(uid) {
          let docRef = this.props.firebase.db
            .collection("users")
            .doc(uid.data.uid);
          client.uid = uid.data.uid;
          docRef.set(client);
          this.setState(
            {
              message: "Successfully Added Client",
              loading: false
            },
            () => {
              this.getClients();
            }
          );
        }.bind(this)
      )
      .catch(
        function(err) {
          console.log("error adding client,: " + err.message);
          this.setState({ message: "Error Adding Client", loading: false });
        }.bind(this)
      );
  };
  render() {
    return (
      <div>
        <Tabs />
        {this.state.loading ? (
          <LoadingScreen />
        ) : (
          <div className="uploadFormContainer">
            <div className="uploadForm">
              <p className="display-4 dashboardHeading">Clients</p>
              {this.state.message !== undefined ? (
                <p className="lead">{this.state.message}</p>
              ) : (
                ""
              )}
              <div className="buttonContainer">
                {" "}
                <button
                  className="controlButton btn btn-sm btn-secondary"
                  onClick={this.toggleAddClient}
                >
                  Add Client
                </button>
                {this.state.selectedClient !== undefined ? (
                  <div>
                    {" "}
                    <button
                      className="controlButton btn btn-sm btn-secondary"
                      onClick={this.confirmDelete}
                    >
                      Delete Client
                    </button>
                    <button
                      className="controlButton btn btn-sm btn-secondary"
                      onClick={this.editClient}
                    >
                      Edit Client
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <br />
              <ClientsTable
                selectClient={this.selectClient}
                clients={this.state.clients}
              />
            </div>
          </div>
        )}
        {this.state.confirmation ? (
          <Confirmation
            question="Are You Sure You Want To Delete The Selected Client?"
            answer={this.answerConfirmDelete}
          />
        ) : (
          ""
        )}
        {this.state.editClient ? (
          <div>
            <EditClient
              client={this.state.selectedClient}
              submit={this.submitEditClient}
            />
            <Backdrop click={this.toggleEditClient} />
          </div>
        ) : (
          ""
        )}
        {this.state.addClient ? (
          <div>
            <AddClient submit={this.submitAddClient} />
            <Backdrop click={this.toggleAddClient} />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default compose(withFirebase)(Clients);
