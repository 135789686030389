import React from "react";

export default function PortStyle(props) {
  return (
    <div>
      <p className="lead">Portfolio Style</p>
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="checkbox"
          id="inlineCheckbox1"
          onClick={() => {
            props.answer("cinema");
          }}
          checked={props.style == "cinema" ? true : false}
          value="cinema"
        />
        <label className="form-check-label" htmlFor="inlineCheckbox1">
          Cinema
        </label>
      </div>
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          type="checkbox"
          id="inlineCheckbox2"
          onClick={() => {
            props.answer("block");
          }}
          checked={props.style == "block" ? true : false}
          value="block"
        />
        <label className="form-check-label" htmlFor="inlineCheckbox2">
          Block And Lightbox
        </label>
      </div>
    </div>
  );
}
