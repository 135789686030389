import React, { Component } from "react";
import { withFirebase } from "./../../../../firebase/index";
import { compose } from "redux";
import SiteImageContainer from "../portfolioManagement/SiteImageContainer";
import ActiveImage from "./ActiveImage";
import Confirmation from "./../../../common/Confirmation";
import axios from "axios";
import "./imagemanagement.css";
class ImagesManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      loading: true,
      activeImage: undefined,
      editActive: false,
      confirmation: false
    };
  }

  answerConfirmDelete = answer => {
    this.setState({ confirmation: false, loading: true });
    if (answer) {
      let strings = this.state.activeImage.path.split("/");
      let lastPart = this.state.activeImage.path.split("/").pop();
      let twoLastPart = "thumb@200_" + lastPart;
      let fiveLastPart = "thumb@500_" + lastPart;
      let twoPath = strings[0] + "/" + strings[1] + "/" + twoLastPart;
      console.log("twoPath is: " + twoPath);
      let fivePath = strings[0] + "/" + strings[1] + "/" + fiveLastPart;
      this.deleteImage(fivePath)
        .then(
          function(res) {
            this.deleteImage(this.state.activeImage.path)
              .then(
                function(res) {
                  this.props.firebase.db
                    .collection("site-images")
                    .doc(this.state.activeImage.id)
                    .delete()
                    .then(
                      function(res) {
                        console.log("success");
                        this.setState({
                          loading: false,
                          message: "Successfully Deleted Image"
                        });
                        this.props.getImages();
                      }.bind(this)
                    )
                    .catch(function(err) {
                      console.log(
                        "error deleting image from database: " + err.message
                      );
                    });
                }.bind(this)
              )
              .catch(function(err) {
                console.log("error deleting main image: " + err);
              });
          }.bind(this)
        )
        .catch(function(err) {
          console.log("error: " + err);
        });
    } else {
      this.setState({ loading: false });
    }
  };
  deleteImage = path => {
    console.log("inside delete image");
    return new Promise(
      function(res, rej) {
        this.props.firebase.storage
          .ref(path)
          .delete()
          .then(function(obj) {
            console.log("successfully deleted image");
            res("success");
          })
          .catch(function(err) {
            console.log("error deleting image: " + err.message);
            res(err.message);
          });
      }.bind(this)
    );
  };
  toggleConfirmation = () => {
    this.setState(prevState => {
      return { confirmation: !prevState.confirmation };
    });
  };
  componentWillReceiveProps(nextProps) {
    console.log("will recieve props");
    console.log("props are: " + nextProps.siteImages);
    this.setImages(nextProps);
  }
  setImages = nextProps => {
    let images = [];
    if (nextProps == undefined) {
      images = [...this.props.siteImages];
      console.log("inside setImages: " + JSON.stringify(images[0]));
    } else {
      images = [...nextProps.siteImages];
    }
    images.forEach(function(img) {
      img.selected = false;
    });
    if (this.state.activeIndex !== undefined && images !== undefined) {
      if (images[this.state.activeIndex] !== undefined) {
        images[this.state.activeIndex].selected = true;
      }
    }

    this.setState({
      images: images,
      loading: false,
      activeImage: images[this.state.activeIndex]
    });
  };

  componentDidMount() {
    console.log("rerender for imagesManagement ");
    this.setImages();
  }
  editMeta = () => {
    this.setState(prevState => {
      return { editActive: !prevState.editActive };
    });
  };
  generateFiveThumb = fivePath => {
    return new Promise(
      function(promiseRes, rej) {
        this.props.firebase.storage
          .ref(fivePath)
          .getDownloadURL()
          .then(
            function(url) {
              let imageObj = this.state.activeImage;
              imageObj.thumbTwo = url;
              this.props.firebase.db
                .collection("site-images")
                .doc(this.state.activeImage.id)
                .update({ thumbFive: url })
                .then(function(res) {
                  console.log("successfully wrote fiveThumb to database");
                  promiseRes(url);
                })
                .catch(function(err) {
                  console.log("error writing five thumb to database");
                  rej(err);
                });
            }.bind(this)
          )
          .catch(
            function(err) {
              console.log("error getting download url for 500 thumb");
              this.setState({
                error: true,
                loading: false,
                message: "Error Generating 500 pixel wide Thumb"
              });
              rej(err);
            }.bind(this)
          );
      }.bind(this)
    );
  };
  generateTwoThumb = twoPath => {
    return new Promise(
      function(promiseRes, rej) {
        this.props.firebase.storage
          .ref(twoPath)
          .getDownloadURL()
          .then(
            function(url) {
              let imageObj = this.state.activeImage;
              imageObj.thumbTwo = url;
              this.props.firebase.db
                .collection("site-images")
                .doc(this.state.activeImage.id)
                .update({ thumbTwo: url })
                .then(function(res) {
                  console.log("successfully wrote twoThumb to database");
                  promiseRes(url);
                })
                .catch(function(err) {
                  console.log("error writing two thumb to database");
                  rej(err);
                });
            }.bind(this)
          )
          .catch(
            function(err) {
              console.log("error getting download url for 200 thumb");
              this.setState({
                error: true,
                loading: false,
                message:
                  "Error Generating 200 pixel wide Thumb: If You Just Uploaded This Image, Give It 15 Minutes For The Server To Do Its Work"
              });
              rej(err);
            }.bind(this)
          );
      }.bind(this)
    );
  };
  genThumb = () => {
    axios
      .post(
        "https://us-central1-katiephotographydev.cloudfunctions.net/createThumbnails",
        { path: this.state.activeImage.path }
      )
      .then(
        function(res) {
          console.log(
            "response from createThumbnails is : " + JSON.stringify(res)
          );
        }.bind(this)
      )
      .catch(
        function(err) {
          console.log("error from createThumbnails: " + err.message);
        }.bind(this)
      );
  };
  generateThumbnails = () => {
    console.log(
      "inside generate thumbnails, path is : " + this.state.activeImage.path
    );

    let strings = this.state.activeImage.path.split("/");
    let lastPart = this.state.activeImage.path.split("/").pop();
    let twoLastPart = "thumb@200_" + lastPart;
    let fiveLastPart = "thumb@500_" + lastPart;
    let twoPath = strings[0] + "/" + strings[1] + "/" + twoLastPart;
    console.log("twoPath is: " + twoPath);
    let fivePath = strings[0] + "/" + strings[1] + "/" + fiveLastPart;

    this.generateFiveThumb(fivePath)
      .then(
        function(res) {
          this.setState(
            { message: "thumbs generated successfully", loading: false },
            () => {
              // this.props.getImages();
              console.log(
                "success generating thumbnails, check to see if it refreshed"
              );
              this.props.getImages();
            }
          );
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error generating fiveThumb");
      });
  };
  submitEdit = imageObj => {
    console.log(
      "inside submit edit from imagesManagement component, object is: " +
        JSON.stringify(imageObj)
    );
    this.setState({ loading: true, editActive: false });
    this.props.firebase.db
      .collection("site-images")
      .doc(imageObj.id)
      .update({ name: imageObj.name, caption: imageObj.caption })
      .then(
        function(res) {
          this.setState(
            { message: "Successfully Updated Image Object", loading: true },
            () => {
              console.log("calling getImages");
              this.props.getImages();
            }
          );
        }.bind(this)
      )
      .catch(
        function(err) {
          console.log("error updating image");
          this.setState({
            message: "Error Updating Image, Refresh And Try Again",
            loading: false
          });
        }.bind(this)
      );
  };
  selectImage = image => {
    let images = this.state.images;
    this.state.images.forEach(
      function(siteImage, i) {
        if (image == siteImage) {
          console.log("found image that was clicked");
          console.log("image is :" + JSON.stringify(siteImage));

          if (image.selected == true) {
            console.log("setting active image to undefined");
            images[i].selected = false;
            this.setState({ activeImage: undefined, activeIndex: undefined });
          } else {
            console.log("setting active image to image");
            images[i].selected = true;
            this.setState({ activeImage: siteImage, activeIndex: i });
          }
        } else {
          images[i].selected = false;
        }
        this.setState({ images: images });
      }.bind(this)
    );
  };
  render() {
    return (
      <div>
        {this.state.loading ? (
          <i className="spinner fas fa-circle-notch fa-spin" />
        ) : (
          <div>
            <p className="lead">Images Management</p>
            {this.state.message !== undefined ? (
              <p className="lead alert">{this.state.message}</p>
            ) : (
              ""
            )}
            <div className="row row-space">
              {this.state.activeImage !== undefined ? (
                <ActiveImage
                  activeImage={this.state.activeImage}
                  deleteImage={this.toggleConfirmation}
                  editMeta={this.editMeta}
                  generateThumbnails={this.generateThumbnails}
                  editActive={this.state.editActive}
                  submitEdit={this.submitEdit}
                />
              ) : (
                <div className="activeImageContainer col col-md-6 col-lg-6">
                  <p className="lead">Active Image</p>
                </div>
              )}
              <div className="col col-md-6 col-lg-6">
                {" "}
                <SiteImageContainer
                  siteImages={this.state.images}
                  select={this.selectImage}
                  disabled={
                    this.state.editActive || this.state.loading ? true : false
                  }
                />
              </div>
            </div>
          </div>
        )}
        {this.state.confirmation ? (
          <Confirmation
            question="Are You Sure You Want To Delete This Image?"
            answer={this.answerConfirmDelete}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default compose(withFirebase)(ImagesManagement);
