import React, { Component } from "react";
import Plx from "react-plx";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { withFirebase } from "./../firebase/index";
import { mockDB } from "./mockDB";
import NavBar from "./nav/NavBar";
import "./landing.css";
import Footer from "./Footer";
import LoadingScreen from "./common/LoadingScreen";

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      portfolios: [],
      navSticky: false,
      loading: true,
      containerStyle: {
        position: "absolute",
        backgroundColor: "#fff",
        zIndex: 200,
        width: "100%"
      },
      portContainerStyle: {
        position: "relative",
        backgroundColor: "#fff",
        zIndex: 200,
        width: "100%"
      },
      containerParallaxData: [
        {
          start: 0,
          end: 300,
          easing: "easeInOut",
          properties: [{ startValue: 0, endValue: 1, property: "scaleX" }]
        },
        {
          start: 800,
          end: 1000,
          easing: "easeInOut",
          properties: [{ startValue: 1, endValue: 0, property: "scaleX" }]
        }
      ],
      portfolioParallaxData: [
        {
          start: 1000,
          end: 1300,
          easing: "easeInOut",
          properties: [{ startValue: 0, endValue: 1, property: "scaleX" }]
        },
        {
          start: 1800,
          end: 2000,
          easing: "easeInOut",
          properties: [{ startValue: 1, endValue: 0, property: "scaleX" }]
        }
      ]
    };
    this.divElement = React.createRef();
  }
  updateDimensions = () => {
    //getting height of port div

    let windowWidth = window.innerWidth;

    let height = window.innerHeight;
    console.log(
      "inside updateDimensions width and height are: " +
        windowWidth +
        " and " +
        height
    );
    this.setState({
      height: height,

      containerStyle: {
        position: "absolute",
        backgroundColor: "#fff",
        zIndex: 200,
        width: "100%"
      },
      containerParallaxData: [
        {
          start: 0,
          end: height * 0.5,
          easing: "easeInOut",
          properties: [{ startValue: 0, endValue: 1, property: "scaleX" }]
        },
        {
          start: height * 0.8,
          end: height * 1,
          easing: "easeInOut",
          properties: [{ startValue: 1, endValue: 0, property: "scaleX" }]
        }
      ],
      portfolioParallaxData: [
        {
          start: height * 0.9,
          end: height * 1.7,
          easing: "easeInOut",
          properties: [
            { startValue: -2000, endValue: 0, property: "translateX" }
          ]
        },
        {
          start: height * 0.8,
          end: height * 1.1,
          easing: "easeInOut",
          properties: [{ startValue: 0, endValue: 1, property: "opacity" }]
        }
      ]
    });
  };

  componentDidMount() {
    this.updateDimensions();
    console.log("from navbar, sticky is : " + this.props.sticky);
    window.addEventListener("resize", this.updateDimensions);
    console.log("images from mockDB are " + JSON.stringify(mockDB));
    console.log(
      "from landing, signed in is, " +
        this.props.signedIn +
        " and admin is : " +
        this.props.admin
    );
    this.getPortfolioInfo();
    this.getHomeContent();
    let portHeight = 0;
    if (this.divElement !== undefined) {
      portHeight = this.divElement;
      console.log("portHeight is : " + JSON.stringify(portHeight));
    }
  }
  getPortfolioInfo = () => {
    let ports = [];
    this.props.firebase.db
      .collection("portfolios")
      .get()
      .then(
        function(res) {
          res.forEach(function(port) {
            let url = "/portfolio/" + port.data().url;
            let portObj = {
              image: port.data().defaultImageThumb,
              name: port.data().name,
              url: url
            };
            ports.push(portObj);
          });
          this.setState({ portfolios: ports });
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting port stuff: " + err.message);
      });
  };
  getHomeContent = () => {
    this.props.firebase.db
      .collection("pages")
      .doc("home")
      .get()
      .then(
        function(res) {
          console.log(
            "res from getHomeContent is : " + JSON.stringify(res.data())
          );
          if (res.exists) {
            let home = res.data();

            const landing1Loader = new Image();
            const landing2Loader = new Image();
            const landing3Loader = new Image();
            let landing1, landing2, landing3;
            if (home.landing1 == undefined) {
              landing1Loader.src = mockDB.landing1;
            } else {
              landing1Loader.src = home.landing1.url;
            }
            if (home.landing2 == undefined) {
              landing2Loader.src = mockDB.landing2;
            } else {
              landing2Loader.src = home.landing2.url;
            }
            if (home.landing3 == undefined) {
              landing3Loader.src = mockDB.landing3;
            } else {
              landing3Loader.src = home.landing3.url;
            }

            landing1Loader.onload = () => {
              this.setState({
                landing1: landing1Loader.src,
                loading: false,
                homeHeading: home.homeHeading,
                homeParagraph: home.homeParagraph,
                landing3: landing3Loader.src
              });
            };
            landing2Loader.onload = () => {
              this.setState({ landing2: landing2Loader.src });
            };
          } else {
            this.setState({
              landing1: mockDB.landing1,
              loading: false,
              homeHeading: "Edit Landing Page In Dashboard",
              homeParagraph: "Edit Landing Page In Dashboard",
              landing3: mockDB.landing3,
              landing2: mockDB.landing2
            });
          }
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting home content: " + err.message);
      });
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
  render() {
    console.log(
      "from render, portfolios is : " + JSON.stringify(this.state.portfolios)
    );
    return (
      <div className="appContainer d-flex flex-column">
        {this.state.loading ? (
          <LoadingScreen />
        ) : (
          <div>
            {" "}
            <NavBar
              signedIn={this.props.signedIn}
              admin={this.props.admin}
              active="home"
              sticky={this.state.navSticky}
            />
            <div className="landingContainer">
              <div
                className="landingImage1"
                style={{
                  backgroundImage: "url(" + this.state.landing1 + ")"
                }}
              ></div>
              <Plx
                style={this.state.containerStyle}
                parallaxData={this.state.containerParallaxData}
              >
                <div
                  style={{
                    width: "100%",
                    display: "block",
                    alignItems: "center",
                    textAlign: "center",
                    borderTop: "2px solid black",
                    borderBottom: "2px solid black"
                  }}
                  className="features"
                >
                  {" "}
                  <h1>{this.state.homeHeading}</h1>
                  <br />
                  <p className="aboutParagraph lead">
                    {this.state.homeParagraph}
                  </p>
                  <Link className="btn btn-lg btn-secondary" to="/contact">
                    Contact
                  </Link>
                </div>
              </Plx>
              <div
                className="landingImage"
                style={{
                  backgroundImage: "url(" + this.state.landing2 + ")"
                }}
              ></div>
              <Plx
                style={this.state.portContainerStyle}
                parallaxData={this.state.portfolioParallaxData}
              >
                <div
                  style={{
                    width: "100%",
                    display: "block",
                    alignItems: "center",
                    textAlign: "center",

                    borderTop: "2px solid black",
                    borderBottom: "2px solid black",
                    position: "relative"
                  }}
                  className="features"
                >
                  {" "}
                  <h1>portfolio</h1>
                  <br />
                  <div className="portLinkContainer row">
                    {this.state.portfolios[0] !== undefined
                      ? this.state.portfolios.map((port, i) => (
                          <div
                            className=" rounded portLink col col-lg-4 col-md-6 col-sm-12"
                            key={i}
                            style={{
                              backgroundImage: "url(" + port.image + ")"
                            }}
                          >
                            <div
                              className={
                                this.state.mouseWeddings == i
                                  ? "portName portActive"
                                  : "portName slow-animated fadeIn"
                              }
                            >
                              <Link
                                onMouseOver={() => {
                                  console.log("mouseover");
                                  this.setState({ mouseWeddings: i });
                                }}
                                onMouseOut={() => {
                                  this.setState({ mouseWeddings: undefined });
                                }}
                                to={port.url}
                              >
                                {port.name}
                              </Link>
                            </div>
                          </div>
                        ))
                      : ""}
                  </div>
                </div>
              </Plx>
              <div
                className="landingImage lastLanding"
                style={{
                  backgroundImage: "url(" + this.state.landing3 + ")"
                }}
              ></div>
              <Footer />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default compose(withFirebase)(Landing);
