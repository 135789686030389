import React, { Component } from "react";
import formatDate from "./../../../common/formatDate";
class PortfolioTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPortfolio: null
    };
    this.selectPortfolio = this.selectPortfolio.bind(this);
  }
  selectPortfolio(event) {
    if (this.state.selectedPortfolio == event) {
      this.props.selectPortfolio(null);
      this.setState({ selectedPortfolio: null });
    } else {
      this.props.selectPortfolio(event);
      this.setState({ selectedPortfolio: event });
    }
  }
  render() {
    return (
      <div className="clientsTableContainer">
        <p className="display-5">Portfolios</p>
        <table className="table table-hover">
          <thead className="thead-dark">
            <tr>
              <th></th>
              <th
                className="sort"
                scope="col"
                onClick={() => {
                  this.props.sort("name");
                }}
              >
                Name
              </th>
              <th scope="col">URL</th>
              <th
                className="sort"
                scope="col"
                onClick={() => {
                  this.props.sort("date");
                }}
              >
                Date Added
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.ports.map((portfolio, i) => (
              <tr
                key={i}
                style={{
                  backgroundColor:
                    this.state.selectedPortfolio == portfolio
                      ? "#ccc"
                      : undefined
                }}
              >
                <td>
                  <input
                    type="checkbox"
                    checked={
                      this.state.selectedPortfolio == portfolio ? true : false
                    }
                    id="selectPortfolio"
                    onChange={() => {
                      this.selectPortfolio(portfolio);
                    }}
                  ></input>
                </td>
                <th scope="row">
                  <button
                    className="btn btn-link"
                    onClick={() => {
                      this.selectPortfolio(portfolio);
                      this.props.openPortfolio(portfolio);
                    }}
                  >
                    {portfolio.name}
                  </button>
                </th>
                <th>{portfolio.url}</th>
                <th scope="row">{formatDate(portfolio.date)}</th>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default PortfolioTable;
